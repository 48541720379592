
class Npc {
	constructor(
		gameBoard,
		round,
		npcSettings,
		speed,
		position = null,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.settings = npcSettings;
		this.speed = speed;
		this.position = position;

		if (!position) {
			this.position = round.spawn.generateRandomSpawnPoint(this.settings.hitBox);
		}

		this.velocity = { dx: 0, dy: 0 };
	}

	draw() {
		const { ctx } = this.gameBoard;
		ctx.beginPath();
		ctx.fillStyle = this.settings.color;
		ctx.font = this.settings.font;
		ctx.save();
		ctx.translate(this.position.x + this.settings.textOffset.x, this.position.y + this.settings.textOffset.y);
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(this.settings.text, 0, 0);
		ctx.restore();
		ctx.closePath();
	}

	update() {
		const directionX = this.round.spawn.position.x - this.position.x;
		const directionY = this.round.spawn.position.y - this.position.y;

		const scalar = this.speed / Math.sqrt((directionX ** 2) + (directionY ** 2));
		this.velocity = {
			dx: directionX * scalar,
			dy: directionY * scalar,
		};

		this.position.x += this.velocity.dx;
		this.position.y += this.velocity.dy;
		this.draw();

		if (this.checkCollision()) {
			this.handleCollision();
		} else {
			this.draw();
		}
	}

	checkCollision() {
		// include hitbox
		// eslint-disable-next-line
		return Math.sqrt((this.position.x - this.gameBoard.go.position.x) ** 2) + (this.position.y - this.gameBoard.go.position.y) ** 2 < this.gameBoard.go.radius;
	}

	handleCollision() {
		const { gameBoard, round } = this;
		const enemy = round.settings.npcs.enemy.name;
		const ally = round.settings.npcs.ally.name;

		switch (this.settings.name) {
			case enemy:
				round.enemiesInvaded++;
				break;
			case ally:
				round.alliesSaved++;
				break;
			default: return;
		}

		this.removeFromGameBoard();

		if (round.totalErrors() >= gameBoard.settings.scoreBoard.maxErrors) {
			gameBoard.endRound();
		}
	}

	handleClicked() {
		const { round, settings } = this;
		const enemy = round.settings.npcs.enemy.name;
		const ally = round.settings.npcs.ally.name;

		switch (settings.name) {
			case enemy:
				round.enemiesDestroyed++;
				// gameBoard.updateScoreBoard('enemiesDestroyed', round.enemiesDestroyed);

				break;
			case ally:
				round.alliesDestroyed++;
				// gameBoard.updateScoreBoard('alliesDestroyed', round.alliesDestroyed);
				break;
			default: return;
		}

		this.removeFromGameBoard();
	}

	removeFromGameBoard() {
		// const round = this.scoreBoard.rounds[this.scoreBoard.rounds.length - 1];
		const index = this.round.npcs.indexOf(this);
		this.round.npcs.splice(index, 1);
	}
}

module.exports = Npc;
