import React, { Fragment } from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Field } from 'formik';

import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup, FormHelperText,
	FormLabel, Typography,
} from '@material-ui/core';


const propTypes = {
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.objectOf(PropTypes.any),
		PropTypes.string,
	])).isRequired,
	values: PropTypes.objectOf(PropTypes.any).isRequired,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	error: PropTypes.string,
};


export const validationSchemas = {
	REQUIRED_OPTION_SELECTION: yup.array()
		.min(1, 'Please make at least one selection.')
		.nullable(),
};

const CheckboxList = ({
	label,
	name,
	options = [],
	values = [],
	onChange,
	onBlur,
	error,
}) => {
	return (
		<FormControl component="fieldset" error={!!error} style={{ display: 'block' }}>
			<FormLabel component="legend">
				<Typography variant="h4" component="div" gutterBottom>
					{label}
				</Typography>
			</FormLabel>
			<Box ml={2}>
				<FormGroup>
					{options.map((item) => (
						<FormControlLabel
							key={uid(item)}
							control={
								<Field
									type="checkbox"
									name={`${name}`}
									value={item.value || item.label || item}
									component={({ field, form, ...props }) => {
										return <Checkbox color="primary" {...field} {...props} />;
									}}
								/>
							}
							label={
								<Fragment>
									{item.helperText && (
										<Box my={1.5} />
									)}
									{item.label || item}
									{item.helperText && (
										<FormHelperText error={false}>
											{item.helperText}
										</FormHelperText>
									)}
								</Fragment>
							}
						/>
					))}
				</FormGroup>
			</Box>

			{error && (
				<FormHelperText>{error}</FormHelperText>
			)}
		</FormControl>
	);
};

CheckboxList.propTypes = propTypes;

export default CheckboxList;
