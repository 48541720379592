export const appStates = {
	WELCOME: 'WELCOME',
	FIRST_TIME: 'FIRST_TIME',
	LOGIN: 'LOGIN',
	PRE_ASSESSMENT: 'PRE_ASSESSMENT',
	POST_ASSESSMENT: 'POST_ASSESSMENT',
	PARTICIPANT_INFORMATION: 'PARTICIPANT_INFORMATION',
	TESTS: 'TESTS',
	CONCLUSION: 'CONCLUSION',
};
