
import { playingStates } from '@@Constants';
import AbstractGamePiece from '@@Components/Tests/AbstractGamePiece';


class CircleBase extends AbstractGamePiece {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		super(gameBoard, round, squareSettings, position);
		this.isHovered = false;
	}

	draw(isDisplayed = false) {
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;

		ctx.beginPath();
		ctx.fillStyle = this.settings.boarderColor;
		ctx.lineWidth = this.settings.boarderSize;
		ctx.arc(this.position.x, this.position.y, this.settings.radius, 0, Math.PI * 2);
		ctx.stroke();
		ctx.closePath();

		if (this.isHovered) {
			this.fill(this.settings.hoverColor);
		}

		if (isDisplayed) {
			this.fill(this.settings.color);
		}
	}

	fill(color) {
		const { ctx } = this.gameBoard;
		ctx.beginPath();
		ctx.fillStyle = color;
		ctx.arc(this.position.x,
			this.position.y,
			this.settings.radius,
			0,
			Math.PI * 2);
		ctx.fill();
		ctx.closePath();
	}

	isInsideOf(clickPosition) {
		if (Math.sqrt((clickPosition.x - this.position.x) ** 2 + (clickPosition.y - this.position.y) ** 2)
			< this.settings.radius) {
			return true;
		}

		return false;
	}

	addHover() {
		this.isHovered = true;
	}

	removeHover() {
		this.isHovered = false;
	}
}

export default CircleBase;
