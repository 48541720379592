import React, { createRef, Fragment, Component } from 'react';
import hash from 'object-hash';

import config from '@@Config';
import { gameStates, playingStates } from '@@Constants';
import AbstractGameBoard from '@@Components/Tests/AbstractGameBoard';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class GameBoardBase extends AbstractGameBoard {

	constructor(props) {
		// this needs to be abstracted more
		super(props);

		this.canvasRef = createRef();
		this.activeTest = props.activeTest;
		this.onScoreBoardUpdate = props.onScoreBoardUpdate;
		this.onGameStateUpdate = props.onGameStateUpdate;
		this.onPlayingStateUpdate = props.onPlayingStateUpdate;
		this.status = true;

		this.frameCount = 0;
		this.frameCountTime = new Date();
		this.currentFrameRate = 0;

		this.handleCanvasClick = this.handleCanvasClick.bind(this);

		this.state = {
			scoreBoard: {
				rounds: [],
			},
		};

		this.settings = {
			theme: {
				backgroundColor: '#808080',
				textColor: '#FFFFFF',
				boarderColor: '#808080',
				largeFont: '30px Arial',
				smallFont: '12px Arial',
			},
			// TODO: scoreboard is in react now so this needs to be addressed as
			//  some games relay on knowing exactly where the scoreboard is
			scoreBoard: {
				position: {
					x: 750,
					y: 25,
				},
				size: {
					x: 200,
					y: 115,
				},
				padding: {
					x: 10,
					y: 10,
				},
				textLineSize: {
					x: 100,
					y: 15,
				},
			},
			debug: 0,
		};

		this.ctx = null;
	}

	componentDidMount() {
		this.canvas = this.canvasRef.current;
		this.canvasCenter = {
			x: this.canvas.width / 2,
			y: this.canvas.height / 2,
		};

		this.ctx = this.canvas.getContext('2d');

		this.go = {
			position: this.canvasCenter,
			radius: 70,
			text: 'Press',
		};

		this.interval = setInterval(() => {
			this.draw();
		}, 1000 / 60);

		document.addEventListener('keydown', this.keydownHandler);
		document.addEventListener('mousemove', this.mouseMoveHandler);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keydownHandler);
		document.removeEventListener('mousemove', this.mouseMoveHandler);
		clearInterval(this.interval);
	}

	keydownHandler = (event) => {
		this.handleKeyPress(event);
	}

	mouseMoveHandler = (event) => {
		this.handleMouseMove(event);
	}

	getMousePositionFromEvent(event) {
		const { target } = event;
		const rect = target.getBoundingClientRect();

		// note: updated due to layout coordinate reporting issues with relative positioning
		const clickX = event.clientX - rect.left; // event.pageX - this.canvas.offsetLeft;
		const clickY = event.clientY - rect.top; // event.pageY - this.canvas.offsetTop;

		if (this.settings.debug) {
			console.debug('Click', clickX, clickY);
		}

		return { x: clickX, y: clickY };
	}

	setStartState() {
		this.props.onGameStateUpdate(gameStates.START);
	}

	setEndState() {
		this.props.onGameStateUpdate(gameStates.END);
	}

	createNewRound() {
		throw new Error('No implementation for method createNewRound.');
	}

	async startRound() {
		const newRound = this.createNewRound();

		await this.setState((prevState) => {
			return {
				scoreBoard: {
					...prevState.scoreBoard,
					rounds: [...prevState.scoreBoard.rounds, newRound],
				},
			};
		});

		this.props.onGameStateUpdate(gameStates.PLAYING);
	}

	isGameOver() {
		return this.state.scoreBoard.rounds.length >= this.settings.numberOfRounds;
	}

	endRound() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.endRound();

		if (this.isGameOver()) {
			this.setEndState();
		} else {
			this.setStartState();
		}
	}

	calculateFrameRate() {
		this.frameCount++;

		if (new Date() - this.frameCountTime > 1000) {
			this.currentFrameRate = this.frameCount;
			this.frameCount = 0;
			this.frameCountTime = new Date();
		}
	}

	update() {
		this.calculateFrameRate();
		this.verify();

		switch (this.props.gameState) {
			case gameStates.START:
				this.drawStartScreen();
				break;
			case gameStates.PLAYING:
				this.checkState();
				this.drawGameScreen();
				break;
			default: break;
		}
	}

	verify() {
		if (this.activeTest.hash !== hash(this.settings) && this.status) {
			api.action(ENTITIES.SESSIONS, ACTIONS.CREATE, {
				completedSections: [],
				clientDetails: {},
				presentedGameOrder: [],
				cheatingDetected: 1,
				sessionDetails: {
					game: this.constructor.name,
				},
			});
			this.status = false;
		}
	}

	clear() {
		const { ctx, canvas } = this;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
	}

	drawStartScreen() {
		this.drawHUD();
	}

	checkState() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (round.isRoundOver()) {
			this.endRound();
		}
	}

	drawGameScreen() {
		this.drawHUD();
		this.drawGameBoard();
	}

	drawHUD() {
		this.onScoreBoardUpdate(this.state.scoreBoard);
	}

	draw() {
		this.clear();
		this.update();
	}

	render() {
		return (
			<Fragment>
				<canvas
					ref={this.canvasRef}
					width={config.gameContainer.width}
					height={config.gameContainer.height}
					onClick={this.handleCanvasClick}
				/>
			</Fragment>
		);
	}
}

export default GameBoardBase;
