
import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Npc from './Npc';
import { playingStates } from '@@Constants/playingStates';


class QuickDrawRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.startTime = new Date();
		this.endTime = null;
		this.npcs = [];
		this.countDown = new Date();

		this.spawn = {
			position: this.gameBoard.canvasCenter,
			radius: this.gameBoard.canvas.height / 2,

			generateRandomSpawnPoint: (hitBox) => {
				const angle = Math.random() * Math.PI * 2;
				const newPosition = {
					x: Math.floor((Math.cos(angle) * this.spawn.radius) + this.spawn.position.x),
					y: Math.floor((Math.sin(angle) * this.spawn.radius) + this.spawn.position.y),
				};

				const newPositionRight = {
					x: newPosition.x + hitBox.x,
					y: newPosition.y + hitBox.y,
				};

				/* eslint-disable no-continue, no-restricted-syntax */
				for (const element of this.npcs) {
					const elementRight = {
						x: element.position.x + element.settings.hitBox.x,
						y: element.position.y + element.settings.hitBox.y,
					};

					if (newPosition.x > elementRight.x || element.position.x > newPositionRight.x) {
						continue;
					}

					if (newPosition.y > elementRight.y || element.position.y > newPositionRight.y) {
						continue;
					}

					return this.spawn.generateRandomSpawnPoint(hitBox);
				}
				/* eslint-enable no-continue, no-restricted-syntax */

				return newPosition;
			},
		};
	}

	generateRandomClickPoint() {
		let newPosition;
		do {
			newPosition = {
				x: Math.floor(Math.random()
					* (this.gameBoard.canvas.width - this.settings.npcSettings.padding.x * 2))
					+ this.settings.npcSettings.padding.x,
				y: Math.floor(Math.random()
					* (this.gameBoard.canvas.height - this.settings.npcSettings.padding.y * 2))
					+ this.settings.npcSettings.padding.y,
			};
		} while ((Math.sqrt((newPosition.x
			- this.gameBoard.go.position.x) ** 2
			+ (newPosition.y - this.gameBoard.go.position.y) ** 2)
			< (this.gameBoard.go.radius + 30))
		|| (newPosition.x >= (this.settings.scoreBoard.position.x - 20)
			&& newPosition.y <= this.settings.scoreBoard.position.y + this.settings.scoreBoard.size.y + 20));

		this.npcs.push(new Npc(this.gameBoard, this.round, this.settings.npcSettings, newPosition));
	}

	isRoundOver() {
		return this.npcs.length > this.settings.npcSettings.maxTargets;
	}


	totalFalseStarts() {
		let totalFalseStarts = 0;
		this.npcs.forEach((npc) => {
			if (npc.userClickTime != null) {
				totalFalseStarts += npc.IsFalseStart() ? 1 : 0;
			}
		});

		return totalFalseStarts;
	}

	totalPercentFalseStarts() {
		const totalNumber = this.npcs.length;
		const totalFalseStarts = this.totalFalseStarts();
		return totalFalseStarts / totalNumber;
	}

	averageReactionTime() {
		let totalNumber = 0;
		let summedTime = 0;
		this.npcs.forEach((npc) => {
			if (npc.userClickTime != null) {
				if (!npc.IsFalseStart()) {
					totalNumber++;
					summedTime += npc.ReactionTimeOfUserClick();
				}
			}
		});

		return parseFloat((totalNumber === 0
			? 0
			: summedTime / totalNumber).toFixed(4));
	}

	draw() {
		switch (this.gameBoard.props.playingState) {
			case playingStates.ANSWER:
				if (this.clicks.length > 0) {
					this.clicks[this.clicks.length - 1].draw();
				}
				break;
			case playingStates.COUNT_DOWN: {
				this.drawCountDown();
				break;
			}
			default: break;
		}
	}

	drawCountDown() {
		const { ctx } = this.gameBoard;
		ctx.font = this.settings.countDownFont;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillStyle = this.settings.countDownColor;
		const timeLeft = Math.floor((this.settings.countDownLength - (new Date() - this.countDown)) / 1000);
		ctx.fillText(timeLeft, this.gameBoard.canvasCenter.x, this.gameBoard.canvasCenter.y);
	}

	render() {
		return null;
	}
}

export default QuickDrawRound;
