import hash from 'object-hash';
import NumberBlockRound from '@@Components/Tests/NumberBlock/NumberBlockRound';
import { gameStates, playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class NumberBlockGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 5;
		this.settings.roundSettings = {
			gridSettings: {
				square: {
					size: {
						x: 50,
						y: 50,
					},
					color: '#808080',
					textColor: '#000000',
					selectedTextColor: '#FFFFFF',
					hoverColor: '#D3D3D3',
					font: '35px Overpass',
					boarderColor: '#FFFFFF',
					boarderSize: 5,
				},
				numberOfSquares: {
					x: 3,
					y: 3,
				},
				font: '30px Arial',
				maxAttempts: 3,
				displayTime: 10000,
				squaresInPattern: 10,
				position: {
					x: 100,
					y: 100,
				},
				timerOffset: {
					x: 75,
					y: -25,
				},
				timerFont: '30px Overpass',
				timerColor: '#000000',
			},
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				enemiesDestroyed: 0,
				alliesDestroyed: 0,
				numberOfClicks: 0,
				alliesSaved: 0,
				enemiesInvaded: 0,
				totalEnemies: 0,
				totalAllies: 0,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.settings.roundSettings.gridSettings.position.x = this.canvasCenter.x
			- (this.settings.roundSettings.gridSettings.numberOfSquares.x
				* this.settings.roundSettings.gridSettings.square.size.x / 2);
		this.settings.roundSettings.gridSettings.position.y = this.canvasCenter.y
			- (this.settings.roundSettings.gridSettings.numberOfSquares.y
				* this.settings.roundSettings.gridSettings.square.size.y / 2);
	}

	createNewRound() {
		return new NumberBlockRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const click = this.getMousePositionFromEvent(event);

		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			round.checkClicked(click);
		}
	}

	handleMouseMove(event) {
		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const mousePosition = this.getMousePositionFromEvent(event);
			this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.checkHover(mousePosition);
		}
	}

	handleKeyPress(event) {
		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			round.handleKeyPress(event.key);
		}
	}

	setAnswerState() {
		this.props.onPlayingStateUpdate(playingStates.ANSWER);
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds - 1];
		// round.startTime = new Date();
	}

	checkState() {
		super.checkState();
		if (this.props.gameState !== gameStates.END) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			if (this.props.playingState === playingStates.DISPLAY && round.elapsedTime()
				>= this.settings.roundSettings.gridSettings.displayTime) {
				this.setAnswerState();
			}
		}
	}

	async startRound() {
		await super.startRound();
		this.props.onPlayingStateUpdate(playingStates.DISPLAY);
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.draw();
	}

	numberOfPieces() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.grid.numberOfPieces();
		});

		return output;
	}

	numberCorrect() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.grid.numberCorrect();
		});

		return output;
	}

	percentCorrect() {
		return parseFloat((this.numberCorrect() / this.numberOfPieces() * 100).toFixed(4));
	}

	numberIncorrect() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.grid.numberIncorrect();
		});

		return output;
	}

	numberBlank() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.grid.numberBlank();
		});

		return output;
	}

	percentIncorrect() {
		return parseFloat((this.numberIncorrect() / this.numberOfPieces() * 100).toFixed(4));
	}

	percentBlank() {
		return parseFloat((this.numberBlank() / this.numberOfPieces() * 100).toFixed(4));
	}

	totalElapsedTime() {
		let summedTime = 0;

		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_NUMBER_BLOCK_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.startTime,
				round_details: {
					hash: hash(this.settings),
				},
				total_time: round.elapsedTime(),
				count_correct: round.grid.numberCorrect(),
				count_incorrect: round.grid.numberIncorrect(),
				count_blank: round.grid.numberBlank(),
				percent_correct: round.grid.percentCorrect(),
				percent_incorrect: round.grid.percentIncorrect(),
				percent_blank: round.grid.percentBlank(),
			});
		});
	}
}

export default NumberBlockGame;
