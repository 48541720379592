import { Component } from 'react';


class GameRoundBase extends Component {
	constructor(props) {
		super(props);

		this.gameBoard = props.gameBoard;
		this.settings = props.settings;
		this.startTime = new Date();
		this.endTime = null;
	}

	elapsedTime() {
		const { startTime, endTime } = this;

		return startTime == null
			? 0
			: ((endTime == null ? new Date() : endTime) - startTime);
	}

	elapsedTimeSeconds() {
		return this.elapsedTime() / 1000;
	}

	endRound() {
		this.endTime = new Date();
	}
}

export default GameRoundBase;
