import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Npc from './Npc';


class ClickBlitzRound extends GameRoundBase {
	constructor(props) {
		super(props);

		this.lastSpawnTime = 0;
		this.npcs = [];
		this.enemiesDestroyed = 0;
		this.alliesDestroyed = 0;
		this.numberOfClicks = 0;
		this.alliesSaved = 0;
		this.enemiesInvaded = 0;
		this.totalEnemies = 0;
		this.totalAllies = 0;
		this.npcSpeed = this.settings.npcs.speed;
		this.nextSpawnInterval = this.settings.npcs.nextSpawnInterval;
		this.lastClick = null;
		this.lastClickTime = null;
		this.lastClickDisplayLength = 1000;
		this.spawn = {
			position: this.gameBoard.canvasCenter,
			radius: this.gameBoard.canvas.height / 2,

			generateRandomSpawnPoint: (hitBox) => {
				const angle = Math.random() * Math.PI * 2;
				const newPosition = {
					x: Math.floor((Math.cos(angle) * this.spawn.radius) + this.spawn.position.x),
					y: Math.floor((Math.sin(angle) * this.spawn.radius) + this.spawn.position.y),
				};

				const newPositionRight = {
					x: newPosition.x + hitBox.x,
					y: newPosition.y + hitBox.y,
				};

				/* eslint-disable no-continue, no-restricted-syntax */
				for (const element of this.npcs) {
					const elementRight = {
						x: element.position.x + element.settings.hitBox.x,
						y: element.position.y + element.settings.hitBox.y,
					};

					if (newPosition.x > elementRight.x || element.position.x > newPositionRight.x) {
						continue;
					}

					if (newPosition.y > elementRight.y || element.position.y > newPositionRight.y) {
						continue;
					}

					return this.spawn.generateRandomSpawnPoint(hitBox);
				}
				/* eslint-enable no-continue, no-restricted-syntax */

				return newPosition;
			},
		};
	}

	isRoundOver() {
		return this.totalErrors() >= 10;
	}

	totalErrors() {
		return this.enemiesInvaded + this.alliesDestroyed;
	}

	spawnNPCs() {
		const npcsSettings = this.settings.npcs;

		if (this.elapsedTime() - this.lastSpawnTime > this.nextSpawnInterval) {
			this.nextSpawnInterval = Math.floor(Math.random() * npcsSettings.maxSpawnInterval);
			this.lastSpawnTime = this.elapsedTime();

			if (this.npcs.length < this.settings.maxNPCs) {
				switch (Math.round(Math.random())) {
					case 0:
						this.npcs.push(
							new Npc(
								this.gameBoard,
								this,
								npcsSettings.enemy,
								this.npcSpeed[Math.floor(Math.random() * this.npcSpeed.length)],
							),
						);
						this.totalEnemies++;
						break;
					case 1:
						this.npcs.push(
							new Npc(
								this.gameBoard,
								this,
								npcsSettings.ally,
								this.npcSpeed[Math.floor(Math.random() * this.npcSpeed.length)],
							),
						);
						this.totalAllies++;
						break;
					default: break;
				}
			}
		}
	}

	drawSpawn() {
		const { spawn, gameBoard } = this;
		const { ctx } = gameBoard;
		ctx.beginPath();
		ctx.arc(spawn.position.x, spawn.position.y, spawn.radius, 0, Math.PI * 2);
		ctx.fillStyle = gameBoard.settings.theme.backgroundColor;
		ctx.stroke();
		ctx.closePath();
	}

	accelerate() {
		for (let i = 0; i < this.npcSpeed.length; i++) {
			this.npcSpeed[i] += this.settings.npcs.acceleration;
		}
	}

	targetMissedCount() {
		return this.numberOfClicks - this.enemiesDestroyed - this.alliesDestroyed;
	}

	render() {
		return null;
	}
}

export default ClickBlitzRound;
