import hash from 'object-hash';
import QuickDrawRound from '@@Components/Tests/QuickDraw/QuickDrawRound';
import { gameStates, playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class QuickDrawGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 3;
		this.settings.roundSettings = {
			maxNPCs: 20,
			npcSettings: {
				padding: {
					x: 20,
					y: 20,
				},
				text: '+',
				color: '#000000',
				font: '30px Arial',
				displayTime: 200,
				maxTargets: 10,
				maxTimeBeforeDisplay: 5000,
				minTimeBeforeDisplay: 1000,
				maxTimeToWaitForAnswer: 5000,
			},
			scoreBoard: {
				position: {
					x: 750,
					y: 25,
				},
				size: {
					x: 200,
					y: 500,
				},
				padding: {
					x: 10,
					y: 10,
				},
			},
			countDownLength: 4000,
			countDownFont: '30px Arial',
			countDownColor: '#000000',
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				enemiesDestroyed: 0,
				alliesDestroyed: 0,
				numberOfClicks: 0,
				alliesSaved: 0,
				enemiesInvaded: 0,
				totalEnemies: 0,
				totalAllies: 0,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	createNewRound() {
		return new QuickDrawRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const click = this.getMousePositionFromEvent(event);
	}

	handleMouseMove(event) {
		// don't need to handle mouse movements
	}

	handleKeyPress(event) {
		// don't need to handle key presses
	}

	drawHUD() {
		if (this.props.playingState === playingStates.ANSWER) {
			const { ctx } = this;

			if (this.settings.debug) {
				ctx.beginPath();
				ctx.fillStyle = this.settings.theme.backgroundColor;
				ctx.font = this.settings.theme.largeFont;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillText(this.currentFrameRate, 100, 100);
				ctx.closePath();
				this.drawSpawn();
			}

			super.drawHUD();
		}
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];

		if (this.props.playingState === playingStates.ANSWER) {
			// round.spawnNPCs();
			for (let i = 0; i < round.npcs.length; i++) {
				round.npcs[i].draw();
			}
		} else if (this.props.playingState === playingStates.COUNT_DOWN) {
			round.draw();
		}
	}

	checkState() {
		super.checkState();
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (this.props.playingState === playingStates.COUNT_DOWN && new Date() - round.countDown
			>= round.settings.countDownLength) {
			this.setAnswerState();
		}
	}

	async startRound() {
		await super.startRound();
		this.props.onPlayingStateUpdate(playingStates.COUNT_DOWN);
	}

	totalFalseStarts() {
		let totalFalseStarts = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			totalFalseStarts += round.totalFalseStarts();
		});

		return totalFalseStarts;
	}

	totalPercentFalseStarts() {
		const totalNumber = this.settings.roundSettings.npcSettings.maxTargets * this.state.scoreBoard.rounds.length;
		const totalFalseStarts = this.totalFalseStarts();
		return totalFalseStarts / totalNumber;
	}

	averageReactionTime() {
		let totalNumber = 0;
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			round.npcs.forEach((npc) => {
				if (npc.userClickTime != null) {
					if (!npc.IsFalseStart()) {
						totalNumber++;
						summedTime += npc.ReactionTimeOfUserClick();
					}
				}
			});
		});

		return parseFloat((totalNumber === 0
			? 0
			: summedTime / totalNumber).toFixed(4));
	}

	totalElapsedTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setAnswerState() {
		this.props.onPlayingStateUpdate(playingStates.ANSWER);
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].generateRandomClickPoint();
		// this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].startAnswerState();
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_QUICK_DRAW_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					hash: hash(this.settings),
				},
				average_reaction_time: round.averageReactionTime(),
				premature_click_count: round.totalFalseStarts(),
			});
		});
	}

}

export default QuickDrawGame;
