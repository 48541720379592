
import { playingStates } from '@@Constants';
import Square from './Square';
import PlayerInput from './PlayerInput';
import GridBase from '../GridBase';


class Grid extends GridBase {
	constructor(
		gameBoard,
		round,
		gridSettings,
		position = null,
	) {
		super(gameBoard, round, gridSettings, position);
		this.displayStartTime = null;
		this.sequence = [];
		this.playerInputs = [];
		if (!position) {
			this.position = this.settings.position;
		}
	}

	createGamePiece(position) {
		return new Square(
			this.gameBoard,
			this.round,
			this.settings.square,
			position,
		);
	}

	addSquareToSequence() {
		const randomIndex = Math.floor(Math.random() * (this.gamePieces.length - 1));
		this.sequence.push(randomIndex);
		this.playerInputs.push(new PlayerInput(this.gameBoard, this.round, this.sequence));
	}

	startDisplay() {
		this.addSquareToSequence();
		this.displayStartTime = new Date();
	}

	startAnswerState() {
		if (this.playerInputs.length > 0) {
			this.playerInputs[this.playerInputs.length - 1].startInput();
		}
	}

	hasInputFinished() {
		return this.playerInputs.length === 0 || this.playerInputs[this.playerInputs.length - 1].hasInputFinished();
	}

	isEnteredSequenceCorrect() {
		return this.playerInputs.length === 0 || this.playerInputs[this.playerInputs.length - 1].isEnteredSequenceCorrect();
	}

	hasTimedOut() {
		return this.playerInputs.length === 0 || this.playerInputs[this.playerInputs.length - 1].hasTimedOut();
	}

	isDisplayOver() {

	}

	draw() {
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;
		let gamePieceIndex = -1;


		if (this.displayStartTime != null) {
			const displayDuration = this.displayDuration();
			const currentDisplayIndex = this.currentDisplayIndex();
			if (displayDuration < (currentDisplayIndex * this.settings.displayTime)
				+ (this.settings.displayTime - this.settings.displayGapTime)) {
				gamePieceIndex = this.sequence[currentDisplayIndex];
			}
		}

		this.gamePieces.forEach((gamePiece, index) => {
			gamePiece.draw(gamePieceIndex === index);
		});

		const currentPlayerInput = this.playerInputs[this.playerInputs.length - 1];
		if (currentPlayerInput.shouldDisplayAnswer()) {
			this.gamePieces[currentPlayerInput.lastUserInputIndex()]
				.fill(this.settings.square.answerColor);
		}
	}

	currentDisplayIndex() {
		return Math.floor(this.displayDuration() / this.settings.displayTime);
	}

	displayDuration() {
		return new Date() - this.displayStartTime;
	}

	checkClicked(clickPosition) {
		this.gamePieces.forEach((gamePiece, index) => {
			if (gamePiece.isInsideOf(clickPosition)) {
				this.playerInputs[this.playerInputs.length - 1].addUserEnteredValue(index);
			}
		});
	}

	numberCorrect() {
		let totalCorrect = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCorrect()) {
				++totalCorrect;
			}
		});

		return totalCorrect;
	}

	checkState() {
		const playerInput = this.playerInputs[this.playerInputs.length - 1];
		if (new Date() - playerInput.startTime > this.settings.answerTimeOut) {
			playerInput.endInput();
		}
	}
}

export default Grid;
