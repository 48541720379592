import { createGlobalStyle } from 'styled-components';
import { colors } from '@@Theme';


const GlobalStyle = createGlobalStyle`

	html {
		overflow-y: scroll;
	}

	html, body, #root {
		height: 100vh;
	}

	body {
		background-color: ${colors.primary.main};
		color: ${colors.black};

		// TODO: may want to eliminate scrolling functionality
		// overflow: hidden;
	}

	.MuiButton-label, .MuiListItem-button {
		.MuiSvgIcon-root {
			margin-right: 0.15em;
		}
	}

	code {
		word-break: break-all;
	}

	fieldset {
		border: 0;
	}

	legend {
		margin-bottom: 0.5em;
	}

	address {
		font-style: normal;

		span {
			display: block;
		}
	}

	strong {
		font-weight: 800;
	}

	table {
		tr:nth-child(even) {
			background-color: #fbfbfb;
		}
	}

	dl {
		display: flex;
		flex-wrap: wrap;

		dt {
			width: 75%;
			text-align: left;
		}

		dd {
			margin-left: auto;
			width: 25%;
			text-align: right;
		}
	}

	.MuiTableBody-root {
		.MuiTableCell-head {
			vertical-align: top;
		}
	}

	/* fix to remove empty space for tab scroll button ('auto' mode does not work as expected) */
	.MuiTabs-scrollButtons {
		&.Mui-disabled {
			display: none;
		}
	}

	[class^="block_svg__block-face-"] {
		stroke: ${colors.black};
		stroke-width: 4;
		stroke-linejoin: round;
	};

	.block_svg__block-face-1 { fill: ${colors.grey['400']} };
	.block_svg__block-face-2 { fill: ${colors.grey['600']} };
	.block_svg__block-face-3 { fill: ${colors.grey['300']} };

	.block_svg__block_svg:hover {
		.block_svg__block-face-1 { fill: ${colors.primary.main} };
	}


	@media print {

	}
`;

export default GlobalStyle;
