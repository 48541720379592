import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import seedrandom from 'seedrandom';

import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';

import config from '@@Config';
import { appStates } from '@@Constants/appStates';
import api, { ENTITIES, ACTIONS } from '@@Utils/api';


const dateSeed = Date();

const propTypes = {
	setAppState: PropTypes.func,
};

const FirstTime = ({
	setAppState,
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const generateCode = () => {
		const random = seedrandom(dateSeed);
		const code = random.quick().toFixed(config.userCode.length).substring(2);
		return code;
	};

	const getInitialValues = () => {
		return {
			accept: false,
			code: generateCode(),
			codeValidation: '',
			hardware: false,
			signature: '',
		};
	};

	const validationSchema = () => {
		const schema = {
			codeValidation: yup.string()
				.required('Please re-enter the assigned code.')
				.oneOf([yup.ref('code'), null], 'The assigned code does not match'),
			signature: yup.string()
				.required('Please enter your name for the electronic signature.'),
		};

		return yup.object().shape(schema);
	};

	const handleSubmit = async (values) => {
		console.info('[FirstTime]', values);

		setIsSubmitting(true);

		const participantObj = {
			code: values.code,
		};

		try {
			await api.action(ENTITIES.PARTICIPANTS, ACTIONS.NEW_PARTICIPANT, participantObj);
		} catch (err) {
			console.error('[error]', err);
		}

		const sessionObj = {
			completedSections: [],
			clientDetails: {
				...values,
			},
			presentedGameOrder: [],
			cheatingDetected: 0,
			sessionDetails: {},

		};

		try {
			await api.action(ENTITIES.SESSIONS, ACTIONS.CREATE, sessionObj);
		} catch (err) {
			console.error('[error]', err);
		}

		setAppState(appStates.PARTICIPANT_INFORMATION);
	};

	return (
		<Box p={5}>
			<Typography variant="h1" gutterBottom>
				First Time Entry
			</Typography>

			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({
					handleChange,
					handleBlur,
					values,
					errors,
					touched,
				}) => (
					<Form noValidate>

						<Typography gutterBottom>
							Your participation is either voluntary or based-upon study enrollment benefits.
							Either way, we ask you that you complete all survey and assessment sections completely,
							thoughtfully, and to the best of your ability. Your data will only be identified using your
							randomly assigned participant ID. The data you enter will be used to analyze the validity of
							the esports assessment under development.
						</Typography>

						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.accept}
										onChange={handleChange}
										name="accept"
										color="primary"
									/>
								}
								label="I understand"
							/>
						</Box>

						<Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.hardware}
										onChange={handleChange}
										name="hardware"
										color="primary"
									/>
								}
								label="I acknowledge that I am using a desktop/laptop and keyboard/mouse, either connected or as a peripheral"
							/>
						</Box>

						<Box my={3}>
							<Grid container>
								<Grid item xs={12} sm={4}>
									<TextField
										id="signature"
										name="signature"
										value={values.signature}
										onChange={handleChange}
										onBlur={handleBlur}
										label="Electronic Signature"
										helperText={touched.signature ? errors.signature : ''}
										error={errors.signature && touched.signature}
										variant="filled"
										fullWidth
										required
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
						</Box>

						<Box my={3}>
							<Divider />
						</Box>

						<Typography variant="h3" gutterBottom>
							Randomly Assigned Participant Number
						</Typography>

						<Typography>
							While we expect you to complete this in one session - you can use
							this code to reconnect to your assessment.<br />
							Note your assigned code for future reference.
						</Typography>

						<Box my={3}>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={3}>
									<TextField
										id="code"
										name="code"
										value={values.code}
										onChange={handleChange}
										onBlur={handleBlur}
										label="Your Assigned Code"
										variant="filled"
										fullWidth
										disabled
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										id="code-validation"
										name="codeValidation"
										value={values.codeValidation}
										onChange={handleChange}
										onBlur={handleBlur}
										label="Re-Enter Assigned Code"
										helperText={touched.codeValidation ? errors.codeValidation : ''}
										error={errors.codeValidation && touched.codeValidation}
										variant="filled"
										fullWidth
										required
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>
						</Box>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={(!values.hardware || !values.accept || (!!errors.codeValidation) || isSubmitting)}
						>
							Submit
						</Button>

					</Form>
				)}
			</Formik>
		</Box>
	);
};

FirstTime.propTypes = propTypes;

export default FirstTime;
