import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Grid from './Grid';


class FinalBossRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.grid = new Grid(this.gameBoard, this, this.settings.gridSettings);
	}

	isRoundOver() {
		const currentPlayerInput = this.grid.playerInputs[this.grid.playerInputs.length - 1];
		return !this.grid.isEnteredSequenceCorrect()
			&& !currentPlayerInput.shouldDisplayAnswer()
			&& !this.gameBoard.displayMessage();
	}

	totalErrors() {
		return this.enemiesInvaded + this.alliesDestroyed;
	}

	draw() {
		this.grid.draw();

	}

	checkClicked(clickPosition) {
		this.grid.checkClicked(clickPosition);
	}

	startRound() {

	}

	endRound() {
		super.endRound();
		this.grid.playerInputs[this.grid.playerInputs.length - 1].endInput();
	}

	render() {
		return null;
	}
}

export default FinalBossRound;
