import React, { useEffect, useState } from 'react';
import { withResizeDetector } from 'react-resize-detector';

import { CssBaseline, Box, Divider, Grid, Typography } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import Conclusion from '@@Components/Conclusion';
import ParticipantInformation from '@@Components/ParticipantInformation';
import PostAssessment from '@@Components/PostAssessment';
import PreAssessment from '@@Components/PreAssessment';
import FirstTime from '@@Components/FirstTime';
import Login from '@@Components/Login';
import Tests from '@@Components/Tests/Tests';
import Welcome from '@@Components/Welcome';
import { minimumRequirements } from '@@Config';
import { appStates, games } from '@@Constants';
import GlobalStyle from '@@Theme/components/GlobalStyle';
import theme from '@@Theme';
import { useStickyState, getTechnicalInfo } from '@@Utils';

import { Container, Wrapper } from './App.styles';


const themeSpacing = (theme.spacing() || 8) * 10;
const technicalInfo = getTechnicalInfo();

const App = ({ width, height, ref }) => {

	const [appState, setAppState] = useStickyState(
		appStates.WELCOME,
		'appState',
		[appStates.WELCOME, appStates.LOGIN, appStates.FIRST_TIME],
	);
	const [activeTest, setActiveTest] = useState(null);

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (!token) {
			setAppState(appStates.WELCOME);
		}
	}, [setAppState]);

	const handleGameShortcut = (event) => {
		const gameKey = event.target.value;
		setActiveTest(games[gameKey]);
		setAppState(appStates.TESTS);
	};

	const renderAppState = () => {
		switch (appState) {
			case appStates.WELCOME:
				return <Welcome setAppState={setAppState} />;
			case appStates.FIRST_TIME:
				return <FirstTime setAppState={setAppState} />;
			case appStates.LOGIN:
				return <Login setAppState={setAppState} />;
			case appStates.PRE_ASSESSMENT:
				return <PreAssessment setAppState={setAppState} />;
			case appStates.POST_ASSESSMENT:
				return <PostAssessment setAppState={setAppState} />;
			case appStates.PARTICIPANT_INFORMATION:
				return <ParticipantInformation setAppState={setAppState} />;
			case appStates.TESTS:
				return <Tests setAppState={setAppState} setActiveTest={setActiveTest} activeTest={activeTest} />;
			case appStates.CONCLUSION:
				return <Conclusion setAppState={setAppState} />;
			default: return null;
		}
	};

	const isScreenSizeRequirementMet = () => {
		return (
			width >= minimumRequirements.dimensions.browser.width + themeSpacing
			&& height >= minimumRequirements.dimensions.browser.height + themeSpacing
		);
	};

	const isSupportedDevice = () => {
		return !technicalInfo.isTouchOnlyDevice
			&& technicalInfo.dimensions.screen.width >= minimumRequirements.dimensions.browser.width
			&& technicalInfo.dimensions.screen.height >= minimumRequirements.dimensions.browser.height;
	};

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyle />

				<div ref={ref}>
					<Container maxWidth={null}>
						<Wrapper>

							<Grid container style={{ flexGrow: 1 }} justify="center">

								{!isSupportedDevice() && (
									<Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
										<Typography variant="h3" align="center">
											Please use a supported device
										</Typography>
										<Typography variant="h6" align="center">
											A desktop computer with a minimum screen size of
											&nbsp;
											{minimumRequirements.dimensions.browser.width + themeSpacing}
											&nbsp;&times;&nbsp;
											{minimumRequirements.dimensions.browser.height + themeSpacing}
											&nbsp;
											is required.
										</Typography>
									</Grid>
								)}

								{!isScreenSizeRequirementMet() && (
									<Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
										<Typography variant="h3" align="center">
											Please resize this window to a minimum size of&nbsp;
											{minimumRequirements.dimensions.browser.width + themeSpacing}
											&nbsp;&times;&nbsp;
											{minimumRequirements.dimensions.browser.height + themeSpacing}
										</Typography>

										<Box my={2}>
											<Typography variant="h6" align="center">
												Current Size
											</Typography>

											<Typography variant="h3" align="center">
												{width}&times;{height}
											</Typography>
										</Box>
									</Grid>
								)}

								{(isSupportedDevice() && isScreenSizeRequirementMet()) && (
									<Grid item xs={12} style={{ height: '100%' }}>
										{renderAppState()}

										<Box mx={5} mt={5}>
											<Divider />
										</Box>
									</Grid>
								)}
							</Grid>

						</Wrapper>
					</Container>
				</div>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default withResizeDetector(App);
