
class AbstractGameBoardStructure {
	constructor(
		gameBoard,
		round,
		settings,
		position = null,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.settings = settings;
		this.position = position;
	}

	createGamePiece(position) {
		throw new Error('No implementation for method createGamePiece.');
	}

	startDisplay() {
		throw new Error('No implementation for method startDisplay.');
	}

	draw() {
		throw new Error('No implementation for method draw.');
	}

	checkClicked(clickPosition) {
		throw new Error('No implementation for method createGamePiece.');
	}

	checkHover(position) {
		throw new Error('No implementation for method checkHover.');
	}

	clearHover() {
		throw new Error('No implementation for method clearHover.');
	}

	numberOfPieces() {
		throw new Error('No implementation for method numberOfSquares.');
	}
}

export default AbstractGameBoardStructure;
