export const games = {
	CLICK_BLITZ: {
		key: 'CLICK_BLITZ',
		title: 'Click Blitz',
		hash: 'e9570f4b4642be123c585eaab6ba910fb863e100',
	},
	ARROWS: {
		key: 'ARROWS',
		title: 'Arrows',
		hash: '1469fc713d6402924ea8b729b2e17c892ec1018b',
	},
	GHOST_BLOCK: {
		key: 'GHOST_BLOCK',
		title: 'Ghost Block',
		hash: '2211a42eae8cff9683c8a56081aa277241946cc0',
	},
	NUMBER_BLOCK: {
		key: 'NUMBER_BLOCK',
		title: 'Number Block',
		hash: '6913e563a6722176a339c22a052ed46e06f60e51',
	},
	PINPOINT: {
		key: 'PINPOINT',
		title: 'Pinpoint',
		hash: 'ee6a7ead7fae3555e5d264b3868e687e0742ae6f',
	},
	STAX: {
		key: 'STAX',
		title: 'Stax',
		hash: '7e65ad8c79ae01a80ee9bf5da6e935074457457d',
	},
	QUICK_DRAW: {
		key: 'QUICK_DRAW',
		title: 'Quick Draw',
		hash: '0f52469495304a956e300c32e2a44530daf3ec5b',
	},
	ALPHA_BLITZ: {
		key: 'ALPHA_BLITZ',
		title: 'Alpha Blitz',
		hash: 'ddb1a534bbab1cf73240287a13e3b56ed49cad7b',
	},
	FINAL_BOSS: {
		key: 'FINAL_BOSS',
		title: 'Final Boss',
		hash: '14a79d9c6599ceb07085d7367dad3de4213abd51',
	},
};
