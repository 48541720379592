import GameRoundBase from '@@Components/Tests/GameRoundBase';
import ArrowSet from './ArrowSet';
import { playingStates } from '@@Constants/playingStates';


class ArrowsRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.arrowSet = new ArrowSet(this.gameBoard, this, this.settings.arrowSetSettings);
		this.countDown = new Date();
	}

	isRoundOver() {
		return this.arrowSet.currentPatternNumber >= this.arrowSet.settings.numberOfPatterns;
	}

	draw() {
		switch (this.gameBoard.props.playingState) {
			case playingStates.ANSWER:
				this.arrowSet.draw();
				break;
			case playingStates.COUNT_DOWN: {
				this.drawCountDown();
				break;
			}
			default: break;
		}
	}

	drawCountDown() {
		const { ctx } = this.gameBoard;
		ctx.font = this.settings.countDownFont;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillStyle = this.settings.countDownColor;
		const timeLeft = Math.floor((this.settings.countDownLength - (new Date() - this.countDown)) / 1000);
		ctx.fillText(timeLeft, this.gameBoard.canvasCenter.x, this.gameBoard.canvasCenter.y);
	}

	startAnswerState() {
		this.countDown = null;
		this.startTime = new Date();
	}

	leftAnswer() {
		if (this.gameBoard.props.playingState === playingStates.ANSWER) {
			this.arrowSet.leftAnswer();
		}
	}

	rightAnswer() {
		if (this.gameBoard.props.playingState === playingStates.ANSWER) {
			this.arrowSet.rightAnswer();
		}
	}

	render() {
		return null;
	}

	shouldDisableArrowButtons() {
		return this.gameBoard.props.playingState === playingStates.COUNT_DOWN
			|| this.arrowSet.shouldDrawBreakScreen()
			|| this.arrowSet.gamePieces[this.arrowSet.currentPatternNumber]?.enteredTime !== null;
	}
}

export default ArrowsRound;
