import { playingStates } from '@@Constants';
import ClickableCircleBase from '../ClickableCircleBase';


class Circle extends ClickableCircleBase {
	constructor(
		gameBoard,
		round,
		circleSettings,
		position,
		value,
	) {
		super(gameBoard, round, circleSettings, position);
		this.value = value;
		this.enteredValue = null;
		this.clickedTime = null;
		this.IncorrectClicks = 0;
	}

	drawDisplay() {
		if (this.isSelected) {
			this.fill(this.settings.color);
		}
	}

	drawAnswers() {
		if (this.isSelected) {
			this.fill(this.settings.color);
		}
	}

	drawValues() {
		this.drawText(this.value);
	}

	draw() {
		super.draw();
		this.drawValues();
	}

	checkClicked(clickPosition, lastSelected) {
		if (this.isInsideOf((clickPosition))) {
			if (this.isAllowedToBeClicked(lastSelected)) {
				this.handleClicked();
				return this.value;
			} else {
				this.IncorrectClicks++;
				alert("(>'.')>Wrong Circle!");
			}
		}
	}

	isCorrect() {
		return this.isSelected;
	}

	centerPoint() {
		return this.position;
	}

	drawText(textToDraw) {
		const { ctx } = this.gameBoard;
		const center = this.centerPoint();
		ctx.beginPath();
		if (this.isSelected) {
			ctx.fillStyle = this.settings.selectedTextColor;
		} else {
			ctx.fillStyle = this.settings.textColor;
		}

		ctx.font = this.settings.font;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(textToDraw, center.x, center.y);
		ctx.closePath();
	}

	isAllowedToBeClicked(lastSelected) {
		let currentValue = parseInt(this.value, 10);
		if (Number.isNaN(currentValue)) {
			currentValue = this.value.charCodeAt(0);
			const lastValue = parseInt(lastSelected, 10);
			if (currentValue === (65 + lastValue)) {
				return true;
			}
		} else if (String.fromCharCode(currentValue + 64) === lastSelected) {
			return true;
		}

		return false;
	}
}

export default Circle;
