
import { playingStates } from '@@Constants';
import ClickableSquareBase from '../ClickableSquareBase';


class Square extends ClickableSquareBase {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		super(gameBoard, round, squareSettings, position);
		this.actualValue = Math.floor(Math.random() * 10);
		this.enteredValue = null;
	}

	centerPoint() {
		return {
			x: this.position.x + (this.settings.size.x / 2),
			y: this.position.y + (this.settings.size.y / 2) + 3,
		};
	}

	drawAnswers() {
		if (this.isSelected) {
			this.fill(this.settings.color);
		}

		if (this.enteredValue != null) {
			this.drawText(this.enteredValue);
		}
	}

	drawDisplay() {
		this.drawText(this.actualValue);
	}

	drawText(textToDraw) {
		const { ctx } = this.gameBoard;
		const center = this.centerPoint();
		ctx.beginPath();
		if (this.isSelected) {
			ctx.fillStyle = this.settings.selectedTextColor;
		} else {
			ctx.fillStyle = this.settings.textColor;
		}

		ctx.font = this.settings.font;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.textBaseline = 'middle';
		ctx.fillText(textToDraw, center.x, center.y);
		ctx.closePath();
	}

	checkClicked(clickPosition) {
		this.isSelected = false;
		super.checkClicked(clickPosition);
	}

	handleKeyPress(key) {
		const keyInt = parseInt(key, 10);
		if (this.isSelected) {
			if (keyInt >= 0 && keyInt <= 9) {
				this.enteredValue = keyInt;
			} else if (keyInt === 'Backspace' || keyInt === 'Delete') {
				this.enteredValue = null;
			}
		}
	}

	isCorrect() {
		return this.enteredValue !== null && this.actualValue === this.enteredValue;
	}

	isInCorrect() {
		return this.enteredValue !== null && this.actualValue !== this.enteredValue;
	}

	isBlank() {
		return this.enteredValue === null;
	}

	selectSquare() {
		this.isSelected = true;
	}

	unSelectSquare() {
		this.isSelected = false;
	}
}

export default Square;
