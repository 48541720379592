
import { playingStates } from '@@Constants';
import AbstractGamePiece from '@@Components/Tests/AbstractGamePiece';


class SquareBase extends AbstractGamePiece {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		super(gameBoard, round, squareSettings, position);
	}

	draw(isDisplayed = false) {
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;

		ctx.beginPath();
		ctx.fillStyle = this.settings.boarderColor;
		ctx.lineWidth = this.settings.boarderSize;
		ctx.rect(this.position.x,
			this.position.y,
			this.settings.size.x,
			this.settings.size.y);
		ctx.stroke();
		ctx.closePath();

		if (this.isHovered) {
			this.fill(this.settings.hoverColor);
		}

		if (isDisplayed) {
			this.fill(this.settings.color);
		}
	}

	fill(color) {
		const { ctx } = this.gameBoard;
		ctx.beginPath();
		ctx.fillStyle = color;
		ctx.rect(this.position.x + this.settings.boarderSize / 2,
			this.position.y + this.settings.boarderSize / 2,
			this.settings.size.x - this.settings.boarderSize,
			this.settings.size.y - this.settings.boarderSize);
		ctx.fill();
		ctx.closePath();
	}

	isInsideOf(clickPosition) {
		if (clickPosition.x > this.position.x && clickPosition.x < (this.position.x + this.settings.size.x)
			&& clickPosition.y > this.position.y && clickPosition.y < (this.position.y + this.settings.size.y)) {
			return true;
		}

		return false;
	}

	addHover() {
		this.isHovered = true;
	}

	removeHover() {
		this.isHovered = false;
	}
}

export default SquareBase;
