
import GameBoardStructureBase from '@@Components/Tests/GameBoardStructureBase';


class Grid extends GameBoardStructureBase {
	constructor(
		gameBoard,
		round,
		gridSettings,
		position = null,
	) {
		super(gameBoard, round, gridSettings, position);
		for (let i = 0; i < this.settings.numberOfSquares.x; i++) {
			for (let j = 0; j < this.settings.numberOfSquares.y; j++) {
				this.gamePieces.push(this.createGamePiece({
					x: this.position.x + (this.settings.square.size.x * i),
					y: this.position.y + (this.settings.square.size.y * j),
				}));
			}
		}
	}
}

export default Grid;
