import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '@material-ui/core';

import { colors } from '@@Theme';


const propTypes = {
	label: PropTypes.string,
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

const CircleButton = ({
	label = 'Press',
	x,
	y,
	onClick,
	disabled,
}) => {
	return (
		<Button
			onClick={onClick}
			style={{
				color: 'white',
				backgroundColor: colors.primary.main,
				width: 150,
				height: 150,
				borderRadius: '50%',
				border: '10px solid black',
				position: 'absolute',
				top: `${y - 75}px`,
				left: `${x - 75}px`,
			}}
			disabled={disabled}
		>
			<Typography
				variant="h3"
				component="span"
			>
				{label}
			</Typography>
		</Button>
	);
};

CircleButton.propTypes = propTypes;

export default CircleButton;
