import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@material-ui/core';

import * as Styles from '@@Components/Tests/Tests.styles';


const propTypes = {
	title: PropTypes.string.isRequired,
	instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.element.isRequired,
};

const TestLayout = ({
	title,
	instructions,
	children,
}) => {
	return (
		<Box px={4}>
			<Grid container wrap="nowrap">
				<Grid item xs>
					<Box py={4} mr={4}>
						<Typography variant="h1" gutterBottom>
							{title}
						</Typography>
						<Typography style={{ textTransform: 'uppercase' }}>
							{instructions}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs="auto">
					<Styles.TestWrapper>
						{children}
					</Styles.TestWrapper>
				</Grid>
			</Grid>
		</Box>
	);
};

TestLayout.propTypes = propTypes;

export default TestLayout;
