import { playingStates } from '@@Constants';
import ClickableSquareBase from '../ClickableSquareBase';


class Square extends ClickableSquareBase {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		super(gameBoard, round, squareSettings, position);
		this.isPartOfPattern = false;
	}

	drawDisplay() {
		if (this.isPartOfPattern) {
			this.fill(this.settings.color);
		}
	}

	drawAnswers() {
		if (this.isSelected) {
			this.fill(this.settings.color);
		}
	}

	isCorrect() {
		return this.isPartOfPattern === this.isSelected
			&& this.isSelected;
	}

	isInCorrect() {
		return this.isSelected !== this.isPartOfPattern
			&& this.isSelected;
	}

	isBlank() {
		return !this.isSelected;
	}
}

export default Square;
