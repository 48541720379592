import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

import { Button, Box, Grid, Typography } from '@material-ui/core';

import ClickBlitz from '@@Components/Tests/ClickBlitz/ClickBlitz';
import Arrows from '@@Components/Tests/Arrows/Arrows';
import GhostBlock from '@@Components/Tests/GhostBlock/GhostBlock';
import NumberBlock from '@@Components/Tests/NumberBlock/NumberBlock';
import Pinpoint from '@@Components/Tests/Pinpoint/Pinpoint';
import Stax from '@@Components/Tests/Stax/Stax';
import QuickDraw from '@@Components/Tests/QuickDraw/QuickDraw';
import AlphaBlitz from '@@Components/Tests/AlphaBlitz/AlphaBlitz';
import FinalBoss from '@@Components/Tests/FinalBoss/FinalBoss';
import { appStates, games } from '@@Constants';
import { shuffleArray } from '@@Utils';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


const gamesArray = Object.values(games);
const numberArray = shuffleArray(Array.from(Array(gamesArray.length).keys()));
const gameOrder = numberArray.map((index) => gamesArray[index]);

// move Final Boss to end of array
gameOrder.push(gameOrder.splice(gameOrder.indexOf(games.FINAL_BOSS), 1)[0]);


const propTypes = {
	activeTest: PropTypes.objectOf(PropTypes.any),
	setActiveTest: PropTypes.func,
	setAppState: PropTypes.func,
};

const Tests = ({
	activeTest,
	setActiveTest,
	setAppState,
}) => {

	const testsRef = useRef();

	useEffect(() => {
		if (testsRef.current) {
			testsRef.current.scrollIntoView();
		}
	}, [activeTest]);

	const handleNext = async () => {
		if (!activeTest) {
			const sessionObj = { presented_game_order: JSON.stringify(gameOrder.map((game) => game.title)) };
			await api.action(ENTITIES.COMPLETED_SECTIONS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, sessionObj);
			setActiveTest(gameOrder[0]);
		} else if (activeTest === gameOrder[gameOrder.length - 1]) {

			// update participant, marking section as complete
			const participantObj = { gamesComplete: 1 };
			await api.action(ENTITIES.PARTICIPANTS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, participantObj);

			// update section as complete
			await api.updateCompletedSections({
				[appStates.TESTS]: {
					complete: true,
				},
			});

			setAppState(appStates.POST_ASSESSMENT);
		} else {
			setActiveTest(gameOrder[gameOrder.indexOf(activeTest) + 1]);
		}
	};

	const renderTest = (test) => {
		switch (test) {
			case games.CLICK_BLITZ:
				return <ClickBlitz setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.ARROWS:
				return <Arrows setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.GHOST_BLOCK:
				return <GhostBlock setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.NUMBER_BLOCK:
				return <NumberBlock setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.PINPOINT:
				return <Pinpoint setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.STAX:
				return <Stax setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.QUICK_DRAW:
				return <QuickDraw setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.ALPHA_BLITZ:
				return <AlphaBlitz setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			case games.FINAL_BOSS:
				return <FinalBoss setAppState={setAppState} activeTest={activeTest} handleNext={handleNext} />;
			default:
				return (
					<Typography color="textPrimary" variant="h1" align="center">
						test {activeTest.title}
					</Typography>
				);
		}
	};

	const ActiveTest = () => renderTest(activeTest);

	return (
		<Box
			display="flex"
			height="100%"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			pt={4}
		>
			{!activeTest && (
				<Fragment>
					<Grid container spacing={4} justify="center" alignItems="center">
						<Grid item xs={12}>
							<Box px={5} mx={5} mt={3}>
								<Typography align="center" variant="h4" color="textPrimary">
									You are about to enter the game portion of this assessment.
									Each game consists of multiple rounds. Do your best to move through the
									games as accurately and quickly as possible. Please complete all games
									during this session. If you are not able to finish the session,
									you will be asked to restart the game portion if you re-enter the assessment.
									Also be aware that there are several tools built into this assessment which could
									indicate if you are trying to gain an unfair advantage. Please complete this assessment
									with no additional help, by yourself. Have fun.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs="auto">
							<Typography variant="h3" component="ol" color="textPrimary" style={{ lineHeight: '1.5em' }}>
								{gameOrder && gameOrder.map((game) => {
									return (
										<li key={uid(game.title)}>
											{game.title}
										</li>
									);
								})}
							</Typography>
						</Grid>
					</Grid>

					<Box mt={5}>
						<Typography align="center">
							<Button variant="contained" fullWidth={false} onClick={handleNext}>
								Go
							</Button>
						</Typography>
					</Box>
				</Fragment>
			)}

			{activeTest && (
				<Box ref={testsRef} width="100%">
					<ActiveTest />
				</Box>
			)}
		</Box>
	);
};

Tests.propTypes = propTypes;

export default Tests;
