
class Npc {
	constructor(
		gameBoard,
		round,
		npcSettings,
		position = null,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.settings = npcSettings;
		this.position = position;
		this.userClickTime = null;
		this.userClick = null;
		this.timeOfCreation = new Date();
		this.displayTime = this.settings.displayTime;
		// displayTime: this.settings.displayTime,
		// text: this.settings.text,
		// color: this.settings.color,
		// font: this.settings.font,
	}

	shouldDisplay() {
		return new Date() - this.timeOfCreation < this.displayTime;
	}

	draw() {
		if (this.shouldDisplay()) {
			const { ctx } = this.gameBoard;
			ctx.beginPath();
			ctx.fillStyle = this.settings.color;
			ctx.font = this.settings.font;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(this.settings.text, this.position.x, this.position.y);
			ctx.closePath();
		}
	}

	ReactionTimeOfUserClick() {
		return this.userClickTime - this.timeOfCreation;
	}

	distanceOfUserClick() {
		return {
			x: Math.abs(this.position.x - this.userClick.x),
			y: Math.abs(this.position.y - this.userClick.y),
		};
	}

	isCorrect() {
		return this.userClick != null
			&& this.position.x === this.userClick.x
			&& this.position.y === this.userClick.y;
	}

	isIncorrect() {
		return this.userClick != null
			&& (this.position.x !== this.userClick.x
				|| this.position.y !== this.userClick.y);
	}

	detailedInfo() {
		return {
			position: this.position,
			userClick: this.userClick,
			distanceOfUserClick: this.distanceOfUserClick(),
			reactionTimeOfUserClick: this.ReactionTimeOfUserClick(),
		};
	}
}

module.exports = Npc;
