
import { playingStates } from '@@Constants';
import Square from './Square';
import GridBase from '../GridBase';


class Grid extends GridBase {
	constructor(
		gameBoard,
		round,
		gridSettings,
		position = null,
	) {
		super(gameBoard, round, gridSettings, position);

		for (let i = 0; i < this.settings.squaresInPattern; i++) {
			let randomIndex = Math.floor(Math.random() * (this.gamePieces.length - i));
			let currentIndex = 0;
			while (randomIndex !== 0 || this.gamePieces[currentIndex].isPartOfPattern) {
				if (!this.gamePieces[currentIndex].isPartOfPattern) {
					randomIndex--;
				}

				currentIndex++;
			}

			this.gamePieces[currentIndex].isPartOfPattern = true;
		}
	}

	createGamePiece(position) {
		return new Square(
			this.gameBoard,
			this.round,
			this.settings.square,
			position,
		);
	}

	draw() {
		super.draw();
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;
		if (playingState === playingStates.DISPLAY) {
			ctx.font = this.settings.timerFont;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillStyle = this.settings.timerColor;
			const timeLeft = Math.floor((this.settings.displayTime - this.round.elapsedTime()) / 1000);
			ctx.fillText(timeLeft, this.position.x + this.settings.timerOffset.x, this.position.y + this.settings.timerOffset.y);
		}
	}

	checkClicked(clickPosition) {
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.checkClicked(clickPosition);
		});
	}

	numberCorrect() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCorrect()) {
				++output;
			}
		});

		return output;
	}

	percentCorrect() {
		const numberSelected = this.numberOfSelectedPieces();
		return parseFloat((this.numberCorrect() / (numberSelected < 1 ? 1 : numberSelected) * 100).toFixed(4));
	}

	numberIncorrect() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isInCorrect()) {
				++output;
			}
		});

		return output;
	}

	numberBlank() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isBlank()) {
				++output;
			}
		});

		return output;
	}

	numberOfSelectedPieces() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isSelected) {
				++output;
			}
		});

		return output;
	}

	percentIncorrect() {
		const numberSelected = this.numberOfSelectedPieces();
		return parseFloat((this.numberIncorrect() / (numberSelected < 1 ? 1 : numberSelected) * 100).toFixed(4));
	}

	percentBlank() {
		return parseFloat((this.numberBlank() / this.numberOfPieces() * 100).toFixed(4));
	}

	numberOfPatternPieces() {
		return this.settings.squaresInPattern;
	}

	isGridComplete() {
		return this.endTime !== null;
	}
}

export default Grid;
