
class PlayerInput {
	constructor(
		gameBoard,
		round,
		sequence,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.sequence = sequence.slice();
		this.userEnteredSequence = [];
		this.userEnteredSequenceTime = [];
		this.startTime = null;
		this.endTime = null;
	}

	startInput() {
		this.startTime = new Date();
	}

	addUserEnteredValue(value) {
		this.userEnteredSequence.push(value);
		this.userEnteredSequenceTime.push(new Date());
	}

	endInput(value) {
		this.endTime = new Date();
	}

	lastUserInputTime() {
		if (this.userEnteredSequenceTime.length <= 0) {
			return null;
		}

		return this.userEnteredSequenceTime[this.userEnteredSequenceTime.length - 1];
	}

	shouldDisplayAnswer() {
		return new Date() - this.lastUserInputTime() < this.round.grid.settings.answerDisplayTime;
	}

	lastUserInputIndex() {
		if (this.userEnteredSequence.length <= 0) {
			return null;
		}

		return this.userEnteredSequence[this.userEnteredSequence.length - 1];
	}

	hasInputFinished() {
		return this.userEnteredSequenceTime.length === this.sequence.length || !this.isEnteredSequenceCorrect();
	}

	hasTimedOut() {
		return this.endTime !== null && this.userEnteredSequenceTime.length !== this.sequence.length;
	}

	isEnteredSequenceCorrect() {
		if (this.endTime !== null) {
			return false;
		}

		for (let i = 0; i < this.userEnteredSequence.length; i++) {
			if (this.sequence[i] !== this.userEnteredSequence[i]) {
				return false;
			}
		}

		return true;
	}
}

export default PlayerInput;
