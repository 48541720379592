import ArrowLine from './ArrowLine';
import GameBoardStructureBase from '../GameBoardStructureBase';


const loadingImage = new Image();
const loadingImageSize = 48;
let loadingImageRotation = 0;
loadingImage.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABrUlEQVRYw+WVPW7CQBCFnwDxI+HeSC4RJR1d0oIQLUeAc1hyGSkFiPVBfAGX5ghINBEWPQLkdlNk2Jhgs7vxbsW4ffO+9Xj2GXi98m0DuG0Et43gthHcNoLbQDQxBkOMPS45gCGEC4bzna1BRAsBrqXmlQEukqfmFe2HSHNWBzDM0EfX3Ol/7Y9YoG52TVu54URwzF+0QNivUVOOipGqfU9sTvRgXx52PjJ4aoBQzN4pNCq25+AI1W7tiQBL5ZG+U8cJHbl4QuI0tzmyauBAXXO5mJF0o7UWn9S1kktjkk61AFPq2sqlO5IOtACeGKy0bsnpaAHa1JXJpRlJ21oAh7rOcuktgzwtwIC6dnLplqSTf33kWC5dkfRDC7ChLiaXzkn6hYayfV0MVuG9OyIq3pQBSxEVTfWwKwu1og06EiBUO4+H7Elm/q0aIrK/oqf6yqNSe/5gvxY/pwCVyieb++FEwj5By4Q9z23OQsyeI4Vrxp6jiz5mYOIP8GM/NGVf9CTmTv/4XBFUm/0zwBms2tmLERfsEYNhrHZr9RGWyrcNKL5oVhCwjYBtxMvVNy1NaGxXWHiWAAAAAElFTkSuQmCC';

class ArrowSet extends GameBoardStructureBase {
	constructor(
		gameBoard,
		round,
		settings,
		position = null,
	) {
		super(gameBoard, round, settings, position);
		this.currentPatternNumber = 0;
		for (let i = 0; i < this.settings.numberOfPatterns; i++) {
			this.gamePieces.push(this.createGamePiece(this.position, (i % 2 === 0)));
		}
	}

	shouldDrawGamePiece() {
		return (new Date() - this.gamePieces[this.currentPatternNumber].startTime) <= this.settings.patternDisplayLength;
	}

	shouldDrawBreakScreen() {
		return this.currentPatternNumber >= this.settings.numberOfPatterns
			|| (!((new Date() - this.gamePieces[this.currentPatternNumber].startTime) <= this.settings.patternDisplayLength)
			&& this.currentPatternNumber < this.gamePieces.length - 1);
	}

	draw() {
		this.update();
		if (this.currentPatternNumber < this.settings.numberOfPatterns) {
			if (this.shouldDrawGamePiece()) {
				this.gamePieces[this.currentPatternNumber].draw();
			} else if (this.shouldDrawBreakScreen()) {
				this.displayBreakScreen();
			}
		}
	}

	update() {
		this.currentPatternNumber = Math.floor(this.round.elapsedTime()
			/ (this.settings.patternDisplayLength + this.settings.breakLength));
		if (this.gamePieces[this.currentPatternNumber] && this.gamePieces[this.currentPatternNumber].startTime == null) {
			this.gamePieces[this.currentPatternNumber].startTime = new Date();
		}
	}

	displayBreakScreen() {
		const { ctx } = this.gameBoard;

		/*
		ctx.beginPath();
		ctx.fillStyle = this.settings.arrowLineSettings.textColor;
		ctx.font = this.settings.arrowLineSettings.font;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		const timeLeft = Math.floor(
			(this.settings.breakLength
				- (new Date()
				- this.gamePieces[this.currentPatternNumber].startTime
				- this.settings.patternDisplayLength))
			/ 1000,
		);
		ctx.fillText(timeLeft, this.position.x, this.position.y);
		ctx.closePath();
		*/

		const x = this.position.x;
		const y = this.position.y;

		ctx.globalCompositeOperation = 'destination-over';
		ctx.save();
		ctx.clearRect(0, 0, loadingImageSize, loadingImageSize);
		ctx.translate(x, y);
		loadingImageRotation += 1;
		ctx.rotate(loadingImageRotation * Math.PI / 64);
		ctx.translate(-(loadingImageSize / 2), -(loadingImageSize / 2));
		ctx.globalAlpha = 0.5;
		ctx.drawImage(loadingImage, 0, 0);
		ctx.restore();

	}

	createGamePiece(position, isCongruentPattern) {
		return new ArrowLine(
			this.gameBoard,
			this.round,
			this.settings.arrowLineSettings,
			position,
			isCongruentPattern,
		);
	}

	numberCongruentCorrect() {
		let correct = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCorrect() && gamePiece.isCongruentPattern) {
				correct++;
			}
		});

		return correct;
	}

	numberCongruentIncorrect() {
		let correct = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isIncorrect() && gamePiece.isCongruentPattern) {
				correct++;
			}
		});

		return correct;
	}

	numberIncongruentCorrect() {
		let correct = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCorrect() && !gamePiece.isCongruentPattern) {
				correct++;
			}
		});

		return correct;
	}

	numberIncongruentIncorrect() {
		let correct = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isIncorrect() && !gamePiece.isCongruentPattern) {
				correct++;
			}
		});

		return correct;
	}

	numberOfAttempts() {
		return this.currentPatternNumber < this.settings.numberOfPatterns
			? this.currentPatternNumber
			+ (this.gamePieces[this.currentPatternNumber].enteredValue === null
				? 0
				: 1)
			: this.gamePieces.length;
	}

	numberOfIncongruentAttempts() {
		let answered = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isAnswered() && !gamePiece.isCongruentPattern) {
				answered++;
			}
		});

		return answered;
	}

	numberOfCongruentAttempts() {
		let answered = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isAnswered() && gamePiece.isCongruentPattern) {
				answered++;
			}
		});

		return answered;
	}

	summedCongruentReactionTime() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCongruentPattern && gamePiece.enteredTime != null) {
				output += gamePiece.reactionTime();
			}
		});

		return output;
	}

	averageCongruentReactionTime() {
		let numberOfAttempts = this.numberOfCongruentAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedCongruentReactionTime() / numberOfAttempts).toFixed(4));
	}

	summedIncongruentReactionTime() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (!gamePiece.isCongruentPattern && gamePiece.enteredTime != null) {
				output += gamePiece.reactionTime();
			}
		});

		return output;
	}

	averageIncongruentReactionTime() {
		let numberOfAttempts = this.numberOfIncongruentAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedIncongruentReactionTime() / numberOfAttempts).toFixed(4));
	}

	leftAnswer() {
		this.gamePieces[this.currentPatternNumber].leftAnswer();
	}

	rightAnswer() {
		this.gamePieces[this.currentPatternNumber].rightAnswer();
	}


}

export default ArrowSet;
