
import AbstractGameBoardStructure from '@@Components/Tests/AbstractGameBoardStructure';


class GameBoardStructureBase extends AbstractGameBoardStructure {
	constructor(
		gameBoard,
		round,
		settings,
		position = null,
	) {
		super(gameBoard, round, settings, position);
		if (!position) {
			this.position = this.settings.position;
		}

		this.gamePieces = [];
	}

	startDisplay() {
		this.displayStartTime = new Date();
	}

	draw() {
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.draw();
		});
	}

	checkClicked(clickPosition) {
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.checkClicked(clickPosition);
		});
	}

	checkHover(position) {
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isInsideOf(position)) {
				gamePiece.addHover();
			} else {
				gamePiece.removeHover();
			}
		});
	}

	clearHover() {
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.removeHover();
		});
	}

	numberOfPieces() {
		return this.gamePieces.length;
	}
}

export default GameBoardStructureBase;
