import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const propTypes = {
	heading: PropTypes.string,
	children: PropTypes.element.isRequired,
};

const ScoreBoard = ({
	heading = 'Scoreboard',
	children,
}) => {
	return (
		<Box
			position="absolute"
			top={0}
			right={0}
			width={200}
			p={3}
			m={2}
			style={{
				backgroundColor: '#fff',
				border: '1px solid #ddd',
			}}
		>
			<Typography variant="h4">
				{heading}
			</Typography>
			<Box my={4}>
				{children}
			</Box>
		</Box>
	);
};

ScoreBoard.propTypes = propTypes;

export default ScoreBoard;
