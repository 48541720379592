import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';

import { Box, Grid, Typography, TextField, Button } from '@material-ui/core';

import { getRandomInt, shuffleArray } from '@@Utils';

import BlockSVG from '@@Assets/block.svg';


const GRID_SIZE = 5;
const CHOICE_COUNT = 5;
const CHOICE_VARIANCE = 0.5;


const propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	gameBoard: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	grid: PropTypes.array,
	inputUserValue: PropTypes.number,
};

const StaxBlocks = ({
	gameBoard,
	grid,
	inputUserValue,
}) => {

	const [userAnswer, setUserAnswer] = useState(inputUserValue);

	const Block = ({ x, y }) => {
		const xOffset = 250;
		const yOffset = 240;
		const blockWidth = 100;

		return (
			<BlockSVG style={{ position: 'absolute', width: blockWidth, left: x + xOffset, top: y + yOffset }} />
		);
	};

	Block.propTypes = {
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
	};

	const BlockStack = ({ row = 1, col = 1, height = 1 }) => {

		// xBaseValues = [0, -50, -100, -150, -200];
		// yBaseValues = [60, 90, 120, 150, 180];

		const xBaseValue = 200;
		const yBaseValue = 60;
		const xFactor = -50;
		const yFactor = 30;
		const yLevelFactor = -60;

		const xValue = xBaseValue + ((row - 1) * xFactor);
		const yValue = yBaseValue + ((row - 1) * yFactor);

		return (
			<Fragment>
				{Array.from(Array(height).keys()).map((i) => {
					return (
						<Block
							key={uid(i)}
							x={xValue + ((col - 1) * -(xFactor))}
							y={yValue + (yLevelFactor * i) + ((col - 1) * yFactor)}
						/>
					);
				})}
			</Fragment>
		);
	};

	BlockStack.propTypes = {
		row: PropTypes.number.isRequired,
		col: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
	};

	const getInitialValues = () => {
		return {
			stackCount: null,
		};
	};

	/* eslint-disable no-param-reassign */
	const totalCount = grid.reduce((a, c) => {
		const rowCount = c.reduce((aa, cc) => {
			aa += cc;
			return aa;
		}, 0);
		a += rowCount;
		return a;
	}, 0);
	/* eslint-enable no-param-reassign */

	const getCountChoices = () => {
		const choices = [totalCount];

		while (choices.length < CHOICE_COUNT) {
			const variance = totalCount * CHOICE_VARIANCE;
			const random = getRandomInt(totalCount - variance, totalCount + variance);
			if (!choices.includes(random)) {
				choices.push(random);
			}
		}

		shuffleArray(choices);
		return choices;
	};

	function handleTextChange(event) {
		const inputValue = parseInt(event.target.value, 10);
		setUserAnswer(inputValue || 0);
	}

	function handleSubmit() {
		gameBoard.endQuestion(totalCount, userAnswer || 0);
		setUserAnswer('');
	}

	const choices = getCountChoices();

	return (
		<Fragment>
			<Grid item xs={12}>
				<Box>
					{grid.map((heights, i) => {
						return (
							<Fragment key={uid(i)}>
								{heights.map((height, j) => {
									return (
										<BlockStack
											key={uid({ j, i })}
											row={j + 1}
											col={i + 1}
											height={height}
										/>
									);
								})}
							</Fragment>
						);
					})}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Grid item xs={12} align="center">
					<TextField
						align="center"
						name="stackCount"
						label="How Many Blocks?"
						value={userAnswer}
						onChange={handleTextChange}
					/>
					<Button
						align="center"
						onClick={handleSubmit}
					>
						<Typography
							variant="h3"
							component="span"
						>
							Submit
						</Typography>
					</Button>
				</Grid>
			</Grid>
		</Fragment>
	);
};

StaxBlocks.propTypes = propTypes;

export default StaxBlocks;
