import hash from 'object-hash';
import React from 'react';
import { playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import StaxBlocks from '@@Components/Tests/Stax/StaxBlocks';
import { getRandomInt } from '@@Utils';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class StaxGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 3;
		this.settings.roundSettings = {
			gridSettings: {
				size: 5,
			},
			numberOfQuestions: 5,
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			this.draw();
		}, 1000 / 60);
	}

	clear() {

	}

	createNewRound() {
		return {
			settings: this.settings.roundSettings,
			questions: [],
			startTime: new Date(),
			endTime: null,
			totalCorrectAnswers() {
				let output = 0;
				this.questions.forEach((question) => {
					if (question.isAnswerCorrect()) {
						output++;
					}
				});

				return output;
			},
			draw() {

			},
			totalIncorrectAnswers() {
				let output = 0;
				this.questions.forEach((question) => {
					if (question.isAnswerIncorrect()) {
						output++;
					}
				});

				return output;
			},
			totalQuestionTime() {
				let totalTime = 0;
				this.questions.forEach((question) => {
					totalTime += question.elapsedTime();
				});

				return totalTime;
			},
			averageQuestionTime() {
				return this.totalQuestionTime() / this.questions.length;
			},
			isRoundOver() {
				return this.questions.length >= this.settings.numberOfQuestions && this.userAnswer !== null;
			},
			elapsedTime() {
				const { startTime, endTime } = this;

				return startTime == null
					? 0
					: ((endTime == null ? new Date() : endTime) - startTime);
			},
			elapsedTimeSeconds() {
				return this.elapsedTime() / 1000;
			},
			endRound() {
				this.endTime = new Date();
			},
		};
	}


	addQuestionToRound() {
		const { scoreBoard } = this.state;
		const rounds = [...scoreBoard.rounds];
		const round = { ...rounds[rounds.length - 1] };
		round.questions.push({
			answer: null,
			userAnswer: null,
			startTime: new Date(),
			endTime: null,
			grid: this.getStackHeights(),
			isAnswerCorrect() {
				return this.userAnswer !== null && this.answer === this.userAnswer;
			},
			isAnswerIncorrect() {
				return this.userAnswer !== null && this.answer !== this.userAnswer;
			},
			elapsedTime() {
				const { startTime, endTime } = this;

				return startTime == null
					? 0
					: ((endTime == null ? new Date() : endTime) - startTime);
			},
		});

		rounds[rounds.length - 1] = round;
		scoreBoard.rounds = rounds;
		this.setState({ scoreBoard });
	}

	getStackHeights() {
		let maxHeights = [];
		return [...Array(this.settings.roundSettings.gridSettings.size)].map(() => {
			const heights = [...Array(this.settings.roundSettings.gridSettings.size)].map((_, j) => {
				let maxHeight;
				let minHeight;

				if (maxHeights.length === 0) {
					// start first row with 3-5 blocks
					minHeight = 1;
					maxHeight = 4;
				} else {
					// subsequent rows are limited to previous rows value to prevent hidden blocks
					maxHeight = maxHeights[j];
					minHeight = 0;
				}

				return getRandomInt(minHeight, maxHeight);
			});

			heights.sort((a, b) => b - a);

			maxHeights = [...heights];
			return heights;
		});
	}

	handleCanvasClick(event) {
		// don't need to handle click
	}

	handleMouseMove(event) {
		// don't need to handle mouse move
	}

	handleKeyPress(event) {
		// don't need to handle keypress
	}

	async startRound() {
		await super.startRound();
		this.addQuestionToRound();
		this.props.onPlayingStateUpdate(playingStates.DISPLAY);
	}

	endQuestion(answer, userAnswer) {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		const question = round.questions[round.questions.length - 1];
		question.answer = answer;
		question.userAnswer = userAnswer;
		question.endTime = new Date();
		if (round.questions.length < this.settings.roundSettings.numberOfQuestions) {
			this.addQuestionToRound();
		} else {
			this.endRound();
		}
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.draw();
	}

	totalIncorrectAnswers() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.totalIncorrectAnswers();
		});

		return output;
	}

	totalCorrectAnswers() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.totalCorrectAnswers();
		});

		return output;
	}

	totalQuestionTime() {
		let totalTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			totalTime += round.totalQuestionTime();
		});

		return totalTime;
	}

	totalQuestions() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.questions.length;
		});

		return output;
	}

	averageQuestionTime() {
		let totalQuestions = this.totalQuestions();
		totalQuestions = totalQuestions === 0 ? 1 : totalQuestions;
		return this.totalQuestionTime() / totalQuestions;
	}

	totalElapsedTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_STAX_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					hash: hash(this.settings),
				},
				total_time: round.elapsedTime(),
				avg_time: parseFloat(round.averageQuestionTime().toFixed(4)),
				correct_answers: round.totalCorrectAnswers(),
				incorrect_answers: round.totalIncorrectAnswers(),
			});
		});
	}

	render() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (round && !round.isRoundOver()) {
			const question = round.questions[round.questions.length - 1];
			if (question && question.userAnswer === null) {
				return (
					<StaxBlocks
						gameBoard={this}
						grid={question.grid}
						inputUserValue={question.userAnswer}
					/>);
			}

			return null;
		}

		return null;
	}
}

export default StaxGame;
