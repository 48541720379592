import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { camelizeKeys } from 'humps';

import {
	Box,
	Button,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';

import * as yup from 'yup';
import { Form, Formik } from 'formik';

import config from '@@Config';
import { appStates } from '@@Constants/appStates';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


const propTypes = {
	setAppState: PropTypes.func,
};

const Login = ({
	setAppState,
}) => {

	const [isSuccessfulLogin, setIsSuccessfulLogin] = useState(null);

	const getInitialValues = () => {
		return {
			code: '',
			codeValidation: '',
		};
	};

	const validationSchema = () => {
		const schema = {
			code: yup.string()
				.required('Please enter your assigned code.'),
			codeValidation: yup.string()
				.required('Please re-enter the assigned code.')
				.oneOf([yup.ref('code'), null], 'The assigned code does not match'),
		};

		return yup.object().shape(schema);
	};

	const handleSubmit = async (values) => {
		console.info('[Login]', values);

		const participantObj = {
			code: values.code,
		};

		try {

			const participant = await api.action(ENTITIES.PARTICIPANTS, ACTIONS.GET_BY_CODE, participantObj);

			if (participant.user) {
				setIsSuccessfulLogin(true);
				const response = await api.action(ENTITIES.COMPLETED_SECTIONS, ACTIONS.CURRENT);
				let { completedSections } = camelizeKeys(response);
				completedSections = JSON.parse(completedSections);

				if ((completedSections[appStates.POST_ASSESSMENT] || {}).complete) {
					setAppState(appStates.CONCLUSION);
				} else if ((completedSections[appStates.TESTS] || {}).complete) {
					setAppState(appStates.POST_ASSESSMENT);
				} else if ((completedSections[appStates.PRE_ASSESSMENT] || {}).complete) {
					setAppState(appStates.TESTS);
				} else if ((completedSections[appStates.PARTICIPANT_INFORMATION] || {}).complete) {
					setAppState(appStates.PRE_ASSESSMENT);
				} else {
					setAppState(appStates.PARTICIPANT_INFORMATION);
				}

			} else {
				setIsSuccessfulLogin(false);
			}
		} catch (err) {
			console.error('[error]', err);
			setIsSuccessfulLogin(false);
		}
	};

	return (
		<Box p={5}>
			<Typography variant="h1" gutterBottom>
				Restart Login
			</Typography>

			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({
					handleChange,
					handleBlur,
					values,
					errors,
					touched,
				}) => (
					<Form noValidate>

						<Typography>
							Welcome back. You have completed a portion of this assessment and are re-entering.
							If you began the games section, you will be taken back to the first game to accurately
							record metrics for this section. Please enter your assigned code to proceed.
						</Typography>

						<Box my={3}>
							<Grid container spacing={3}>
								<Grid item sm={3}>
									<TextField
										id="code"
										name="code"
										value={values.code}
										onChange={handleChange}
										onBlur={handleBlur}
										label="Your Assigned Code"
										variant="filled"
										fullWidth
										inputProps={{ maxLength: config.userCode.length }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid item sm={3}>
									<TextField
										id="code-validation"
										name="codeValidation"
										value={values.codeValidation}
										onChange={handleChange}
										onBlur={handleBlur}
										label="Re-Enter Assigned Code"
										helperText={touched.codeValidation ? errors.codeValidation : ''}
										error={errors.codeValidation && touched.codeValidation}
										variant="filled"
										fullWidth
										inputProps={{ maxLength: config.userCode.length }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>

							{isSuccessfulLogin === false && (
								<Typography color="error" variant="body2">
									The code you entered is not valid. Please re-enter your code or use the URL to start over.
								</Typography>
							)}

						</Box>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={((!touched.code || errors.code) || (!!errors.codeValidation))}
						>
							Submit
						</Button>

					</Form>
				)}
			</Formik>
		</Box>
	);
};

Login.propTypes = propTypes;

export default Login;
