import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {
	Box,
	Button,
	Grid,
	LinearProgress,
	Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import RadioButtonList, { validationSchemas as RadioButtonListValidationSchemas } from '@@Components/RadioButtonList';
import CheckboxList, { validationSchemas as CheckboxListValidationSchemas } from '@@Components/CheckboxList';
// import DebugOutput from '@@Components/DebugOutput';
import { appStates, formItemTypes } from '@@Constants';
import { normalize } from '@@Utils';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


const { RADIO_BUTTON_LIST, CHECKBOX_LIST } = formItemTypes;

const postAssessment = {
	FEEDBACK: {
		title: 'Feedback',
		fields: {
			ENJOYED_MOST: {
				name: 'enjoyedMost',
				type: RADIO_BUTTON_LIST,
				label: <span>Which of the assessments did you enjoy the <u>most</u>?</span>,
				options: [
					'Click Blitz',
					'Arrows',
					'Ghost Block',
					'Number Block',
					'Pinpoint',
					'Stax',
					'Quick Draw',
					'Alphablitz',
					'Final Boss',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			ENJOYED_LEAST: {
				name: 'enjoyedLeast',
				type: RADIO_BUTTON_LIST,
				label: <span>Which of the assessments did you enjoy the <u>least</u>?</span>,
				options: [
					'Click Blitz',
					'Arrows',
					'Ghost Block',
					'Number Block',
					'Pinpoint',
					'Stax',
					'Quick Draw',
					'Alphablitz',
					'Final Boss',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			HARDEST: {
				name: 'hardest',
				type: RADIO_BUTTON_LIST,
				label: 'Which of the assessments was the hardest?',
				options: [
					'Click Blitz',
					'Arrows',
					'Ghost Block',
					'Number Block',
					'Pinpoint',
					'Stax',
					'Quick Draw',
					'Alphablitz',
					'Final Boss',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			RECOMMENDATION_LIKELIHOOD: {
				name: 'recommendationLikelihood',
				type: RADIO_BUTTON_LIST,
				label: 'How likely are you to recommend this skills assessment to a friend or fellow esports athlete?',
				options: [
					'highly likely',
					'very likely',
					'neutral',
					'unlikely',
					'highly unlikely',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			ASSESSMENT_LENGTH_FEEDBACK: {
				name: 'assessmentLengthFeedback',
				type: RADIO_BUTTON_LIST,
				label: 'How would you characterize the length of the skills assessment?',
				options: [
					'much too short',
					'too short',
					'just right',
					'too long',
					'much too long',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
		},
	},
	VALUE: {
		title: 'Value',
		fields: {
			WILL_SHARE_ON_SOCIAL_MEDIA: {
				name: 'willShareOnSocialMedia',
				type: RADIO_BUTTON_LIST,
				label: 'Would you share scores from an assessment like this on social media?',
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			WHICH_SOCIAL_MEDIA: {
				name: 'whichSocialMedia',
				type: CHECKBOX_LIST,
				label: 'Where would you post or share this?',
				options: [
					'Discord',
					'eFuse',
					'Instagram',
					'Facebook',
					'Twitter',
					'TikTok',
					'Twitch',
					'YouTube',
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			DOES_SCORE_HAVE_VALUE: {
				name: 'doesScoreHaveValue',
				type: RADIO_BUTTON_LIST,
				label: 'Do you feel that this skills assessment score will be valuable in demonstrating to others your neurocognitive and psychomotor skills?',
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			COMPLETE_ASSESSMENT_HOW_OFTEN: {
				name: 'completeAssessmentHowOften',
				type: RADIO_BUTTON_LIST,
				label: 'How often would you come back to complete this skills assessment again?',
				options: [
					'only once',
					'once a year',
					'twice a year',
					'four times a year',
					'monthly',
					'multiple times a month',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			WOULD_PAY_MONEY: {
				name: 'wouldPayMoney',
				type: RADIO_BUTTON_LIST,
				label: 'Do you think other gamers would be willing to pay to complete this skill assessment?',
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			HOW_MUCH_MONEY: {
				name: 'howMuchMoney',
				type: RADIO_BUTTON_LIST,
				label: 'How much money?',
				options: [
					'< $10',
					'$10-$20',
					'$21-$30',
					'$31-$40',
					'$41-$50',
					'> $50',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
		},
	},
};

const { FEEDBACK, VALUE } = postAssessment;

const postAssessmentItems = [
	FEEDBACK.fields.ENJOYED_MOST,
	FEEDBACK.fields.ENJOYED_LEAST,
	FEEDBACK.fields.HARDEST,
	FEEDBACK.fields.RECOMMENDATION_LIKELIHOOD,
	FEEDBACK.fields.ASSESSMENT_LENGTH_FEEDBACK,
	VALUE.fields.WILL_SHARE_ON_SOCIAL_MEDIA,
	VALUE.fields.DOES_SCORE_HAVE_VALUE,
	VALUE.fields.COMPLETE_ASSESSMENT_HOW_OFTEN,
	VALUE.fields.WOULD_PAY_MONEY,
];

const propTypes = {
	setAppState: PropTypes.func,
};

const PostAssessment = ({
	setAppState,
}) => {
	const [activeItem, setActiveItem] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		async function fetchData() {
			// get last answered question from completed sections
			const completedSections = await api.getCompletedSections();
			if (completedSections[appStates.POST_ASSESSMENT]) {
				const lastQuestion = completedSections[appStates.POST_ASSESSMENT].completedQuestion;
				const index = postAssessmentItems.findIndex((item) => item.name === lastQuestion);
				setActiveItem(postAssessmentItems[index + 1]);
			} else {
				setActiveItem(postAssessmentItems[0]);
			}
		}
		fetchData();
	}, []);

	/* eslint-disable no-param-reassign */
	const getInitialValues = () => {
		return {
			...postAssessmentItems.reduce((a, c) => {
				a[c.name] = c.initialValue;
				return a;
			}, {}),
			[VALUE.fields.WHICH_SOCIAL_MEDIA.name]: [],
		};
	};
	/* eslint-enable no-param-reassign */

	const validationSchema = () => {
		switch (activeItem) {
			case VALUE.fields.WILL_SHARE_ON_SOCIAL_MEDIA: {
				return yup.object().shape({
					[activeItem.name]: activeItem.validationSchema,
					[VALUE.fields.WHICH_SOCIAL_MEDIA.name]: yup.array()
						.when(activeItem.name, {
							is: 'true',
							then: VALUE.fields.WHICH_SOCIAL_MEDIA.validationSchema,
						}),
				});
			}
			case VALUE.fields.WOULD_PAY_MONEY: {
				return yup.object().shape({
					[activeItem.name]: activeItem.validationSchema,
					[VALUE.fields.HOW_MUCH_MONEY.name]: yup.string()
						.when(activeItem.name, {
							is: 'true',
							then: VALUE.fields.HOW_MUCH_MONEY.validationSchema,
						}),
				});
			}
			default: break;
		}

		return yup.object().shape({ [activeItem.name]: activeItem.validationSchema });
	};

	const handleSubmit = async (values) => {
		let entity;
		let value;

		setIsSubmitting(true);

		if (Object.values(FEEDBACK.fields).includes(activeItem)) {
			entity = ENTITIES.ASSESSMENT_FEEDBACKS;
		} else if (Object.values(VALUE.fields).includes(activeItem)) {
			entity = ENTITIES.ASSESSMENT_VALUES;
		}

		if (activeItem.type === RADIO_BUTTON_LIST && activeItem.isBoolean) {
			// convert boolean (as string) to number
			value = (values[activeItem.name] === 'true') ? 1 : 0;
		} else {
			value = values[activeItem.name];
		}

		const obj = {
			[activeItem.name]: value,
		};

		// TODO: revisit
		switch (activeItem) {
			case VALUE.fields.WILL_SHARE_ON_SOCIAL_MEDIA: {
				// if WILL_SHARE_ON_SOCIAL_MEDIA value is 'no' / 0, WHICH_SOCIAL_MEDIA should be reset to empty array
				let socialMedia = [];
				if (value === 1) {
					socialMedia = values[VALUE.fields.WHICH_SOCIAL_MEDIA.name];
				}
				obj[VALUE.fields.WHICH_SOCIAL_MEDIA.name] = socialMedia;
				break;
			}
			case VALUE.fields.WOULD_PAY_MONEY: {
				// if WOULD_PAY_MONEY value is 'no' / 0, HOW_MUCH_MONEY should be reset to empty string
				let howMuchMoney = '';
				if (value === 1) {
					howMuchMoney = values[VALUE.fields.HOW_MUCH_MONEY.name];
				}
				obj[VALUE.fields.HOW_MUCH_MONEY.name] = howMuchMoney;
				break;
			}
			default: break;
		}

		// update corresponding entity record with answer
		await api.action(entity, ACTIONS.PARTIAL_UPDATE_BY_AUTH, obj);

		// update answered question as complete
		await api.updateCompletedSections({
			[appStates.POST_ASSESSMENT]: {
				completedQuestion: activeItem.name,
			},
		});

		// if last question move to Conclusion
		const index = postAssessmentItems.indexOf(activeItem);
		if (index < postAssessmentItems.length - 1) {
			setActiveItem(postAssessmentItems[index + 1]);
		} else {
			// update participant, marking section as complete
			const participantObj = { postAssessmentComplete: 1 };
			await api.action(ENTITIES.PARTICIPANTS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, participantObj);

			// update section as complete
			await api.updateCompletedSections({
				[appStates.POST_ASSESSMENT]: {
					complete: true,
				},
			});

			setAppState(appStates.CONCLUSION);
		}

		setIsSubmitting(false);
	};

	const handlePrevious = () => {
		setActiveItem(postAssessmentItems[postAssessmentItems.indexOf(activeItem) - 1]);
	};

	return (
		<Box p={5} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<Box>
				<Typography variant="h1">
					Post-Assessment
				</Typography>

				{activeItem && (
					<Box mt={3} mb={5}>
						<Typography variant="h6" gutterBottom style={{ textTransform: 'uppercase', fontWeight: '700' }}>
							Question {postAssessmentItems.indexOf(activeItem) + 1} / {postAssessmentItems.length}
						</Typography>
						<LinearProgress
							variant="determinate"
							value={normalize(postAssessmentItems.indexOf(activeItem) + 1, 1, postAssessmentItems.length)}
						/>
					</Box>
				)}
			</Box>

			{activeItem && (
				<Formik
					enableReinitialize={true}
					initialValues={getInitialValues()}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({
						handleBlur,
						handleChange,
						values,
						errors,
						touched,
					}) => {

						const renderActiveItem = () => {

							const item = activeItem;

							switch (activeItem) {
								case VALUE.fields.WILL_SHARE_ON_SOCIAL_MEDIA: {
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={values[item.name]}
												options={item.options}
												onBlur={handleBlur}
												onChange={handleChange}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>
											{/* (If "yes") */}
											{values[item.name] === 'true' && (
												<Fragment>
													<Box ml={2}>
														<CheckboxList
															label={VALUE.fields.WHICH_SOCIAL_MEDIA.label}
															name={VALUE.fields.WHICH_SOCIAL_MEDIA.name}
															values={values}
															options={VALUE.fields.WHICH_SOCIAL_MEDIA.options}
															onBlur={handleBlur}
															onChange={handleChange}
															touched={touched[VALUE.fields.WHICH_SOCIAL_MEDIA.name]}
															error={errors[VALUE.fields.WHICH_SOCIAL_MEDIA.name]}
														/>
													</Box>
												</Fragment>
											)}
										</Fragment>
									);
								}
								case VALUE.fields.WOULD_PAY_MONEY: {
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={values[item.name]}
												options={item.options}
												onBlur={handleBlur}
												onChange={handleChange}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>
											{/* (If "yes") */}
											{values[item.name] === 'true' && (
												<Fragment>
													<Box ml={2}>
														<RadioButtonList
															label={VALUE.fields.HOW_MUCH_MONEY.label}
															name={VALUE.fields.HOW_MUCH_MONEY.name}
															value={values[VALUE.fields.HOW_MUCH_MONEY.name]}
															options={VALUE.fields.HOW_MUCH_MONEY.options}
															onBlur={handleBlur}
															onChange={handleChange}
															touched={touched[VALUE.fields.HOW_MUCH_MONEY.name]}
															error={errors[VALUE.fields.HOW_MUCH_MONEY.name]}
														/>
													</Box>
												</Fragment>
											)}
										</Fragment>
									);
								}
								default: {
									switch (item.type) {
										case RADIO_BUTTON_LIST: {
											return (
												<RadioButtonList
													label={item.label}
													name={item.name}
													value={values[item.name]}
													options={item.options}
													onBlur={handleBlur}
													onChange={handleChange}
													touched={touched[item.name]}
													error={errors[item.name]}
												/>
											);
										}
										case CHECKBOX_LIST: {
											return (
												<CheckboxList
													label={item.label}
													name={item.name}
													value={values[item.name]}
													options={item.options}
													onBlur={handleBlur}
													onChange={handleChange}
													touched={touched[item.name]}
													error={errors[item.name]}
												/>
											);
										}
										default: {
											return null;
										}
									}
								}
							}
						};

						return (
							<Form noValidate style={{ height: '100%' }}>
								<Grid
									container
									spacing={3}
									alignItems="flex-start"
									justify="flex-start"
									alignContent="space-between"
									style={{ height: '100%' }}
								>
									<Grid item xs={12}>
										{renderActiveItem()}
									</Grid>
									<Grid item>
										<Button
											disabled={activeItem === postAssessmentItems[0] || isSubmitting}
											onClick={handlePrevious}
										>
											<ArrowBackIcon />
											Previous
											&nbsp;
										</Button>
									</Grid>
									<Grid item>
										<Button
											type="submit"
											disabled={isSubmitting}
										>
											&nbsp;
											{activeItem === postAssessmentItems[postAssessmentItems.length - 1] && 'Finish'}
											{activeItem !== postAssessmentItems[postAssessmentItems.length - 1] && 'Next'}
											&nbsp;
											<ArrowForwardIcon />
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			)}
		</Box>
	);
};

PostAssessment.propTypes = propTypes;

export default PostAssessment;
