import React, { Component, createRef, Fragment } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import GameOver from '@@Components/Tests/GameOver';
import ScoreBoard from '@@Components/Tests/ScoreBoard';
import StartButton from '@@Components/Tests/StartButton';
import TestLayout from '@@Components/Tests/TestLayout';
import { appStates, gameStates, playingStates } from '@@Constants';

import FinalBossGame from './FinalBossGame';


class FinalBoss extends Component {

	constructor(props) {
		super(props);
		this.setAppState = props.setAppState;
		this.activeTest = props.activeTest;
		this.handleNext = props.handleNext;

		this.gameBoardRef = createRef();

		this.state = {
			scoreBoard: {},
			gameState: gameStates.START,
			playingState: playingStates.DISPLAY,
		};

		this.startGame = this.startGame.bind(this);
		this.handleScoreBoardUpdate = this.handleScoreBoardUpdate.bind(this);
		this.handleGameStateUpdate = this.handleGameStateUpdate.bind(this);
		this.handlePlayingStateUpdate = this.handlePlayingStateUpdate.bind(this);
	}

	handleScoreBoardUpdate(scoreBoard) {
		this.setState({ scoreBoard });
	}

	handleGameStateUpdate(gameState) {
		this.setState({ gameState });
	}

	handlePlayingStateUpdate(playingState) {
		this.setState({ playingState });
	}

	startGame() {
		const gameBoard = this.gameBoardRef.current;
		// setGameState('Playing');
		// console.info('startGame');
		// setStartTime(new Date());

		// gameBoard.load();
		gameBoard.startRound();

		this.setState({ gameState: gameStates.PLAYING });
	}

	render() {
		let round;
		const { scoreBoard, gameState, playingState } = this.state;

		if (scoreBoard && scoreBoard.rounds) {
			round = scoreBoard.rounds[scoreBoard.rounds.length - 1];
		}

		return (
			<TestLayout
				title="Final Boss"
				instructions="Match the sequence…one mistake, it's over."
			>
				<Fragment>

					<FinalBossGame
						ref={this.gameBoardRef}
						gameState={this.state.gameState}
						playingState={this.state.playingState}
						onScoreBoardUpdate={this.handleScoreBoardUpdate}
						onGameStateUpdate={this.handleGameStateUpdate}
						onPlayingStateUpdate={this.handlePlayingStateUpdate}
						activeTest={this.activeTest}
					/>

					{(gameState !== gameStates.PLAYING) && (
						<Box
							position="absolute"
							top={0}
							left={0}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							width="100%"
							height="100%"
						>
							{gameState === gameStates.START && (
								<Fragment>
									<StartButton onClick={this.startGame} />
								</Fragment>
							)}

							{gameState === gameStates.END && (
								<GameOver>
									<Fragment>
										<Box my={10}>
											<Typography align="center">
												<Button variant="contained" onClick={() => this.handleNext()}>
													<ArrowForwardIcon />
												</Button>
											</Typography>
										</Box>
									</Fragment>
								</GameOver>
							)}
						</Box>
					)}
				</Fragment>
			</TestLayout>
		);
	}
}

export default FinalBoss;
