
import { playingStates } from '@@Constants';
import CircleBase from './CircleBase';


class ClickableCircleBase extends CircleBase {
	constructor(
		gameBoard,
		round,
		circleSettings,
		position,
	) {
		super(gameBoard, round, circleSettings, position);
		this.isSelected = false;
	}

	draw() {
		super.draw();
		const { playingState } = this.gameBoard.props;
		switch (playingState) {
			case playingStates.DISPLAY:
				this.drawDisplay();
				break;
			case playingStates.ANSWER:
				this.drawAnswers();
				break;
			default:
				break;
		}
	}

	drawDisplay() {
		throw new Error('No implementation for method drawDisplay.');
	}

	drawAnswers() {
		throw new Error('No implementation for method drawDisplay.');
	}

	checkClicked(clickPosition) {
		if (this.isInsideOf((clickPosition))) {
			this.handleClicked();
		}
	}

	handleClicked() {
		this.isSelected = !this.isSelected;
	}

	isCorrect() {
		throw new Error('No implementation for method drawDisplay.');
	}
}

export default ClickableCircleBase;
