import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';


const propTypes = {};

const Conclusion = () => {
	return (
		<Box p={5} mt={5} style={{ height: '100%' }}>
			<Grid container spacing={10} alignItems="center" justify="center" style={{ height: '100%' }}>
				<Grid item xs={12}>
					<Typography align="center" variant="h3" style={{ color: '#000', textShadow: '1px 1px 0 #ccc' }}>
						EA3: Esports Athlete Assessment
					</Typography>
					<Typography variant="h1" align="center" gutterBottom>
						Thank You for Your Participation!
					</Typography>
				</Grid>

				{/* <Grid item sm={5}>
					<Typography>
						You have completed the Esports Athlete Assessment
					</Typography>
				</Grid> */}
			</Grid>
		</Box>
	);
};

Conclusion.propTypes = propTypes;

export default Conclusion;
