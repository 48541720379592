import { useEffect, useState, useRef } from 'react';
import Bowser from 'bowser';


const elapsedTimeSeconds = (elapsedTime) => {
	return elapsedTime / 1000;
};

/* eslint-disable no-param-reassign */
const getRandomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max + 1);
	return Math.floor(Math.random() * (max - min) + min);
};
/* eslint-enable no-param-reassign */

const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};

const useStickyState = (defaultValue, key, unpersistedValues = []) => {
	const [value, setValue] = useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		return stickyValue !== null
			? JSON.parse(stickyValue)
			: defaultValue;
	});
	useEffect(() => {
		if (!unpersistedValues.includes(value)) {
			window.localStorage.setItem(key, JSON.stringify(value));
		}
	}, [key, value, unpersistedValues]);
	return [value, setValue];
};

const normalize = (value, min, max) => (value - min) * 100 / (max - min);

/* eslint-disable no-param-reassign */
const shuffleArray = (array = []) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};
/* eslint-enable no-param-reassign */


let isTouchOnlyDevice = false;

// https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only
if (window.matchMedia('(any-hover: none)').matches) {
	isTouchOnlyDevice = true;
}

const getTechnicalInfo = () => {
	return {
		userAgent: window.navigator.userAgent,
		logicalProcessors: window.navigator.hardwareConcurrency,
		...Bowser.parse(window.navigator.userAgent),
		dimensions: {
			screen: {
				width: window.screen.width,
				height: window.screen.height,
			},
			browser: {
				width: window.innerWidth,
				height: window.innerHeight,
			},
		},
		isTouchOnlyDevice,
	};
};


export {
	elapsedTimeSeconds,
	getRandomInt,
	getTechnicalInfo,
	normalize,
	shuffleArray,
	useInterval,
	useStickyState,
};
