import hash from 'object-hash';
import ArrowsRound from '@@Components/Tests/Arrows/ArrowsRound';
import { gameStates, playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class ArrowsGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 3;


		this.settings.roundSettings = {
			arrowSetSettings: {
				arrowLineSettings: {
					textColor: '#808080',
					font: '60px Arial',
					patternLength: 9, // needs to be odd
					leftArrow: '<',
					rightArrow: '>',
					offset: {
						x: 0,
						y: -30,
					},
				},
				numberOfPatterns: 8,
				maxAttempts: 3,
				patternDisplayLength: 2000,
				breakLength: 2000,
				breakText: '+',
				timeBetweenRounds: 2000,
			},
			countDownLength: 10000,
			countDownFont: '30px Arial',
			countDownColor: '#000000',
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				enemiesDestroyed: 0,
				alliesDestroyed: 0,
				numberOfClicks: 0,
				alliesSaved: 0,
				enemiesInvaded: 0,
				totalEnemies: 0,
				totalAllies: 0,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.settings.roundSettings.arrowSetSettings.position = this.canvasCenter;
	}

	createNewRound() {
		return new ArrowsRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		// don't need to handle clicks
	}

	handleMouseMove(event) {
		// don't need to handle mouse movements
	}

	handleKeyPress(event) {
		// don't need to handle key presses
	}

	checkState() {
		super.checkState();
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (this.props.playingState === playingStates.COUNT_DOWN && new Date() - round.countDown
			>= round.settings.countDownLength) {
			this.setAnswerState();
		}
	}

	async startRound() {
		await super.startRound();
		this.props.onPlayingStateUpdate(playingStates.COUNT_DOWN);
	}

	setAnswerState() {
		this.props.onPlayingStateUpdate(playingStates.ANSWER);
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].startAnswerState();
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.draw();
	}

	leftAnswer() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.leftAnswer();
	}

	rightAnswer() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.rightAnswer();
	}

	numberOfAttempts() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.arrowSet.numberOfAttempts();
		});

		return output;
	}

	numberCongruentCorrect() {
		let correct = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			correct += round.arrowSet.numberCongruentCorrect();
		});

		return correct;
	}

	numberCongruentIncorrect() {
		return this.numberOfAttempts() - this.numberCongruentCorrect();
	}

	numberIncongruentCorrect() {
		let correct = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			correct += round.arrowSet.numberIncongruentCorrect();
		});

		return correct;
	}

	numberIncongruentIncorrect() {
		return this.numberOfAttempts() - this.numberIncongruentCorrect();
	}

	summedCongruentReactionTime() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.arrowSet.summedCongruentReactionTime();
		});

		return output;
	}

	averageCongruentReactionTime() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedCongruentReactionTime() / numberOfAttempts).toFixed(4));
	}

	summedIncongruentReactionTime() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.arrowSet.summedIncongruentReactionTime();
		});

		return output;
	}

	averageIncongruentReactionTime() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedIncongruentReactionTime() / numberOfAttempts).toFixed(4));
	}

	totalElapsedTime() {
		let summedTime = 0;

		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_ARROWS_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.startTime,
				round_details: {
					hash: hash(this.settings),
				},
				congruent_reaction_time_total: round.arrowSet.summedCongruentReactionTime(),
				congruent_reaction_time_avg: round.arrowSet.averageCongruentReactionTime(),
				incongruent_reaction_time_total: round.arrowSet.summedIncongruentReactionTime(),
				incongruent_reaction_time_avg: round.arrowSet.averageIncongruentReactionTime(),
				congruent_correct_choices_count: round.arrowSet.numberCongruentCorrect(),
				congruent_incorrect_choices_count: round.arrowSet.numberCongruentIncorrect(),
				incongruent_correct_choices_count: round.arrowSet.numberIncongruentCorrect(),
				incongruent_incorrect_choices_count: round.arrowSet.numberIncongruentIncorrect(),
			});
		});
	}

}

export default ArrowsGame;
