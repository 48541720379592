
import { playingStates } from '@@Constants';


class AbstractGamePiece {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.settings = squareSettings;
		this.position = position;
		this.isHovered = false;
	}

	draw(isDisplayed = false) {
		throw new Error('No implementation for method draw.');
	}

	fill(color) {
		throw new Error('No implementation for method fill.');
	}

	isInsideOf(clickPosition) {
		throw new Error('No implementation for method isInsideOf.');
	}

	addHover() {
		throw new Error('No implementation for method addHover.');
	}

	removeHover() {
		throw new Error('No implementation for method removeHover.');
	}
}

export default AbstractGamePiece;
