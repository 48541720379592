
import SquareBase from '../SquareBase';


class Square extends SquareBase {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
	) {
		super(gameBoard, round, squareSettings, position);
	}
}

export default Square;
