const {
	common,
	blue,
	green,
	yellow,
	red,
	grey,
} = require('@material-ui/core/colors');


const primary = {
	light: '#a0cff1',
	main: '#429fe3',
	dark: '#215072',
	contrastText: '#fff',
};

const secondary = {
	light: grey['800'],
	main: grey['500'],
	dark: grey['200'],
	contrastText: '#fff',
};


const error = red;
const warning = yellow;
const info = blue;
const success = green;
const { black, white } = common;


module.exports = {
	primary,
	secondary,
	error,
	warning,
	info,
	success,
	black,
	white,
	grey,
};
