import { transparentize } from 'polished';

import colors from './colors';


const overrides = {
	MuiAlert: {
		message: {
			textAlign: 'left',
		},
		action: {
			alignItems: 'flex-start',
			marginTop: '0.5em',
		},
	},
	MuiInputBase: {
		input: {
			'&:disabled': {
				backgroundColor: colors.grey['200'],
				color: colors.grey['700'],
			},
		},
	},
	MuiButton: {
		root: {
			fontSize: '1.25em',
			fontWeight: '900',
			textTransform: 'uppercase',
			borderRadius: 0,
			borderWidth: 3,
			borderStyle: 'solid',
		},
		containedPrimary: {
			// backgroundColor: colors.primary.main,
			backgroundColor: colors.grey['300'],
			color: colors.black,
			borderColor: colors.black,
			'&:hover': {
				backgroundColor: colors.primary.main,
			},
			'&:disabled': {
				borderColor: colors.grey['200'],
			},
		},
		containedSecondary: {
			backgroundColor: colors.white,
			color: colors.primary.main,
			'&:hover': {
				backgroundColor: colors.primary.light,
			},
		},
		outlinedPrimary: {
			borderColor: transparentize(0.85, colors.primary.main),
		},
	},
	MuiContainer: {
		root: {
			maxWidth: 1600,
		},
	},
	MuiDrawer: {
		paperAnchorDockedLeft: {
			borderRight: 0,
		},
	},
	MuiExpansionPanelDetails: {
		root: {
			padding: '1em 2em',
			overflowX: 'auto',
		},
	},
	MuiFilledInput: {
		root: {
			backgroundColor: colors.grey['100'],
		},
		underline: {
			'&:before': {
				borderBottomWidth: 1,
				borderBottomColor: transparentize(0.85, colors.black),
			},
		},
	},
	MuiFormControl: {
		root: {
			marginBottom: '0.5em',
		},
	},
	MuiFormControlLabel: {
		label: {
			lineHeight: '1em',
		},
	},
	MuiFormGroup: {
		root: {
			marginBottom: '1em',
			maxHeight: 500,
		},
	},
	MuiFormLabel: {
		root: {
			color: 'inherit',
		},
		asterisk: {
			color: colors.error['600'],
			fontWeight: '700',
		},
	},
	// MuiIconButton: {
	// 	root: {
	// 		borderStyle: 'solid',
	// 		borderColor: colors.black,
	// 		borderWidth: 5,
	// 	},
	// },
	MuiTab: {
		root: {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: colors.grey['200'],
			margin: '0 0.25em',
		},
		textColorPrimary: {
			backgroundColor: colors.white,
		},
	},
	MuiTabPanel: {
		root: {
			padding: 0,
		},
	},
	MuiTableCell: {
		root: {
			verticalAlign: 'top',
			padding: '0.9em',
		},
		head: {
			fontWeight: '700',
			lineHeight: 1.2,
			verticalAlign: 'bottom',
		},
	},
	MuiTableSortLabel: {
		root: {
			lineHeight: 1.25,
		},
	},
	MuiTooltip: {
		tooltip: {
			backgroundColor: colors.grey['700'],
			fontSize: '1.05em',
		},
		arrow: {
			color: colors.grey['700'],
		},
	},
};

export default overrides;
