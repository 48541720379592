import React, { Component } from 'react';


class AbstractGameBoard extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		throw new Error('No implementation for method componentDidMount.');
	}

	handleCanvasClick(event) {
		throw new Error('No implementation for method handleCanvasClick.');
	}

	handleKeyPress(event) {
		throw new Error('No implementation for method handleKeyPress.');
	}

	handleMouseMove(event) {
		throw new Error('No implementation for method handleMouseMove.');
	}

	getMousePositionFromEvent(event) {
		throw new Error('No implementation for method getMousePositionFromEvent.');
	}

	setStartState() {
		throw new Error('No implementation for method setStartState.');
	}

	setEndState() {
		throw new Error('No implementation for method setEndState.');
	}

	async startRound() {
		throw new Error('No implementation for method startRound.');
	}

	isGameOver() {
		throw new Error('No implementation for method isGameOver.');
	}

	endRound() {
		throw new Error('No implementation for method endRound.');
	}

	calculateFrameRate() {
		throw new Error('No implementation for method calculateFrameRate.');
	}

	update() {
		throw new Error('No implementation for method update.');
	}

	clear() {
		throw new Error('No implementation for method clear.');
	}

	drawStartScreen() {
		throw new Error('No implementation for method drawStartScreen.');
	}

	checkState() {
		throw new Error('No implementation for method checkState.');
	}

	drawGameScreen() {
		throw new Error('No implementation for method drawGameScreen.');
	}

	drawHUD() {
		throw new Error('No implementation for method drawHUD.');
	}

	drawGameBoard() {
		throw new Error('No implementation for method drawGameBoard.');
	}

	draw() {
		throw new Error('No implementation for method draw.');
	}

}

export default AbstractGameBoard;
