import hash from 'object-hash';
import ClickBlitzRound from '@@Components/Tests/ClickBlitz/ClickBlitzRound';
import { gameStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class ClickBlitzGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 3;
		this.settings.roundSettings = {
			maxNPCs: 20,
			npcs: {
				enemy: {
					debug: 1,
					name: '+',
					text: '+',
					hitBox: { x: 22, y: 22 },
					textOffset: { x: 0, y: 2 },
					color: '#808080',
					font: '30px Overpass',
					rotation: 0,
				},
				ally: {
					debug: 1,
					name: 'x',
					text: '×',
					hitBox: { x: 22, y: 22 },
					textOffset: { x: 0, y: 1 },
					color: '#808080',
					font: '30px Overpass',
					rotation: 0,
				},
				speed: [
					0.5,
					0.75,
					1,
					1.25,
					1.4,
					1.5,
				],
				acceleration: 0.1,
				accelerationInterval: 20000,
				maxSpawnInterval: 1500,
				lastAccelerationTime: 0,
				lastSpawnTime: 0,
				nextSpawnInterval: 0,
			},
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				enemiesDestroyed: 0,
				alliesDestroyed: 0,
				numberOfClicks: 0,
				alliesSaved: 0,
				enemiesInvaded: 0,
				totalEnemies: 0,
				totalAllies: 0,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	createNewRound() {
		return new ClickBlitzRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const click = this.getMousePositionFromEvent(event);

		if (this.props.gameState === gameStates.PLAYING) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			round.numberOfClicks++;
			round.lastClick = click;
			round.lastClickTime = new Date();

			const currentSnapShot = round.npcs.slice();
			currentSnapShot.forEach((element) => {
				if (
					click.y > (element.position.y - element.settings.hitBox.y / 2)
					&& click.y < element.position.y + (element.settings.hitBox.y / 2)
					&& click.x > element.position.x - (element.settings.hitBox.x / 2)
					&& click.x < element.position.x + (element.settings.hitBox.y / 2)
				) {
					element.handleClicked();

					if (round.totalErrors() >= this.settings.scoreBoard.maxErrors) {
						this.endRound();
					}
				}
			});
		}
	}

	handleMouseMove(event) {
		// don't need to handle mouse movements
	}

	handleKeyPress(event) {
		// don't need to handle key presses
	}

	drawHUD() {
		// draw go
		this.ctx.beginPath();
		this.ctx.arc(this.go.position.x, this.go.position.y, this.go.radius, 0, Math.PI * 2);
		this.ctx.fillStyle = this.settings.theme.backgroundColor;
		this.ctx.fill();
		this.ctx.closePath();
		// draw text
		this.ctx.beginPath();
		this.ctx.fillStyle = this.settings.theme.textColor;
		this.ctx.font = this.settings.theme.largeFont;
		this.ctx.textAlign = 'center';
		this.ctx.textBaseline = 'middle';
		// this.ctx.fillText(this.go.text, this.go.position.x, this.go.position.y);
		this.ctx.closePath();

		if (this.settings.debug) {
			this.ctx.beginPath();
			this.ctx.fillStyle = this.settings.theme.backgroundColor;
			this.ctx.font = this.settings.theme.largeFont;
			this.ctx.textAlign = 'center';
			this.ctx.textBaseline = 'middle';
			this.ctx.fillText(this.currentFrameRate, 100, 100);
			this.ctx.closePath();
			// this.scoreBoard.rounds[this.scoreBoard.rounds.length - 1].drawSpawn();
		}

		super.drawHUD();
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		const lastClick = round.lastClick;
		if (lastClick !== null && (new Date() - round.lastClickTime) < round.lastClickDisplayLength) {
			this.ctx.beginPath();
			this.ctx.arc(lastClick.x, lastClick.y, 2, 0, Math.PI * 2);
			this.ctx.fillStyle = '#808080';
			this.ctx.fill();
			this.ctx.closePath();
		}

		// eslint-disable-next-line
		if ((round.elapsedTime() - round.settings.npcs.lastAccelerationTime) > (round.npcs.accelerationInterval)){
			round.accelerate();
			round.settings.npcs.lastAccelerationTime = round.elapsedTime();
		}

		round.spawnNPCs();
		// update npc
		for (let i = 0; i < round.npcs.length; i++) {
			round.npcs[i].update();
		}
	}

	numberOfClicks() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.numberOfClicks;
		});

		return output;
	}

	enemiesDestroyed() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.enemiesDestroyed;
		});

		return output;
	}

	alliesDestroyed() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.alliesDestroyed;
		});

		return output;
	}

	targetMissedCount() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.targetMissedCount();
		});

		return output;
	}

	enemiesInvaded() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.enemiesInvaded;
		});

		return output;
	}

	totalElapsedTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_CLICK_BLITZ_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					hash: hash(this.settings),
				},
				total_clicks: round.numberOfClicks,
				target_hit_count: round.enemiesDestroyed,
				target_invalid_count: round.alliesDestroyed,
				target_missed_count: round.targetMissedCount(),
				target_escaped_count: round.enemiesInvaded,
			});
		});
	}


}

export default ClickBlitzGame;
