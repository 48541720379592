import React, { Fragment, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from '@material-ui/core';

import RadioButtonList from '@@Components/RadioButtonList';
import { appStates } from '@@Constants/appStates';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


const propTypes = {
	setAppState: PropTypes.func,
};

const ParticipantInformation = ({
	setAppState,
}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	const getInitialValues = () => {
		return {
			firstName: '',
			lastName: '',
			email: '',
			isRanked: null,
			onOrganizedTeam: null,
			organizedPlayerTeam: null,
			organizedOtherTeam: '',
			rankedPlayerId: '',
		};
	};

	const validationSchema = () => {
		const schema = {
			firstName: yup.string()
				.required('Please enter your first name.'),
			lastName: yup.string()
				.required('Please enter your last name.'),
			email: yup.string().email()
				.required('Please enter your email address.'),
			organizedOtherTeam: yup.string()
				.when('organizedPlayerTeam', {
					is: 'other',
					then: yup.string().required('Please enter your organization / team name.'),
				}),
		};

		return yup.object().shape(schema);
	};

	const handleSubmit = async (values) => {

		setIsSubmitting(true);

		const participantObj = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			isRanked: Number(values.isRanked === 'yes'),
			rankedPlayerId: values.rankedPlayerId,
			onOrganizedTeam: Number(values.onOrganizedTeam === 'yes'),
			organizedTeam: values.organizedPlayerTeam === 'other'
				? values.organizedOtherTeam
				: values.organizedPlayerTeam,
		};

		console.info('[ParticipantInformation]', participantObj);

		// update participant with values/answers
		await api.action(ENTITIES.PARTICIPANTS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, participantObj);

		// update section as complete
		await api.updateCompletedSections({
			[appStates.PARTICIPANT_INFORMATION]: {
				complete: true,
			},
		});

		setAppState(appStates.PRE_ASSESSMENT);
	};

	return (
		<Box p={5}>

			<Typography variant="h1">
				Participant Information
			</Typography>

			<Box my={3}>
				<Divider />
			</Box>

			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({
					handleChange,
					handleBlur,
					values,
					errors,
					setFieldValue,
					touched,
				}) => (
					<Form noValidate>

						<Grid container spacing={3}>

							<Grid item xs={12} sm={3}>
								{/* 1 */}
								<TextField
									id="first-name"
									name="firstName"
									value={values.firstName}
									onChange={handleChange}
									onBlur={handleBlur}
									label="What is your first name?"
									helperText={touched.firstName ? errors.firstName : ''}
									error={errors.firstName && touched.firstName}
									variant="filled"
									fullWidth
									required
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								{/* 2 */}
								<TextField
									id="last-name"
									name="lastName"
									value={values.lastName}
									onChange={handleChange}
									onBlur={handleBlur}
									label="What is your last name?"
									helperText={touched.lastName ? errors.lastName : ''}
									error={errors.lastName && touched.lastName}
									variant="filled"
									fullWidth
									required
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								{/* 2 */}
								<TextField
									id="email"
									name="email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									label="What is your email address?"
									helperText={touched.email ? errors.email : ''}
									error={errors.email && touched.email}
									variant="filled"
									fullWidth
									required
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
						</Grid>
						<Box my={5}>
							<Grid container spacing={10}>
								<Grid item xs={4}>
									{/* 3 */}
									<FormControl component="fieldset" style={{ width: '100%' }}>
										<RadioButtonList
											label="Do you have an eFuse Portfolio?"
											name="isRanked"
											value={values.isRanked}
											options={[
												{ label: 'Yes', value: 'yes' },
												{ label: 'No', value: 'no' },
											]}
											onChange={(e) => {
												const { value } = e.target;

												setFieldValue('isRanked', value);

												if (value === 'no') {
													setFieldValue('rankedPlayerId', '');
												}
											}}
										/>

										{/* (If "yes") */}
										{values.isRanked === 'yes' && (
											<Fragment>
												<Typography component="div" gutterBottom>
													Please enter your Efuse portfolio name
													following the standard eFuse Portfolio URL.
												</Typography>
												<TextField
													focused={values.isRanked === 'yes'}
													id="ranked-player-id"
													name="rankedPlayerId"
													value={values.rankedPlayerId}
													onChange={handleChange}
													onBlur={handleBlur}
													label="eFuse Portfolio URL"
													variant="filled"
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">efuse.gg/u/</InputAdornment>
														),
													}}
													/* eslint-disable-next-line */
													inputProps={{ maxLength: 45 }}
												/>
											</Fragment>
										)}
									</FormControl>
								</Grid>

								<Grid item xs={4}>
									{/* 4 */}
									<FormControl component="fieldset" style={{ width: '100%' }}>
										<RadioButtonList
											label="Are you a member of an organized esports team?"
											name="onOrganizedTeam"
											value={values.onOrganizedTeam}
											options={[
												{ label: 'Yes', value: 'yes' },
												{ label: 'No', value: 'no' },
											]}
											onChange={(e) => {
												const { value } = e.target;
												setFieldValue('onOrganizedTeam', value);

												if (value === 'yes') {
													setFieldValue('organizedPlayerTeam', null);
													setFieldValue('organizedOtherTeam', '');
												}
											}}
										/>
									</FormControl>
								</Grid>

								{/* (If "yes") */}
								{values.onOrganizedTeam === 'yes' && (
									<Grid item xs={4}>
										<FormControl component="fieldset" style={{ width: '100%' }}>
											<RadioButtonList
												label="What team?"
												name="organizedPlayerTeam"
												value={values.organizedPlayerTeam}
												options={[
													'Cleveland Cavaliers Legion GC',
													'Ohio University Esports',
													'Spire Academy Esports',
													'University of Akron Esports',
													{ label: 'Other', value: 'other' },
												]}
												onChange={(e) => {
													const { value } = e.target;

													if (value !== 'other') {
														setFieldValue('organizedOtherTeam', '');
													}

													setFieldValue('organizedPlayerTeam', value);
												}}
											/>

											<TextField
												focused={values.organizedPlayerTeam === 'other'}
												id="organized-other-team"
												name="organizedOtherTeam"
												value={values.organizedOtherTeam}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Organization / Team Name"
												helperText={touched.organizedOtherTeam ? errors.organizedOtherTeam : ''}
												error={errors.organizedOtherTeam && touched.organizedOtherTeam}
												variant="filled"
												fullWidth
												required
												InputLabelProps={{ shrink: true }}
												disabled={values.organizedPlayerTeam !== 'other'}
											/>

										</FormControl>
									</Grid>
								)}

							</Grid>
						</Box>

						<Box my={3}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
							>
								Submit
							</Button>
						</Box>

					</Form>
				)}
			</Formik>
		</Box>
	);
};

ParticipantInformation.propTypes = propTypes;

export default ParticipantInformation;
