import styled from 'styled-components';

import { Box, Container } from '@material-ui/core';

import { colors } from '@@Theme';


/**
 * Notes: Fixed viewport size for games
 * https://www.browserstack.com/guide/ideal-screen-sizes-for-responsive-design
 * As of Jan 2021, smallest popular desktop screen appears to be 1366x768
 */

const StyledContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	align-items: center;
`;

const Wrapper = styled(Box)`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin: 2em;
	min-height: 75vh;
	background-color: ${colors.white};
	width: 100%;
	border: 10px solid black;
	// background-color: white;
	// color: black;
	// clip-path: polygon( 0 10%,7.5% 0,100% 0,100% 0,100% 90%,92.5% 100%,0% 100%,0% 100%,0% 0% );
`;

export {
	StyledContainer as Container,
	Wrapper,
};
