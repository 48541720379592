import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';


const propTypes = {
	children: PropTypes.element.isRequired,
	heading: PropTypes.string,
};

const GameOver = ({
	heading = 'Game Over',
	children,
}) => {
	return (
		<Box>
			<Typography variant="h1">
				{heading}
			</Typography>
			<Box my={4}>
				{children}
			</Box>
		</Box>
	);
};

GameOver.propTypes = propTypes;

export default GameOver;
