
import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Npc from './Npc';


class PinpointRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.startTime = new Date();
		this.endTime = null;
		this.lastClick = null;
		this.lastClickTime = null;
		this.lastClickDisplayLength = 1000;
		this.npcs = [];
	}

	generateRandomClickPoint() {
		let newPosition;
		do {
			newPosition = {
				x: Math.floor(Math.random()
					* (this.gameBoard.canvas.width - this.settings.npcSettings.padding.x * 2))
					+ this.settings.npcSettings.padding.x,
				y: Math.floor(Math.random()
					* (this.gameBoard.canvas.height - this.settings.npcSettings.padding.y * 2))
					+ this.settings.npcSettings.padding.y,
			};
		} while ((Math.sqrt((newPosition.x
			- this.gameBoard.go.position.x) ** 2
			+ (newPosition.y - this.gameBoard.go.position.y) ** 2)
			< (this.gameBoard.go.radius + 30)));

		this.npcs.push(new Npc(this.gameBoard, this, this.settings.npcSettings, newPosition));
	}

	isRoundOver() {
		return this.npcs.length >= this.settings.npcSettings.maxTargets && this.npcs[this.npcs.length - 1].userClick !== null;
	}

	summedAccuracy() {
		const sum = {
			x: 0,
			y: 0,
		};

		if (this.npcs.length > 0) {
			this.npcs.forEach((npc) => {
				if (npc.userClick != null) {
					const currentAccuracy = npc.distanceOfUserClick();
					sum.x += currentAccuracy.x;
					sum.y += currentAccuracy.y;
				}
			});

		}

		return sum;
	}

	averageAccuracy() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		const summedAccuracy = this.summedAccuracy();

		return {
			x: parseFloat((summedAccuracy.x / numberOfAttempts).toFixed(4)),
			y: parseFloat((summedAccuracy.y / numberOfAttempts).toFixed(4)),
		};
	}

	summedReactionTime() {
		let summedTime = 0;
		this.npcs.forEach((npc) => {
			if (npc.userClickTime != null) {
				summedTime += npc.ReactionTimeOfUserClick();
			}
		});

		return summedTime;
	}

	averageReactionTime() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedReactionTime() / numberOfAttempts).toFixed(4));
	}

	numberOfAttempts() {
		let output = 0;
		this.npcs.forEach((npc) => {
			if (npc.userClick !== null) {
				output++;
			}
		});

		return output;
	}

	numberCorrect() {
		let output = 0;
		this.npcs.forEach((npc) => {
			if (npc.isCorrect()) {
				output++;
			}
		});

		return output;
	}

	percentCorrect() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.numberCorrect() / numberOfAttempts * 100).toFixed(4));
	}

	numberIncorrect() {
		let output = 0;
		this.npcs.forEach((npc) => {
			if (npc.isIncorrect()) {
				output++;
			}
		});

		return output;
	}

	percentIncorrect() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat(this.numberIncorrect() / numberOfAttempts * 100).toFixed(4);
	}

	detailedInfo() {
		const output = [];
		this.npcs.forEach((npc) => {
			output.push(npc.detailedInfo());
		});

		return output;
	}

	draw() {
		if (this.npcs.length > 0) {
			this.npcs[this.npcs.length - 1].draw();
		}
	}

	render() {
		return null;
	}
}

export default PinpointRound;
