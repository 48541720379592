import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '@material-ui/core';

import { colors } from '@@Theme';


const propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

const StartButton = ({
	label = 'Start',
	onClick,
}) => {
	return (
		<Button
			onClick={onClick}
			style={{
				color: 'white',
				backgroundColor: colors.primary.main,
				width: 150,
				height: 150,
				borderRadius: '50%',
				border: '10px solid black',
			}}
		>
			<Typography
				variant="h3"
				component="span"
			>
				{label}
			</Typography>
		</Button>
	);
};

StartButton.propTypes = propTypes;

export default StartButton;
