import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Trail from './Trail';


class AlphaBlitzRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.trail = new Trail(this.gameBoard, this, this.settings.trailSettings);
	}

	isRoundOver() {
		return this.trail.lastSelected === this.settings.trailSettings.trailLength.toString();
	}

	draw() {
		this.trail.draw();
	}

	checkClicked(clickPosition) {
		this.trail.checkClicked(clickPosition);
	}

	render() {
		return null;
	}
}

export default AlphaBlitzRound;
