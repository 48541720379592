import hash from 'object-hash';
import PinpointRound from '@@Components/Tests/Pinpoint/PinpointRound';
import { gameStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class PinpointGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.numberOfRounds = 3;
		this.settings.roundSettings = {
			npcSettings: {
				padding: {
					x: 20,
					y: 20,
				},
				text: '+',
				color: '#000000',
				font: '30px Arial',
				displayTime: 200,
				maxTargets: 10,
				maxTimeBeforeDisplay: 5000,
				minTimeBeforeDisplay: 1000,
				maxTimeToWaitForAnswer: 5000,
			},
			scoreBoard: {
				position: {
					x: 750,
					y: 25,
				},
				size: {
					x: 200,
					y: 500,
				},
				padding: {
					x: 10,
					y: 10,
				},
			},
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				numberOfClicks: 0,
			}),
		};
	}

	createNewRound() {
		return new PinpointRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		const click = this.getMousePositionFromEvent(event);
		round.lastClick = click;
		round.lastClickTime = new Date();

		if (this.props.gameState === gameStates.PLAYING) {
			if (!((Math.sqrt(click.x - this.go.position.x ** 2
				+ click.y - this.go.position.y ** 2)
			< (this.go.radius + 20))
			|| (click.x >= (this.settings.scoreBoard.position.x - 20)
				&& click.y <= this.settings.scoreBoard.position.y + this.settings.scoreBoard.size.y + 20))) {
				round.numberOfClicks++;
				const currentNpc = round.npcs[round.npcs.length - 1];
				currentNpc.userClick = click;
				currentNpc.userClickTime = new Date();
			}
		}
	}

	handleMouseMove(event) {
		// don't need to handle mouse movements
	}

	handleKeyPress(event) {
		// don't need to handle key presses
	}

	drawHUD() {
		const { ctx } = this;

		if (this.settings.debug) {
			ctx.beginPath();
			ctx.fillStyle = this.settings.theme.backgroundColor;
			ctx.font = this.settings.theme.largeFont;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(this.currentFrameRate, 100, 100);
			ctx.closePath();
			this.drawSpawn();
		}

		super.drawHUD();
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		const lastClick = round.lastClick;
		if (lastClick !== null && (new Date() - round.lastClickTime) < round.lastClickDisplayLength) {
			this.ctx.beginPath();
			this.ctx.arc(lastClick.x, lastClick.y, 2, 0, Math.PI * 2);
			this.ctx.fillStyle = '#808080';
			this.ctx.fill();
			this.ctx.closePath();
		}

		// round.spawnNPCs();
		for (let i = 0; i < round.npcs.length; i++) {
			round.npcs[i].draw();
		}
	}

	async startRound() {
		await super.startRound();
	}

	numberOfAttempts() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.numberOfAttempts();
		});

		return output;
	}

	numberCorrect() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.numberCorrect();
		});

		return output;
	}

	percentCorrect() {
		return parseFloat((this.numberCorrect() / this.numberOfAttempts() * 100).toFixed(4));
	}

	numberIncorrect() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.numberIncorrect();
		});

		return output;
	}

	percentIncorrect() {
		return parseFloat((this.numberIncorrect() / this.numberOfAttempts() * 100).toFixed(4));
	}

	summedAccuracy() {
		const sum = {
			x: 0,
			y: 0,
		};

		this.state.scoreBoard.rounds.forEach((round) => {
			const currentSummedAccuracy = round.summedAccuracy();
			sum.x += currentSummedAccuracy.x;
			sum.y += currentSummedAccuracy.y;
		});

		return sum;
	}

	summedReactionTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.summedReactionTime();
		});

		return summedTime;
	}

	averageAccuracy() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		const summedAccuracy = this.summedAccuracy();

		return {
			x: parseFloat((summedAccuracy.x / numberOfAttempts).toFixed(4)),
			y: parseFloat((summedAccuracy.y / numberOfAttempts).toFixed(4)),
		};
	}

	averageReactionTime() {
		let numberOfAttempts = this.numberOfAttempts();
		numberOfAttempts = numberOfAttempts === 0 ? 1 : numberOfAttempts;
		return parseFloat((this.summedReactionTime() / numberOfAttempts).toFixed(4));
	}

	totalElapsedTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_PINPOINT_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					attempts: round.detailedInfo(),
					average_reaction_time: round.averageReactionTime(),
					average_accuracy: round.averageAccuracy(),
					hash: hash(this.settings),
				},
				count_correct: round.numberCorrect(),
				count_incorrect: round.numberIncorrect(),
				percent_correct: round.percentCorrect(),
				percent_incorrect: round.percentIncorrect(),
			});
		});
	}

}

export default PinpointGame;
