import GameRoundBase from '@@Components/Tests/GameRoundBase';
import Grid from './Grid';


class NumberBlockRound extends GameRoundBase {
	constructor(props) {
		super(props);
		this.settings = props.settings;
		this.grid = new Grid(this.gameBoard, this, this.settings.gridSettings);
	}

	isRoundOver() {
		return this.endTime !== null;
	}

	totalErrors() {
		return this.enemiesInvaded + this.alliesDestroyed;
	}

	draw() {
		this.grid.draw();
	}

	checkClicked(clickPosition) {
		this.grid.checkClicked(clickPosition);
	}

	handleKeyPress(key) {
		this.grid.handleKeyPress(key);
	}

	render() {
		return null;
	}
}

export default NumberBlockRound;
