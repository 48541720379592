
import { playingStates } from '@@Constants';
import Square from './Square';
import GridBase from '../GridBase';


class Grid extends GridBase {
	constructor(
		gameBoard,
		round,
		gridSettings,
		position = null,
	) {
		super(gameBoard, round, gridSettings, position);
		if (!position) {
			this.position = this.settings.position;
		}


	}

	createGamePiece(position) {
		return new Square(
			this.gameBoard,
			this.round,
			this.settings.square,
			position,
		);
	}

	draw() {
		super.draw();
		const { ctx } = this.gameBoard;
		const { playingState } = this.gameBoard.props;
		if (playingState === playingStates.DISPLAY) {
			ctx.font = this.settings.timerFont;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillStyle = this.settings.timerColor;
			const timeLeft = Math.floor((this.settings.displayTime - this.round.elapsedTime()) / 1000);
			ctx.fillText(timeLeft, this.position.x + this.settings.timerOffset.x, this.position.y + this.settings.timerOffset.y);
		}
	}

	checkClicked(clickPosition) {
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.checkClicked(clickPosition);
		});
	}

	selectedSquare() {
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isSelected) {
				return gamePiece;
			}
		});

		return null;
	}

	selectedSquareIndex() {
		return this.gamePieces.findIndex((gamePiece) => gamePiece.isSelected);
	}

	handleKeyPress(key) {
		if (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'ArrowUp' || key === 'ArrowDown') {
			this.handleArrowKeys(key);
		} else {
			this.gamePieces.forEach((gamePiece) => {
				gamePiece.handleKeyPress(key);
			});
		}

	}

	handleArrowKeys(key) {
		let selectedIndex = this.selectedSquareIndex();
		let selectedRow = Math.floor(this.settings.numberOfSquares.x / 2);
		let selectedColumn = Math.floor(this.settings.numberOfSquares.y / 2);

		if (selectedIndex !== -1) {
			selectedRow = selectedIndex % this.settings.numberOfSquares.x;
			selectedColumn = Math.floor(selectedIndex / this.settings.numberOfSquares.y);
			switch (key) {
				case 'ArrowLeft':
					--selectedColumn;
					break;
				case 'ArrowRight':
					++selectedColumn;
					break;
				case 'ArrowUp':
					--selectedRow;
					break;
				case 'ArrowDown':
					++selectedRow;
					break;
				default:
					break;
			}

			if (selectedColumn < 0) {
				selectedColumn = this.settings.numberOfSquares.y - 1;
			} else if (selectedColumn >= this.settings.numberOfSquares.y) {
				selectedColumn = 0;
			}

			if (selectedRow < 0) {
				selectedRow = this.settings.numberOfSquares.x - 1;
			} else if (selectedRow >= this.settings.numberOfSquares.x) {
				selectedRow = 0;
			}
		}

		selectedIndex = selectedRow
			+ selectedColumn * this.settings.numberOfSquares.y;
		this.unSelectAllSquares();
		this.gamePieces[selectedIndex].selectSquare();

	}

	numberCorrect() {
		let totalCorrect = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isCorrect()) {
				++totalCorrect;
			}
		});

		return totalCorrect;
	}

	percentCorrect() {
		return parseFloat((this.numberCorrect() / this.numberOfPieces() * 100).toFixed(4));
	}

	numberIncorrect() {
		let totalIncorrect = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isInCorrect()) {
				++totalIncorrect;
			}
		});

		return totalIncorrect;
	}

	percentIncorrect() {
		return parseFloat((this.numberIncorrect() / this.numberOfPieces() * 100).toFixed(4));
	}

	numberBlank() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			if (gamePiece.isBlank()) {
				++output;
			}
		});

		return output;
	}

	percentBlank() {
		return parseFloat((this.numberBlank() / this.numberOfPieces() * 100).toFixed(4));
	}

	isGridComplete() {
		return this.endTime !== null;
	}

	unSelectAllSquares() {
		this.gamePieces.forEach((gamePiece) => {
			gamePiece.unSelectSquare();
		});
	}
}

export default Grid;
