import hash from 'object-hash';
import FinalBossRound from '@@Components/Tests/FinalBoss/FinalBossRound';
import { gameStates, playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class FinalBossGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.scoreBoard.maxErrors = 10;
		this.settings.numberOfRounds = 3;

		this.settings.roundSettings = {
			gridSettings: {
				square: {
					size: {
						x: 100,
						y: 100,
					},
					color: '#808080',
					answerColor: '#A1A1A1',
					hoverColor: '#D3D3D3',
					boarderColor: '#FFFFFF',
					boarderSize: 5,
				},
				numberOfSquares: {
					x: 3,
					y: 3,
				},
				font: '30px Arial',
				maxAttempts: 3,
				displayTime: 1000,
				answerDisplayTime: 200,
				displayGapTime: 200,
				position: {
					x: 0,
					y: 0,
				},
				timerOffset: {
					x: 125,
					y: -25,
				},
				timerFont: '30px Arial',
				timerColor: '#000000',
				answerTimeOut: 5000,
			},
		};

		this.settings.message = {
			displayDuration: 2000,
			color: '#000000',
			size: '30px Arial',
			offset: {
				x: 0,
				y: 50,
			},
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				message: '',
				messageStartTime: null,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	componentDidMount() {
		super.componentDidMount();
		this.settings.roundSettings.gridSettings.position.x = this.canvasCenter.x
			- (this.settings.roundSettings.gridSettings.numberOfSquares.x
				* this.settings.roundSettings.gridSettings.square.size.x / 2);
		this.settings.roundSettings.gridSettings.position.y = this.canvasCenter.y
			- (this.settings.roundSettings.gridSettings.numberOfSquares.y
				* this.settings.roundSettings.gridSettings.square.size.y / 2);
	}

	createNewRound() {
		return new FinalBossRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const click = this.getMousePositionFromEvent(event);

		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			round.checkClicked(click);
		}
	}

	setAnswerState() {
		this.props.onPlayingStateUpdate(playingStates.ANSWER);
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.startAnswerState();
		const scoreBoard = this.state.scoreBoard;
		scoreBoard.message = '';
		scoreBoard.messageStartTime = null;
		this.setState({ scoreBoard });
	}

	setMessageState(message) {
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.clearHover();
		this.props.onPlayingStateUpdate(playingStates.MESSAGE);
		const scoreBoard = this.state.scoreBoard;
		scoreBoard.message = message;
		scoreBoard.messageStartTime = new Date();
		this.setState({ scoreBoard });
	}

	setDisplayState() {
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.clearHover();
		this.props.onPlayingStateUpdate(playingStates.DISPLAY);
		this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.startDisplay();
		this.canvas.removeEventListener('mousemove', this.handleMouseMove);
		const scoreBoard = this.state.scoreBoard;
		scoreBoard.message = '';
		scoreBoard.messageStartTime = null;
		this.setState({ scoreBoard });
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_FINAL_BOSS_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					hash: hash(this.settings),
				},
				total_time: round.elapsedTime(),
				presented_value_total: round.grid.sequence.length,
			});
		});
	}

	handleKeyPress(event) {
		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
			round.handleKeyPress(event.key);
		}
	}

	handleMouseMove(event) {
		if (this.props.gameState === gameStates.PLAYING && this.props.playingState === playingStates.ANSWER) {
			const mousePosition = this.getMousePositionFromEvent(event);
			this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1].grid.checkHover(mousePosition);
		}
	}

	checkState() {
		super.checkState();
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		switch (this.props.playingState) {
			case playingStates.ANSWER:
				round.grid.checkState();
				if (round.grid.hasInputFinished()) {
					if (round.grid.isEnteredSequenceCorrect()) {
						this.setMessageState('Success!');
					} else if (round.grid.hasTimedOut()) {
						this.setMessageState('Timed Out');
					} else {
						this.setMessageState('Incorrect');
					}
				}
				break;
			case playingStates.DISPLAY: {
				const displayDuration = round.grid.displayDuration;
				const currentDisplayIndex = round.grid.currentDisplayIndex();
				if (currentDisplayIndex > round.grid.sequence.length - 1) {
					this.setAnswerState();
					this.displayStartTime = null;
				}
				break;
			}
			case playingStates.MESSAGE:
				if (new Date() - this.state.scoreBoard.messageStartTime > this.settings.message.displayDuration) {
					const scoreBoard = this.state.scoreBoard;
					scoreBoard.message = '';
					scoreBoard.messageStartTime = null;
					this.setState({ scoreBoard });
					if (round.grid.isEnteredSequenceCorrect()) {
						this.setDisplayState();
					}
				}
				break;
			default: break;
		}
	}

	async startRound() {
		await super.startRound();
		this.props.onPlayingStateUpdate(playingStates.DISPLAY);
		this.setDisplayState();
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.draw();
		this.drawMessage();
	}

	drawMessage() {
		if (this.props.playingState === playingStates.MESSAGE) {
			const { ctx, canvasCenter } = this;
			ctx.beginPath();
			ctx.fillStyle = this.settings.message.color;
			ctx.font = this.settings.message.font;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(this.state.scoreBoard.message,
				canvasCenter.x + this.settings.message.offset.x,
				canvasCenter.y
				- (this.settings.roundSettings.gridSettings.square.size.y
					* this.settings.roundSettings.gridSettings.numberOfSquares.y / 2)
				+ this.settings.message.offset.y);
			ctx.closePath();
		}
	}

	displayMessage() {
		return this.state.scoreBoard.messageStartTime != null;
	}

	averageSequenceLength() {
		let totalNumber = 0;
		let summed = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			totalNumber++;
			summed += round.grid.sequence.length;
		});

		return totalNumber === 0
			? 0
			: summed / totalNumber;
	}

	totalElapsedTime() {
		let summedTime = 0;

		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

}

export default FinalBossGame;
