
import { playingStates } from '@@Constants';
import Circle from './Circle';
import GameBoardStructureBase from '../GameBoardStructureBase';


class Trail extends GameBoardStructureBase {
	constructor(
		gameBoard,
		round,
		settings,
		position = null,
	) {
		super(gameBoard, round, settings, position);
		this.lastSelected = '0';
		this.clicks = 0;
		this.correctClicks = 0;
		for (let i = 0; i < this.settings.trailLength; i++) {
			this.gamePieces.push(this.createGamePiece(this.randomCirclePosition(), String.fromCharCode(i + 65)));
			this.gamePieces.push(this.createGamePiece(this.randomCirclePosition(), (i + 1).toString()));
		}
	}

	createGamePiece(position, value) {
		return new Circle(
			this.gameBoard,
			this.round,
			this.settings.circle,
			position,
			value,
		);
	}

	randomCirclePosition() {
		// this method could go to infinity if too many circles are added to too small of a canvas
		const { gameBoard } = this;
		const spacingFromEdge = this.settings.circleSpacing + this.settings.circle.radius;
		const newPosition = {
			x: Math.floor(Math.random() * (gameBoard.canvas.width - (spacingFromEdge * 2))) + spacingFromEdge,
			y: Math.floor(Math.random() * (gameBoard.canvas.height - (spacingFromEdge * 2))) + spacingFromEdge,
		};

		// const leftMaxX = gameBoard.settings.scoreBoard.position.x
		// 	- this.settings.circle.radius
		// 	- this.settings.circleSpacing;
		// const rightMinX = gameBoard.settings.scoreBoard.position.x
		// 	+ gameBoard.settings.scoreBoard.size.x
		// 	+ this.settings.circle.radius
		// 	+ this.settings.circleSpacing;
		// const upMaxY = gameBoard.settings.scoreBoard.position.y
		// 	- this.settings.circle.radius
		// 	- this.settings.circleSpacing;
		// const downMinY = gameBoard.settings.scoreBoard.position.y
		// 	+ gameBoard.settings.scoreBoard.size.x
		// 	+ this.settings.circle.radius
		// 	+ this.settings.circleSpacing;
		//
		// if (newPosition.x > leftMaxX && newPosition.x < rightMinX
		// 	&& newPosition.y > upMaxY && newPosition.y < downMinY) {
		// 	return this.randomCirclePosition();
		// }

		for (let i = 0; i < this.gamePieces.length; i++) {
			if (Math.sqrt(((newPosition.x - this.gamePieces[i].position.x) ** 2)
				+ ((newPosition.y - this.gamePieces[i].position.y) ** 2))
			<= (this.settings.circle.radius * 2) + this.settings.circleSpacing) {
				return this.randomCirclePosition();
			}
		}


		return newPosition;
	}

	checkClicked(clickPosition) {
		this.clicks++;
		this.gamePieces.forEach((gamePiece) => {
			const selectedValue = gamePiece.checkClicked(clickPosition, this.lastSelected);
			if (selectedValue) {
				this.correctClicks++;
				this.lastSelected = selectedValue;
			}
		});
	}

	numberOfIncorrectClicks() {
		let output = 0;
		this.gamePieces.forEach((gamePiece) => {
			output += gamePiece.IncorrectClicks;
		});

		return output;
	}

	totalClicks() {
		return this.correctClicks + this.numberOfIncorrectClicks();
	}

	percentOfIncorrectClicks() {
		const totalClicks = this.totalClicks();
		if (totalClicks <= 0) {
			return 0;
		}

		return parseFloat((this.numberOfIncorrectClicks() / totalClicks * 100).toFixed(4));
	}

	averageTimeBetweenEachCorrectClick() {
		let numberOfCircles = 0;
		let summedTime = 0;
		let lastTime = this.startTime;
		this.gamePieces.forEach((gamePiece) => {
			numberOfCircles++;
			summedTime += (gamePiece.clickedTime - lastTime);
			lastTime = gamePiece.clickedTime;
		});

		return summedTime / numberOfCircles / 1000;
	}


}

export default Trail;
