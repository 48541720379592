import styled from 'styled-components';

import { Box, withTheme } from '@material-ui/core';

import { gameContainer } from '@@Config';
import { colors } from '@@Theme';


/**
 * Notes: Fixed viewport size for games
 * https://www.browserstack.com/guide/ideal-screen-sizes-for-responsive-design
 * As of Jan 2021, smallest popular desktop screen appears to be 1366x768
 *
*/

const TestWrapper = withTheme(styled(Box)`
	position: relative;
	width: ${gameContainer.width}px;
	height: ${gameContainer.height}px;
	max-width: ${gameContainer.width}px;
	min-height: ${gameContainer.height}px;
	max-height: ${gameContainer.height}px;
	margin: ${({ theme }) => `0 auto ${theme.spacing(4)}px`};
	background-color: ${colors.grey['100']};
	outline: 1px solid ${colors.grey['400']};
`);

export {
	TestWrapper,
};
