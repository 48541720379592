import React from 'react';
import PropTypes from 'prop-types';

import {
	Box,
	Button,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';

import { appStates } from '@@Constants/appStates';


const propTypes = {
	setAppState: PropTypes.func,
};

const Welcome = ({
	setAppState,
}) => {
	return (
		<Box p={5} mt={5}>
			<Grid container spacing={10} alignItems="center" justify="center">

				<Grid item xs={12}>
					<Typography align="center" variant="h3" style={{ color: '#000', textShadow: '1px 1px 0 #ccc' }}>
						EA3: Esports Athlete Assessment
					</Typography>
					<Typography variant="h1" align="center" gutterBottom>
						Welcome!
					</Typography>
					<Typography align="center">
						Welcome to &quot;The Gauntlet&quot;. This is an assessment of your neurocognitive and
						psychomotor skills as an esports athlete. We are currently in the validation
						phase of &quot;The Gauntlet&quot; and you are among the first esports athletes to try this out.
						Your participation will help us refine the assessment and deliver a tool that will
						help esports athletes understand their unique &quot;synaptic skills&quot; and potentially lead
						us to improving these skills in the future. &quot;The Gauntlet&quot; is broken up into three sections.
						A pre-game questionnaire, 9 neurocognitive and psychomotor games, and a post-game questionnaire.
						Completion of all 9 games and both questionnaires are required to complete this assessment.
					</Typography>
				</Grid>

				<Grid item sm={5}>
					<Typography variant="h3" gutterBottom align="center">
						First Time
					</Typography>

					<Typography align="center">
						Please issue me a randomly assigned participant number so that I can start my assessment
					</Typography>

					<Box my={3}>
						<Typography align="center">
							<Button
								variant="contained"
								color="primary"
								onClick={() => setAppState(appStates.FIRST_TIME)}
							>
								Issue Access Code
							</Button>
						</Typography>
					</Box>
				</Grid>

				<Divider orientation="vertical" flexItem />

				<Grid item sm={5}>
					<Typography variant="h3" gutterBottom align="center">
						Returning
					</Typography>

					<Typography align="center">
						I was previously issued a code that I know and would like to use to continue my assessment
					</Typography>

					<Box my={3}>
						<Typography align="center">
							<Button
								variant="contained"
								color="primary"
								onClick={() => setAppState(appStates.LOGIN)}
							>
								&nbsp;Log In&nbsp;
							</Button>
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

Welcome.propTypes = propTypes;

export default Welcome;
