import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import * as yup from 'yup';

import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';


const propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	ariaLabel: PropTypes.string,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.objectOf(PropTypes.any),
		PropTypes.string,
	])).isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	error: PropTypes.string,
};


export const validationSchemas = {
	REQUIRED_OPTION_SELECTION: yup.string()
		.required('Please make a selection.')
		.nullable(),
	REQUIRED_YES_NO_SELECTION: yup.string()
		.required('Please select yes or no.')
		.nullable(),
};

const RadioButtonList = ({
	label,
	ariaLabel,
	name,
	options = [],
	value,
	onChange,
	onBlur,
	error,
}) => {
	return (
		<FormControl component="fieldset" error={!!error} style={{ display: 'block' }}>
			<FormLabel component="legend">
				<Typography variant="h4" component="div" gutterBottom>
					{label}
				</Typography>
			</FormLabel>

			<Box ml={2}>
				<RadioGroup
					aria-label={ariaLabel || label}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				>
					{options.map((item) => (
						<FormControlLabel
							key={uid(item)}
							value={item.value || item.label || item}
							control={<Radio color="primary" />}
							label={
								<Fragment>
									{item.helperText && (
										<Box my={1.5} />
									)}
									{item.label || item}
									{item.helperText && (
										<FormHelperText error={false}>
											{item.helperText}
										</FormHelperText>
									)}
								</Fragment>
							}
						/>
					))}
				</RadioGroup>
			</Box>

			{error && (
				<FormHelperText>{error}</FormHelperText>
			)}
		</FormControl>
	);
};

RadioButtonList.propTypes = propTypes;

export default RadioButtonList;
