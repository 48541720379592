import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button } from '@material-ui/core';

import { colors } from '@@Theme';


const propTypes = {
	label: PropTypes.string,
	top: PropTypes.number.isRequired,
	center: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
};

const TextButton = ({
	label = 'Finish',
	top,
	center,
	onClick,
	width = 200,
	height = 50,
	disabled = false,
}) => {
	return (
		<Box my={5}>
			<Button
				variant="contained"
				onClick={onClick}
				disabled={disabled}
				style={{
					color: 'white',
					backgroundColor: disabled ? colors.grey['300'] : colors.primary.main,
					width,
					height,
					border: '3px solid black',
					position: 'absolute',
					top: `${top}px`,
					left: `${center - width / 2}px`,
				}}
			>
				<Typography
					variant="h3"
					component="span"
				>
					{label}
				</Typography>
			</Button>
		</Box>
	);
};

TextButton.propTypes = propTypes;

export default TextButton;
