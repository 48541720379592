import React, { Component, createRef, Fragment } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CircleButton from '@@Components/Tests/CircleButton';
import GameOver from '@@Components/Tests/GameOver';
import ScoreBoard from '@@Components/Tests/ScoreBoard';
import StartButton from '@@Components/Tests/StartButton';
import TestLayout from '@@Components/Tests/TestLayout';
import { appStates, gameStates, playingStates } from '@@Constants';

import PinpointGame from './PinpointGame';


class Pinpoint extends Component {

	constructor(props) {
		super(props);
		this.setAppState = props.setAppState;
		this.activeTest = props.activeTest;
		this.handleNext = props.handleNext;

		this.gameBoardRef = createRef();

		this.state = {
			scoreBoard: {},
			gameState: gameStates.START,
			playingState: playingStates.NONE,
		};

		this.startGame = this.startGame.bind(this);
		this.handleGameClick = this.handleGameClick.bind(this);
		this.handleScoreBoardUpdate = this.handleScoreBoardUpdate.bind(this);
		this.handleGameStateUpdate = this.handleGameStateUpdate.bind(this);
		this.handlePlayingStateUpdate = this.handlePlayingStateUpdate.bind(this);
	}

	handleScoreBoardUpdate(scoreBoard) {
		this.setState({ scoreBoard });
	}

	handleGameStateUpdate(gameState) {
		this.setState({ gameState });
	}

	handlePlayingStateUpdate(playingState) {
		this.setState({ playingState });
	}

	handleGameClick() {

		const round = this.gameBoardRef.current.state.scoreBoard
			.rounds[this.gameBoardRef.current.state.scoreBoard.rounds.length - 1];
		if (round.npcs.length <= 0 || round.npcs[round.npcs.length - 1].userClick !== null) {
			round.generateRandomClickPoint();
		}

	}

	startGame() {
		const gameBoard = this.gameBoardRef.current;
		// setGameState('Playing');
		// setStartTime(new Date());

		// gameBoard.load();
		gameBoard.startRound();
		this.setState({ gameState: gameStates.PLAYING });
	}

	render() {
		let round;
		let canGenerateNewClick = true;
		const { scoreBoard, gameState, playingState } = this.state;

		if (scoreBoard && scoreBoard.rounds) {
			round = scoreBoard.rounds[scoreBoard.rounds.length - 1];
			if (round !== undefined && round.npcs.length > 0 && round.npcs[round.npcs.length - 1].userClick === null) {
				canGenerateNewClick = false;
			}
		}

		return (
			<TestLayout
				title="Pinpoint"
				instructions={
					<Fragment>
						When you click &quot;PRESS&quot; you will see a + briefly flash on the screen.
						Click the spot where you think the + flashed.
					</Fragment>
				}
			>
				<Fragment>

					<PinpointGame
						ref={this.gameBoardRef}
						gameState={this.state.gameState}
						playingState={this.state.playingState}
						onScoreBoardUpdate={this.handleScoreBoardUpdate}
						onGameStateUpdate={this.handleGameStateUpdate}
						onPlayingStateUpdate={this.handlePlayingStateUpdate}
						activeTest={this.activeTest}
					/>

					{gameState === gameStates.PLAYING && round && (
						<Fragment>
							<CircleButton
								onClick={this.handleGameClick}
								x={this.gameBoardRef.current.canvasCenter.x}
								y={this.gameBoardRef.current.canvasCenter.y}
								disabled={!canGenerateNewClick}
							/>
						</Fragment>
					)}

					{(gameState !== gameStates.PLAYING) && (
						<Box
							position="absolute"
							top={0}
							left={0}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							width="100%"
							height="100%"
						>
							{gameState === gameStates.START && (
								<Fragment>
									<StartButton onClick={this.startGame} />
								</Fragment>
							)}

							{gameState === gameStates.END && (
								<GameOver>
									<Fragment>
										<Box my={10}>
											<Typography align="center">
												<Button variant="contained" onClick={() => this.handleNext()}>
													<ArrowForwardIcon />
												</Button>
											</Typography>
										</Box>
									</Fragment>

								</GameOver>
							)}
						</Box>
					)}
				</Fragment>
			</TestLayout>
		);
	}
}

export default Pinpoint;
