import { camelizeKeys, decamelizeKeys } from 'humps';


const { coreapi, schema, localStorage } = window;
let client = new coreapi.Client();

// https://ea3-esports-api.avantia-projects.com/docs/

const ENTITIES = {
	ASSESSMENT_FEEDBACKS: 'assessment-feedbacks',
	ASSESSMENT_VALUES: 'assessment-values',
	COMPETITION_LEVELS: 'competition-levels',
	COMPLETED_SECTIONS: 'completed-sections',
	DEMOGRAPHICS: 'demographics',
	GAME_ALPHABLITZ_RESULTS: 'game-alphablitz-results',
	GAME_ARROWS_RESULTS: 'game-arrows-results',
	GAME_CLICK_BLITZ_RESULTS: 'game-click-blitz-results',
	GAME_FINAL_BOSS_RESULTS: 'game-final-boss-results',
	GAME_GHOST_BLOCKS_RESULTS: 'game-ghost-blocks-results',
	GAME_NUMBER_BLOCK_RESULTS: 'game-number-block-results',
	GAME_PINPOINT_RESULTS: 'game-pinpoint-results',
	GAME_QUICK_DRAW_RESULTS: 'game-quick-draw-results',
	GAME_STAX_RESULTS: 'game-stax-results',
	PARTICIPANTS: 'participants',
	SESSIONS: 'sessions',
	TRAINING_DETAILS: 'training-details',

};
const ACTIONS = {
	LIST: 'list',
	CREATE: 'create',
	PARTIAL_UPDATE_BY_AUTH: 'partial_update_by_auth',
	UPDATE_BY_AUTH: 'update_by_auth',
	READ: 'read',
	UPDATE: 'update',
	PARTIAL_UPDATE: 'partial_update',
	// SESSIONS specific
	GET_BY_CODE: 'get_by_code',
	NEW_PARTICIPANT: 'new_participant',
	// COMPLETED_SECTIONS specific
	CURRENT: 'current',
};

/* Object.entries(entities).forEach(([entityKey, entityValue]) => {
	API_ACTIONS[entityKey] = {};
	Object.entries(actions).forEach(([actionKey, actionValue]) => {
		API_ACTIONS[entityKey][actionKey] = [entityValue, actionValue];
	});
}); */

const api = {
	action: async (entity, action, params) => {

		console.info(`[api] ${entity} ${action}`, params);

		let token;

		if (
			entity === ENTITIES.PARTICIPANTS
			&& (action === ACTIONS.NEW_PARTICIPANT || action === ACTIONS.GET_BY_CODE)
		) {
			const result = await client.action(schema, [entity, action], decamelizeKeys(params));
			token = result.key;
			localStorage.setItem('token', token);
		} else {
			token = localStorage.getItem('token');
		}

		const auth = new coreapi.auth.TokenAuthentication({
			scheme: 'Token',
			token,
		});

		client = new coreapi.Client({ auth });

		if (params) {
			return client.action(schema, [entity, action], decamelizeKeys(params));
		}

		return client.action(schema, [entity, action]);
	},
	getCompletedSections: async () => {
		const response = await api.action(ENTITIES.COMPLETED_SECTIONS, ACTIONS.CURRENT);
		const { completedSections } = camelizeKeys(response);
		return JSON.parse(completedSections);
	},
	updateCompletedSections: async (updates) => {
		const response = await api.action(ENTITIES.COMPLETED_SECTIONS, ACTIONS.CURRENT);
		let { completedSections } = camelizeKeys(response);
		completedSections = JSON.parse(completedSections);
		completedSections = JSON.stringify({
			...completedSections,
			...updates,
		});

		return api.action(ENTITIES.COMPLETED_SECTIONS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, { completedSections });
	},
};

export default api;

export {
	ENTITIES,
	ACTIONS,
};

// example: api.action(ENTITIES.SESSIONS, ACTIONS.LIST, {});
