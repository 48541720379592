const api = {
	baseUrl: process.env.API_BASE_URL,
};

const debug = {
	output: process.env.DEBUG_OUTPUT === 'true',
};

const userCode = {
	length: 8,
};

const gameContainer = {
	width: 960,
	height: 640,
};

const minimumRequirements = {
	dimensions: {
		browser: {
			width: gameContainer.width + 240,
			height: gameContainer.height,
		},
	},
};

module.exports = {
	api,
	debug,
	gameContainer,
	minimumRequirements,
	userCode,
};
