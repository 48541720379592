import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {
	Box,
	Button,
	FormLabel,
	FormHelperText,
	InputAdornment,
	Grid,
	LinearProgress,
	TextField,
	Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RadioButtonList, { validationSchemas as RadioButtonListValidationSchemas } from '@@Components/RadioButtonList';
import CheckboxList, { validationSchemas as CheckboxListValidationSchemas } from '@@Components/CheckboxList';
import { appStates, formItemTypes } from '@@Constants';
import { normalize } from '@@Utils';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


const { RADIO_BUTTON_LIST, CHECKBOX_LIST, TEXTAREA } = formItemTypes;

const preAssessment = {
	DEMOGRAPHICS: {
		title: 'Demographics',
		fields: {
			AGE: {
				name: 'age',
				label: 'What is your age?',
				initialValue: '',
				validationSchema: yup.number()
					.typeError('Please enter a valid age.')
					.min(5, 'Please enter a valid age.')
					.max(80, 'Please enter a valid age.'),
			},
			SEX: {
				name: 'sex',
				label: 'What is your sex?',
				type: RADIO_BUTTON_LIST,
				options: [
					'Woman',
					'Man',
					'Intersex',
					'Prefer not to answer',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GENDER: {
				name: 'gender',
				label: 'To what gender do you identify?',
				type: RADIO_BUTTON_LIST,
				options: [
					'Female',
					'Male',
					'Transgender Female',
					'Transgender Male',
					'Gender Variant / Non-Conforming',
					'Prefer not to answer',
					{ value: 'other', label: 'Not Listed' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			RACE_ETHNICITY: {
				name: 'raceEthnicity',
				label: 'What is your race / ethnicity?',
				type: RADIO_BUTTON_LIST,
				options: [
					'Asian or Pacific Islander',
					'Black or African American',
					'Hispanic or Latino',
					'Native American or Alaskan Native',
					'White or Caucasian',
					'Multiracial or Biracial',
					'A race/ethnicity not listed here',
					'Prefer not to answer',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			EDUCATION_LEVEL: {
				name: 'educationLevel',
				label: 'What is your current education level?',
				type: RADIO_BUTTON_LIST,
				options: [
					'Less than a high school diploma',
					'High school degree or equivalent (e.g. GED)',
					'Some college, no degree',
					'Associate degree (e.g. AA, AS)',
					'Bachelor\'s degree (e.g. BA, BS)',
					'Master\'s degree (e.g. MA, MS, MEd)',
					'Doctorate (e.g. PhD, EdD)',
					'Professional degree (e.g. MD, DDS, DVM)',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			ANNUAL_INCOME: {
				name: 'annualIncome',
				label: 'What is your current annual income?',
				type: RADIO_BUTTON_LIST,
				options: [
					'< $5k',
					'$5k-$10k',
					'$11k-$20k',
					'$21k-$40k',
					'$41k-$60k',
					'$61k-$80k',
					'$81k-$100k',
					'> $100k',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
		},
	},
	COMPETITION_LEVEL: {
		title: 'Competition Level',
		fields: {
			COMPETITION_LEVEL: {
				name: 'competitionLevel',
				label: 'What is your current level of esports competition?',
				type: CHECKBOX_LIST,
				options: [
					'Recreational gaming only',
					'Competition at tournaments',
					'Grade school esports club athlete',
					'High school esports club athlete',
					'Collegiate esports club athlete',
					'Varsity esports team athlete',
					'Professional esports team athlete',
					'Individual professional esports athlete',
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			COMPETITION_YEARS: {
				name: 'competitionYears',
				label: 'How many years have you been playing games competitively?',
				type: RADIO_BUTTON_LIST,
				options: [
					'< 1 year',
					'1-2 years',
					'3-4 years',
					'5-6 years',
					'7-8 years',
					'9-10 years',
					'> 10 years',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GAMING_PLATFORM: {
				name: 'gamingPlatform',
				label: 'What is your primary mode of gaming?',
				type: RADIO_BUTTON_LIST,
				options: [
					'PC',
					'Tablet / Smartphone',
					'Nintendo Switch',
					'Playstation 4',
					'Playstation 5',
					'Xbox One',
					'Xbox Series S',
					'Xbox Series X',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			MAIN_GAME: {
				name: 'mainGame',
				label: <Fragment>What is the <strong>main</strong> game you play competitively?</Fragment>,
				type: RADIO_BUTTON_LIST,
				options: [
					'Age of Empires II',
					'Apex Legends',
					'Brawlhalla',
					{ label: 'Call of Duty', helperText: '(any version)' },
					'Chess.com',
					'Clash Royale',
					{ label: 'Counter-Strike', helperText: '(any version)' },
					'CrossFire',
					'Defense of the Ancients',
					'Dota 2',
					{ label: 'FIFA', helperText: '(any version)' },
					'Fortnite',
					{ label: 'Gears of War', helperText: '(any version)' },
					'Guild Wars 2',
					'Gwent',
					{ label: 'Halo', helperText: '(any version)' },
					'Hearthstone',
					'Heroes of Newerth',
					'Heroes of the Storm',
					'iRacing.com',
					'League of Legends',
					'Lichess',
					{ label: 'Madden NFL', helperText: '(any version)' },
					{ label: 'Mortal Kombat', helperText: '(any version)' },
					{ label: 'NBA2K', helperText: '(any version)' },
					'Overwatch',
					'Paladins',
					{ label: 'PUBG', helperText: '(not mobile)' },
					'PUBG Mobile',
					{ label: 'Quake', helperText: '(any version)' },
					'Rainbow Six Siege',
					'Rocket League',
					'SMITE',
					{ label: 'StarCraft', helperText: '(any version)' },
					{ label: 'Street Fighter', helperText: '(any version)' },
					{ label: 'Super Smash Bros.', helperText: '(any version)' },
					'Team Fortress 2',
					'Teamfight Tactics',
					'VALORANT',
					{ label: 'WarCraft', helperText: '(any version)' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			OTHER_GAME: {
				name: 'otherGames',
				label: 'What other games do you play competitively?',
				type: CHECKBOX_LIST,
				options: [
					'Age of Empires II',
					'Apex Legends',
					'Brawlhalla',
					{ label: 'Call of Duty', helperText: '(any version)' },
					'Chess.com',
					'Clash Royale',
					{ label: 'Counter-Strike', helperText: '(any version)' },
					'CrossFire',
					'Defense of the Ancients',
					'Dota 2',
					{ label: 'FIFA', helperText: '(any version)' },
					'Fortnite',
					{ label: 'Gears of War', helperText: '(any version)' },
					'Guild Wars 2',
					'Gwent',
					{ label: 'Halo', helperText: '(any version)' },
					'Hearthstone',
					'Heroes of Newerth',
					'Heroes of the Storm',
					'iRacing.com',
					'League of Legends',
					'Lichess',
					{ label: 'Madden NFL', helperText: '(any version)' },
					{ label: 'Mortal Kombat', helperText: '(any version)' },
					{ label: 'NBA2K', helperText: '(any version)' },
					'Overwatch',
					'Paladins',
					{ label: 'PUBG', helperText: '(not mobile)' },
					'PUBG Mobile',
					{ label: 'Quake', helperText: '(any version)' },
					'Rainbow Six Siege',
					'Rocket League',
					'SMITE',
					{ label: 'StarCraft', helperText: '(any version)' },
					{ label: 'Street Fighter', helperText: '(any version)' },
					{ label: 'Super Smash Bros.', helperText: '(any version)' },
					'Team Fortress 2',
					'Teamfight Tactics',
					'VALORANT',
					{ label: 'WarCraft', helperText: '(any version)' },
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			COMPETITION_COUNT: {
				name: 'competitionCount',
				label: 'How many esports competitions have you competed in?',
				type: RADIO_BUTTON_LIST,
				options: [
					'0',
					'1 to 5',
					'6 to 10',
					'11 to 25',
					'26 to 50',
					'51 to 100',
					'> 100',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			COMPETITION_INCOME: {
				name: 'competitionIncome',
				label: 'How much income have you earned from esports competitions?',
				type: RADIO_BUTTON_LIST,
				options: [
					'< $5k',
					'$5k-$10k',
					'$11k-$20k',
					'$21k-$40k',
					'$41k-$60k',
					'$61k-$80k',
					'$81k-$100k',
					'> $100k',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			IS_GAME_STREAMER: {
				name: 'isGameStreamer',
				label: 'Do you stream or upload your gaming content online?',
				type: RADIO_BUTTON_LIST,
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			STREAMING_CHANNELS: {
				name: 'streamingChannels',
				label: 'What channel(s) do you stream on?',
				type: CHECKBOX_LIST,
				options: [
					'Twitch',
					'YouTube',
					'Mixer',
					'Facebook',
					'DLive',
					'Trovo Live',
					'Other', // (text box)
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			WANT_TO_BE_PRO: {
				name: 'wantToBePro',
				label: 'Do you want to be a professional esports athlete?',
				type: RADIO_BUTTON_LIST,
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			ESPORT_PURCHASES_PER_YEAR: {
				name: 'esportPurchasesPerYear',
				label: 'How much do you spend on esports and gaming related purchases each year?',
				type: RADIO_BUTTON_LIST,
				options: [
					'< $100 a year',
					'$100 to $500 a year',
					'$501 to $1,000 a year',
					'$1,001 - $5,000 a year',
					'> $5,000 a year',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
		},
	},
	ATTRIBUTES_AND_TRAINING: {
		title: 'Attributes and Training',
		fields: {
			HEIGHT: {
				name: 'heightInches',
				label: 'What is your height?',
				initialValue: '',
			},
			HEIGHT_FEET: {
				name: 'heightFeetNumber',
				label: 'Feet',
				initialValue: '',
				validationSchema: yup.string()
					.required('Please enter a value.'),
			},
			HEIGHT_INCHES: {
				name: 'heightInchesNumber',
				label: 'Inches',
				initialValue: '',
				validationSchema: yup.string()
					.required('Please enter a value.'),
			},
			WEIGHT_POUNDS: {
				name: 'weightPounds',
				initialValue: '',
				validationSchema: yup.string()
					.required('Please enter a value.'),
			},
			WEIGHT_KG: {
				name: 'weightKg',
				label: 'What is your weight?',
				initialValue: '',
				validationSchema: yup.string()
					.required('Please enter a value.'),
			},
			DOMINANT_HAND: {
				name: 'dominantHand',
				label: 'Are you left handed, right handed or ambidextrous?',
				type: RADIO_BUTTON_LIST,
				options: [
					'Left handed',
					'Right handed',
					'Ambidextrous',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			WEARS_EYEWEAR: {
				name: 'wearsEyewear',
				label: 'Do you wear any special gaming eye glasses?',
				type: RADIO_BUTTON_LIST,
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			EYEWEAR_DETAILS: {
				name: 'eyewearDetails',
				label: 'What type(s) of glasses?',
				type: CHECKBOX_LIST,
				options: [
					'blue light blocking glasses',
					'tinted glasses',
					'antireflective glasses',
					'other', // (text box)
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			FOLLOWS_ERGONOMIC_RECOMMENDATIONS: {
				name: 'followsErgonomicRecommendations',
				label: 'Do you follow any specific ergonomic recommendations for how you adjust your gaming chair, monitor, mouse, keyboard or other gaming peripherals?',
				type: RADIO_BUTTON_LIST,
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			GAME_TRAINING_DAILY_HOURS: {
				name: 'gameTrainingDailyHours',
				label: 'How many hours a day do you spend training on your primary game?',
				type: RADIO_BUTTON_LIST,
				options: [
					'< 1 hour a day',
					'1 to 2.5 hours a day',
					'2.6 to 5 hours a day',
					'5.1 to 7.5 hours a day',
					'7.6 to 10 hours a day',
					'> 10 hours a day',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GAME_TRAINING_DAYS_PER_WEEK: {
				name: 'gameTrainingDaysPerWeek',
				label: 'How many days a week do you spend training on your primary game?',
				type: RADIO_BUTTON_LIST,
				options: [
					'1 day a week',
					'2 days a week',
					'3 days a week',
					'4 days a week',
					'5 days a week',
					'6 days a week',
					'7 days a week',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GAMING_BREAKS: {
				name: 'gamingBreaks',
				label: 'How often do you take a break from gaming to get up and move?',
				type: RADIO_BUTTON_LIST,
				options: [
					'After playing for about 30 minutes',
					'After playing for about 1 hour',
					'After playing for about 2 hours',
					'After playing for about 3 hours',
					'After playing for about 4 hours',
					'I play for more than 4 hours without getting up and moving around',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			EXERCISE_DAILY_HOURS: {
				name: 'exerciseDailyHours',
				label: 'How many total hours a day do you exercise?',
				type: RADIO_BUTTON_LIST,
				options: [
					'0 hours',
					'< 1 hour',
					'1 to 2 hours',
					'> 2 hours',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			CARDIO_DAILY_HOURS: {
				name: 'cardioDailyHours',
				label: 'How many hours a day do you spend doing cardiovascular exercises?',
				type: RADIO_BUTTON_LIST,
				options: [
					'0 hours',
					'< 1 hour',
					'1 to 2 hours',
					'> 2 hours',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			RESISTANCE_TRAINING_DAILY_HOURS: {
				name: 'resistanceTrainingDailyHours',
				label: 'How many hours a day do you spend doing resistance training exercises?',
				type: RADIO_BUTTON_LIST,
				options: [
					'0 hours',
					'< 1 hour',
					'1 to 2 hours',
					'> 2 hours',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			DAILY_STEPS: {
				name: 'dailySteps',
				label: 'How many steps do you take each day?',
				type: RADIO_BUTTON_LIST,
				options: [
					'I don\'t track how many steps I take each day',
					'< 5,000 steps',
					'5,000-10,000 steps',
					'> 10,000 steps',
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GAMING_BEVERAGES: {
				name: 'gamingBeverages',
				label: 'What types of fluid(s) do you drink when you are gaming?',
				type: CHECKBOX_LIST,
				options: [
					{ label: 'I don\'t drink any fluids when I am training or competing' },
					{ label: 'water' },
					{ label: 'soda' },
					{ label: 'coffee' },
					{ label: 'tea' },
					{ label: 'electrolyte containing fluids (e.g. Gatorade, Powerade)' },
					{ label: 'energy drinks' },
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			FOLLOWS_NUTRITION_PROGRAM: {
				name: 'followsNutritionProgram',
				label: 'Do you follow a specific nutrition program when you train or compete?',
				type: RADIO_BUTTON_LIST,
				isBoolean: true,
				options: [
					{ label: 'yes', value: 'true' },
					{ label:	'no', value: 'false' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_YES_NO_SELECTION,
			},
			NUTRITION_PROGRAM_DETAILS: {
				name: 'nutritionProgramDetails',
				label: 'What type of program?',
				type: TEXTAREA,
				validationSchema: yup.string().required('Please enter details.'),
			},
			SLEEP_HOURS: {
				name: 'sleepHours',
				label: 'On average, how many hours of sleep do you get each night?',
				type: RADIO_BUTTON_LIST,
				options: [
					{ label: '< 2 hours', value: '< 2' },
					{ label: '2 to 3 hours', value: '2 to 3' },
					{ label: '3 to 4 hours', value: '3 to 4' },
					{ label: '4 to 5 hours', value: '4 to 5' },
					{ label: '5 to 6 hours', value: '5 to 6' },
					{ label: '6 to 7 hours', value: '6 to 7' },
					{ label: '7 to 8 hours', value: '7 to 8' },
					{ label: '> 8 hours', value: '> 8' },
				],
				initialValue: null,
				validationSchema: RadioButtonListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
			GAMING_PROBLEMS: {
				name: 'gamingProblems',
				label: 'Select the following problem(s) you have had due to gaming:',
				type: CHECKBOX_LIST,
				options: [
					{ label: 'eye strain' },
					{ label: 'dry eyes' },
					{ label: 'headache' },
					{ label: 'neck stiffness' },
					{ label: 'right shoulder pain' },
					{ label: 'left shoulder pain' },
					{ label: 'right elbow pain' },
					{ label: 'left elbow pain' },
					{ label: 'right wrist pain' },
					{ label: 'left wrist pain' },
					{ label: 'right hand pain' },
					{ label: 'left hand pain' },
					{ label: 'right finger or thumb pain' },
					{ label: 'left finger or thumb pain' },
					{ label: 'numbness in the right hand' },
					{ label: 'numbness in the left hand' },
					{ label: 'upper back pain' },
					{ label: 'lower back pain' },
					{ label: 'right hip pain' },
					{ label: 'left hip pain' },
					{ label: 'right thigh pain' },
					{ label: 'left thigh pain' },
					{ label: 'right knee pain' },
					{ label: 'left knee pain' },
					{ label: 'right ankle or foot pain' },
					{ label: 'left ankle or foot pain' },
					{ label: 'numbness in the right leg or foot' },
					{ label: 'numbness in the left leg or foot' },
					{ label: 'None of the above' },
				],
				initialValue: [],
				validationSchema: CheckboxListValidationSchemas.REQUIRED_OPTION_SELECTION,
			},
		},
	},
};

const { DEMOGRAPHICS, COMPETITION_LEVEL, ATTRIBUTES_AND_TRAINING } = preAssessment;

const preAssessmentItems = [
	DEMOGRAPHICS.fields.AGE,
	DEMOGRAPHICS.fields.SEX,
	DEMOGRAPHICS.fields.GENDER,
	DEMOGRAPHICS.fields.RACE_ETHNICITY,
	DEMOGRAPHICS.fields.EDUCATION_LEVEL,
	DEMOGRAPHICS.fields.ANNUAL_INCOME,
	COMPETITION_LEVEL.fields.COMPETITION_LEVEL,
	COMPETITION_LEVEL.fields.COMPETITION_YEARS,
	COMPETITION_LEVEL.fields.GAMING_PLATFORM,
	COMPETITION_LEVEL.fields.MAIN_GAME,
	COMPETITION_LEVEL.fields.OTHER_GAME,
	COMPETITION_LEVEL.fields.COMPETITION_COUNT,
	COMPETITION_LEVEL.fields.COMPETITION_INCOME,
	COMPETITION_LEVEL.fields.IS_GAME_STREAMER,
	COMPETITION_LEVEL.fields.WANT_TO_BE_PRO,
	COMPETITION_LEVEL.fields.ESPORT_PURCHASES_PER_YEAR,
	ATTRIBUTES_AND_TRAINING.fields.HEIGHT,
	ATTRIBUTES_AND_TRAINING.fields.WEIGHT_KG,
	ATTRIBUTES_AND_TRAINING.fields.DOMINANT_HAND,
	ATTRIBUTES_AND_TRAINING.fields.WEARS_EYEWEAR,
	ATTRIBUTES_AND_TRAINING.fields.FOLLOWS_ERGONOMIC_RECOMMENDATIONS,
	ATTRIBUTES_AND_TRAINING.fields.GAME_TRAINING_DAILY_HOURS,
	ATTRIBUTES_AND_TRAINING.fields.GAME_TRAINING_DAYS_PER_WEEK,
	ATTRIBUTES_AND_TRAINING.fields.GAMING_BREAKS,
	ATTRIBUTES_AND_TRAINING.fields.EXERCISE_DAILY_HOURS,
	ATTRIBUTES_AND_TRAINING.fields.CARDIO_DAILY_HOURS,
	ATTRIBUTES_AND_TRAINING.fields.RESISTANCE_TRAINING_DAILY_HOURS,
	ATTRIBUTES_AND_TRAINING.fields.DAILY_STEPS,
	ATTRIBUTES_AND_TRAINING.fields.GAMING_BEVERAGES,
	ATTRIBUTES_AND_TRAINING.fields.FOLLOWS_NUTRITION_PROGRAM,
	ATTRIBUTES_AND_TRAINING.fields.SLEEP_HOURS,
	ATTRIBUTES_AND_TRAINING.fields.GAMING_PROBLEMS,
];

const propTypes = {
	setAppState: PropTypes.func,
};

const PreAssessment = ({
	setAppState,
}) => {
	const [activeItem, setActiveItem] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		// get last answered question from completed sections
		async function fetchData() {
			const completedSections = await api.getCompletedSections();
			if (completedSections[appStates.PRE_ASSESSMENT]) {
				const lastQuestion = completedSections[appStates.PRE_ASSESSMENT].completedQuestion;
				const index = preAssessmentItems.findIndex((item) => item.name === lastQuestion);
				setActiveItem(preAssessmentItems[index + 1]);
			} else {
				setActiveItem(preAssessmentItems[0]);
			}
		}
		fetchData();
	}, []);


	/* eslint-disable no-param-reassign */
	const getInitialValues = () => {
		return {
			...preAssessmentItems.reduce((a, c) => {
				a[c.name] = c.initialValue;
				return a;
			}, {}),
			[`${DEMOGRAPHICS.fields.GENDER.name}Other`]: '',
			[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]: '',
			[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_KG.name]: '',
			[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]: '',
			[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]: '',
			[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name]: [],
			[ATTRIBUTES_AND_TRAINING.fields.STREAMING_CHANNELS]: [],
			[`${ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name}Other`]: '',
			[ATTRIBUTES_AND_TRAINING.fields.GAMING_BEVERAGES.name]: [],
			[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name]: '',
		};
	};

	const validationSchema = () => {
		const {
			HEIGHT,
			HEIGHT_FEET,
			HEIGHT_INCHES,
			WEIGHT_KG,
			WEIGHT_POUNDS,
			FOLLOWS_NUTRITION_PROGRAM,
			NUTRITION_PROGRAM_DETAILS,
		} = ATTRIBUTES_AND_TRAINING.fields;

		switch (activeItem) {
			case DEMOGRAPHICS.fields.GENDER: {
				return yup.object().shape({
					[activeItem.name]: activeItem.validationSchema,
					[`${[activeItem.name]}Other`]: yup.string()
						.when(activeItem.name, {
							is: 'other',
							then: yup.string().required('Please enter a value.'),
						}),
				});
			}
			case HEIGHT: {
				return yup.object().shape({
					[HEIGHT_FEET.name]: HEIGHT_FEET.validationSchema,
					[HEIGHT_INCHES.name]: HEIGHT_INCHES.validationSchema,
				});
			}
			case WEIGHT_KG: {
				return yup.object().shape({
					[activeItem.name]: activeItem.validationSchema,
					[WEIGHT_POUNDS.name]: WEIGHT_POUNDS.validationSchema,
				});
			}
			case FOLLOWS_NUTRITION_PROGRAM: {
				return yup.object().shape({
					[activeItem.name]: activeItem.validationSchema,
					[NUTRITION_PROGRAM_DETAILS.name]: yup.string()
						.when(activeItem.name, {
							is: 'true',
							then: yup.string().required('Please enter details.'),
						}),
				});
			}
			default: break;
		}

		return yup.object().shape({ [activeItem.name]: activeItem.validationSchema });
	};
	/* eslint-enable no-param-reassign */

	const handleSubmit = async (values) => {
		let entity;
		let value;

		setIsSubmitting(true);

		if (Object.values(DEMOGRAPHICS.fields).includes(activeItem)) {
			entity = ENTITIES.DEMOGRAPHICS;
		} else if (Object.values(COMPETITION_LEVEL.fields).includes(activeItem)) {
			entity = ENTITIES.COMPETITION_LEVELS;
		} else if (Object.values(ATTRIBUTES_AND_TRAINING.fields).includes(activeItem)) {
			entity = ENTITIES.TRAINING_DETAILS;
		}

		if (activeItem.type === RADIO_BUTTON_LIST && activeItem.isBoolean) {
			// convert boolean (as string) to number
			value = (values[activeItem.name] === 'true') ? 1 : 0;
		} else {
			value = values[activeItem.name];
		}

		// adjust value for 'other' values
		if (activeItem === DEMOGRAPHICS.fields.GENDER) {
			if (values[activeItem.name] === 'other') {
				value = values[`${activeItem.name}Other`];
			}
		}

		const obj = {
			[activeItem.name]: value,
		};

		// TODO: revisit
		switch (activeItem) {
			case ATTRIBUTES_AND_TRAINING.fields.WEARS_EYEWEAR: {
				let eyewearDetailsValue = values[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name];
				if (values[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name].includes('other')) {
					// remove 'other' and push its value
					eyewearDetailsValue = eyewearDetailsValue.filter((v) => v !== 'other');
					eyewearDetailsValue.push(values[`${ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name}Other`]);
				}
				obj[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name] = eyewearDetailsValue;
				break;
			}
			case COMPETITION_LEVEL.fields.IS_GAME_STREAMER: {
				// if IS_GAME_STREAMER value is 'no' / 0, STREAMING_CHANNELS should be set to null
				let streamingChannels = null;

				if (
					value === 1
					&& values[COMPETITION_LEVEL.fields.STREAMING_CHANNELS.name]
					&& values[COMPETITION_LEVEL.fields.STREAMING_CHANNELS.name].length > 0
				) {
					streamingChannels = values[COMPETITION_LEVEL.fields.STREAMING_CHANNELS.name];
				}
				obj[COMPETITION_LEVEL.fields.STREAMING_CHANNELS.name] = streamingChannels;
				break;
			}
			case ATTRIBUTES_AND_TRAINING.fields.FOLLOWS_NUTRITION_PROGRAM: {
				// if FOLLOWS_NUTRITION_PROGRAM value is 'no' / 0, NUTRITION_PROGRAM_DETAILS should be set to null
				let programDetails = null;
				if (value === 1) {
					programDetails = values[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name];
				}
				obj[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name] = programDetails;
				break;
			}
			default:
				break;
		}

		// update corresponding entity record with answer
		await api.action(entity, ACTIONS.PARTIAL_UPDATE_BY_AUTH, obj);

		// update answered question as complete
		await api.updateCompletedSections({
			[appStates.PRE_ASSESSMENT]: {
				completedQuestion: activeItem.name,
			},
		});

		// if last question move to Tests
		const index = preAssessmentItems.indexOf(activeItem);

		if (index < preAssessmentItems.length - 1) {
			setActiveItem(preAssessmentItems[index + 1]);
		} else {
			// update participant, marking section as complete
			const participantObj = { preAssessmentComplete: 1 };
			await api.action(ENTITIES.PARTICIPANTS, ACTIONS.PARTIAL_UPDATE_BY_AUTH, participantObj);

			// update section as complete
			await api.updateCompletedSections({
				[appStates.PRE_ASSESSMENT]: {
					complete: true,
				},
			});

			setAppState(appStates.TESTS);
		}

		setIsSubmitting(false);
	};

	const handlePrevious = () => {
		setActiveItem(preAssessmentItems[preAssessmentItems.indexOf(activeItem) - 1]);
	};

	return (
		<Box p={5} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<Box>
				<Typography variant="h1">
					Pre-Assessment
				</Typography>

				{activeItem && (
					<Box mt={3} mb={5}>
						<Typography variant="h6" gutterBottom style={{ textTransform: 'uppercase', fontWeight: '700' }}>
							Question {preAssessmentItems.indexOf(activeItem) + 1} / {preAssessmentItems.length}
						</Typography>

						<LinearProgress
							variant="determinate"
							value={normalize(preAssessmentItems.indexOf(activeItem) + 1, 1, preAssessmentItems.length)}
						/>
					</Box>
				)}
			</Box>

			{activeItem && (
				<Formik
					enableReinitialize={true}
					initialValues={getInitialValues()}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({
						handleBlur,
						handleChange,
						setFieldValue,
						values,
						errors,
						touched,
					}) => {

						const renderActiveItem = () => {
							const item = activeItem;

							switch (item) {
								case DEMOGRAPHICS.fields.AGE: {
									const itemValue = values[item.name];
									return (
										<Grid container>
											<Grid item xs={4} md={2}>
												<Typography variant="h4" component="div" gutterBottom>
													{item.label}
												</Typography>
												<TextField
													type="number"
													variant="filled"
													name={item.name}
													value={itemValue}
													onBlur={handleBlur}
													onChange={handleChange}
													hiddenLabel
													fullWidth
													required
													InputProps={{
														inputProps: { min: 5, max: 80, maxLength: 2 },
													}}
													InputLabelProps={{ shrink: false }}
													error={
														touched[item.name]
														&& !!errors[item.name]
													}
													helperText={
														touched[item.name]
															? errors[item.name]
															: ''
													}
													onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
												/>
											</Grid>
										</Grid>
									);
								}
								case DEMOGRAPHICS.fields.GENDER: {
									const value = values[item.name];
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={value}
												options={item.options}
												onChange={handleChange}
												onBlur={handleBlur}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>
											{value === 'other' && (
												<Box ml={2}>
													<Grid container>
														<Grid item xs={4}>
															<TextField
																name={`${item.name}Other`}
																value={values[`${item.name}Other`]}
																onChange={handleChange}
																onBlur={handleBlur}
																variant="filled"
																label="Other"
																helperText={touched[`${item.name}Other`] ? errors[`${item.name}Other`] : ''}
																error={(touched[`${item.name}Other`] && !!errors[`${item.name}Other`])}
																InputLabelProps={{ shrink: true }}
																fullWidth
															/>
														</Grid>
													</Grid>
												</Box>
											)}
										</Fragment>
									);
								}
								case COMPETITION_LEVEL.fields.IS_GAME_STREAMER: {
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={values[item.name]}
												options={item.options}
												onChange={handleChange}
												onBlur={handleBlur}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>

											{/* (If "yes") */}
											{values[item.name] === 'true' && (
												<CheckboxList
													label={COMPETITION_LEVEL.fields.STREAMING_CHANNELS.label}
													name={COMPETITION_LEVEL.fields.STREAMING_CHANNELS.name}
													values={values}
													options={COMPETITION_LEVEL.fields.STREAMING_CHANNELS.options}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
											)}
										</Fragment>
									);
								}
								case ATTRIBUTES_AND_TRAINING.fields.HEIGHT: {
									return (
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<FormLabel>
													<Typography variant="h4" component="span">
														{item.label}
													</Typography>
												</FormLabel>
											</Grid>
											<Grid item xs={3}>
												<TextField
													name={ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name}
													value={values[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]}
													type="number"
													onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
													onChange={(e) => {
														let { value } = e.target;

														if (value !== '') {
															value = Math.max(2, Math.min(7, Number(value)));
															const feetInInches = Number(value) * 12;
															/* eslint-disable max-len */
															const heightInches = values[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name];
															setFieldValue(ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name, Number(value));
															/* eslint-enable max-len */
															setFieldValue(item.name, feetInInches + heightInches);
														} else {
															setFieldValue(ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name, '');
														}
													}}
													onBlur={handleBlur}
													label={ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.label}
													variant="filled"
													fullWidth
													InputProps={{
														endAdornment: <InputAdornment position="end">ft</InputAdornment>,
														inputProps: { min: 2, max: 7, maxLength: 1 },
													}}
													error={
														touched[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]
														&& !!errors[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]
													}
													helperText={
														touched[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]
															? errors[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name]
															: ''
													}
													InputLabelProps={{ shrink: true }}
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													name={ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name}
													value={values[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]}
													type="number"
													onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
													/* eslint-disable max-len */
													onChange={(e) => {
														let { value } = e.target;

														if (value !== '') {
															const feetInInches = values[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_FEET.name] * 12;
															value = Math.max(0, Math.min(11, Number(value)));
															setFieldValue(ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name, Number(value));
															setFieldValue(item.name, feetInInches + Number(value));
														} else {
															setFieldValue(ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name, '');
														}
													}}
													/* eslint-enable max-len */
													onBlur={handleBlur}
													label={ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.label}
													variant="filled"
													fullWidth
													InputProps={{
														endAdornment: <InputAdornment position="end">in</InputAdornment>,
														inputProps: { min: 0, max: 11, maxLength: 2 },
													}}
													InputLabelProps={{ shrink: true }}
													error={
														touched[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]
														&& !!errors[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]
													}
													helperText={
														touched[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]
															? errors[ATTRIBUTES_AND_TRAINING.fields.HEIGHT_INCHES.name]
															: ''
													}
													required
												/>
											</Grid>
											{values[item.name] && (
												<Grid item xs={12}>
													<FormHelperText>
														Total Height (inches): {values[item.name]}&quot;
													</FormHelperText>
												</Grid>
											)}
										</Grid>
									);
								}
								case ATTRIBUTES_AND_TRAINING.fields.WEIGHT_KG: {
									return (
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<FormLabel>
													<Typography variant="h4" component="span">
														{item.label}
													</Typography>
												</FormLabel>
											</Grid>
											<Grid item xs={3}>
												<TextField
													name={ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name}
													value={values[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]}
													type="number"
													onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
													onChange={(e) => {
														let { value } = e.target;

														if (value !== '') {
															value = Math.max(0, Math.min(999, Number(value)));
															setFieldValue(
																ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name,
																value,
															);
															setFieldValue(item.name, Math.floor(value * 0.453592));
														} else {
															setFieldValue(
																ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name,
																'',
															);
														}
													}}
													onBlur={handleBlur}
													label="Pounds"
													variant="filled"
													fullWidth
													InputProps={{
														endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
														inputProps: { min: 50, max: 999, maxLength: 3 },
													}}
													error={
														touched[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]
														&& !!errors[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]
													}
													helperText={
														touched[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]
															? errors[ATTRIBUTES_AND_TRAINING.fields.WEIGHT_POUNDS.name]
															: ''
													}
													InputLabelProps={{ shrink: true }}
													required
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													name={item.name}
													value={values[item.name]}
													type="number"
													onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
													onChange={(e) => {
														let { value } = e.target;

														if (value !== '') {
															value = Math.max(0, Math.min(500, Number(value)));
															setFieldValue('weightPounds', Math.floor(value * 2.20462));
															setFieldValue(item.name, value);
														} else {
															setFieldValue(item.name, '');
														}
													}}
													onBlur={handleBlur}
													label="Kilograms"
													variant="filled"
													fullWidth
													error={touched[item.name] && !!errors[item.name]}
													helperText={touched[item.name] ? errors[item.name] : ''}
													InputProps={{
														endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
														inputProps: { min: 0, max: 500, maxLength: 3 },
													}}
													InputLabelProps={{ shrink: true }}
													required
												/>
											</Grid>
										</Grid>
									);
								}
								case ATTRIBUTES_AND_TRAINING.fields.WEARS_EYEWEAR: {
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={values[item.name]}
												options={item.options}
												onChange={handleChange}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>

											{/* (If "yes") */}
											{values[item.name] === 'true' && (
												<Fragment>
													<CheckboxList
														label={ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.label}
														name={ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name}
														values={values}
														options={ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.options}
														onChange={handleChange}
													/>
													{values[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name].includes('other') && (
														<Box ml={2}>
															<Grid container>
																<Grid item xs={6}>
																	{/* eslint-disable max-len */}
																	<TextField
																		name={`${ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name}Other`}
																		value={values[`${ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name}Other`]}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		variant="filled"
																		label="Other"
																		touched={touched[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name]}
																		error={errors[ATTRIBUTES_AND_TRAINING.fields.EYEWEAR_DETAILS.name]}
																		fullWidth
																	/>
																	{/* eslint-enable max-len */}
																</Grid>
															</Grid>
														</Box>
													)}
												</Fragment>
											)}
										</Fragment>
									);
								}
								case ATTRIBUTES_AND_TRAINING.fields.FOLLOWS_NUTRITION_PROGRAM: {
									return (
										<Fragment>
											<RadioButtonList
												label={item.label}
												name={item.name}
												value={values[item.name]}
												options={item.options}
												onChange={handleChange}
												touched={touched[item.name]}
												error={errors[item.name]}
											/>

											{/* (If "yes") */}
											{values[item.name] === 'true' && (
												<Fragment>
													<Box ml={2}>
														<Grid container>
															<Grid item xs={6}>
																{/* eslint-disable max-len */}
																<TextField
																	label={ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.label}
																	name={ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name}
																	value={values[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name]}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	variant="filled"
																	error={touched[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name] && !!errors[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name]}
																	helperText={errors[ATTRIBUTES_AND_TRAINING.fields.NUTRITION_PROGRAM_DETAILS.name]}
																	fullWidth
																	multiline
																	required
																	rows={3}
																/>
																{/* eslint-enable max-len */}
															</Grid>
														</Grid>
													</Box>
												</Fragment>
											)}
										</Fragment>
									);
								}
								default: {
									switch (item.type) {
										case RADIO_BUTTON_LIST: {
											return (
												<RadioButtonList
													label={item.label}
													name={item.name}
													value={values[item.name]}
													options={item.options}
													onChange={handleChange}
													onBlur={handleBlur}
													touched={touched[item.name]}
													error={errors[item.name]}
												/>
											);
										}
										case CHECKBOX_LIST: {
											return (
												<CheckboxList
													label={item.label}
													name={item.name}
													values={values}
													options={item.options}
													onChange={handleChange}
													onBlur={handleBlur}
													touched={touched[item.name]}
													error={errors[item.name]}
												/>
											);
										}
										default: {
											return null;
										}
									}
								}
							}
						};

						return (
							<Form noValidate style={{ height: '100%' }}>
								<Grid
									container
									spacing={3}
									alignItems="flex-start"
									justify="flex-start"
									alignContent="space-between"
									style={{ height: '100%' }}
								>
									<Grid item xs={12}>
										{renderActiveItem()}
									</Grid>
									<Grid item>
										<Button
											disabled={activeItem === preAssessmentItems[0] || isSubmitting}
											onClick={handlePrevious}
										>
											<ArrowBackIcon />
											Previous
											&nbsp;
										</Button>
									</Grid>
									<Grid item>
										<Button
											type="submit"
											disabled={isSubmitting}
										>
											&nbsp;
											{activeItem === preAssessmentItems[preAssessmentItems.length - 1] && 'Ready for Test'}
											{activeItem !== preAssessmentItems[preAssessmentItems.length - 1] && 'Next'}
											<ArrowForwardIcon />
										</Button>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			)}
		</Box>
	);
};

PreAssessment.propTypes = propTypes;

export default PreAssessment;
