
import { playingStates } from '@@Constants';
import AbstractGamePiece from '@@Components/Tests/AbstractGamePiece';


class ArrowLine extends AbstractGamePiece {
	constructor(
		gameBoard,
		round,
		squareSettings,
		position,
		isCongruentPattern,
	) {
		super(gameBoard, round, squareSettings, position);
		this.isCongruentPattern = isCongruentPattern;
		this.enteredValue = null;
		this.enteredTime = null;
		this.startTime = null;

		if (this.isCongruentPattern) {
			this.patternValue = this.generateCongruentPattern();
		} else {
			this.patternValue = this.generateInCongruentPattern();
		}
	}

	generateCongruentPattern() {
		const randomValue = Math.floor(Math.random() * 2);
		let output = '';
		for (let i = 0; i < this.settings.patternLength; i++) {
			if (randomValue === 0) {
				output += this.settings.leftArrow;
			} else if (randomValue === 1) {
				output += this.settings.rightArrow;
			}
		}

		return output;
	}

	generateInCongruentPattern() {
		let output = '';
		for (let i = 0; i < this.settings.patternLength; i++) {
			const randomValue = Math.floor(Math.random() * 2);
			if (randomValue === 0) {
				output += this.settings.leftArrow;
			} else if (randomValue === 1) {
				output += this.settings.rightArrow;
			}
		}

		return output;
	}

	draw() {
		this.drawValues();
	}

	drawValues() {
		this.drawText(this.patternValue);
	}

	drawText(textToDraw) {
		const { ctx } = this.gameBoard;
		ctx.beginPath();
		ctx.fillStyle = this.settings.textColor;
		ctx.font = this.settings.font;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(textToDraw,
			this.position.x + this.settings.offset.x,
			this.position.y + this.settings.offset.y);
		ctx.closePath();
	}

	leftAnswer() {
		this.enteredValue = this.settings.leftArrow;
		this.enteredTime = new Date();
	}

	rightAnswer() {
		this.enteredValue = this.settings.rightArrow;
		this.enteredTime = new Date();
	}

	isCorrect() {
		return this.isAnswered()
			&& this.patternValue[Math.floor(this.patternValue.length / 2)] === this.enteredValue;
	}

	isIncorrect() {
		return this.isAnswered()
			&& this.patternValue[Math.floor(this.patternValue.length / 2)] !== this.enteredValue;
	}

	isAnswered() {
		return this.enteredValue != null;
	}

	reactionTime() {
		return this.enteredTime - this.startTime;
	}
}

export default ArrowLine;
