
class Npc {
	constructor(
		gameBoard,
		round,
		npcSettings,
		position = null,
	) {
		this.gameBoard = gameBoard;
		this.round = round;
		this.settings = npcSettings;
		this.position = position;
		this.userClickTime = null;
		this.timeOfCreation = new Date();
		this.timeOfDisplay = Date.now()
			+ Math.floor(Math.random()
				* (this.settings.maxTimeBeforeDisplay - this.settings.minTimeBeforeDisplay))
			+ this.settings.minTimeBeforeDisplay;
		// displayTime: this.settings.displayTime,
		// text: this.settings.text,
		// color: this.settings.color,
		// font: this.settings.font,
	}

	shouldDisplay() {
		return new Date() > this.timeOfDisplay
			&& new Date() - this.timeOfDisplay < this.settings.displayTime
			&& this.userClickTime == null;
	}

	draw() {
		if (this.shouldDisplay()) {
			const { ctx } = this.gameBoard;
			ctx.beginPath();
			ctx.fillStyle = this.settings.color;
			ctx.font = this.settings.font;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.fillText(this.settings.text, this.position.x, this.position.y);
			ctx.closePath();
		}
	}

	ReactionTimeOfUserClick() {
		return this.userClickTime - this.timeOfDisplay;
	}

	IsFalseStart() {
		return this.userClickTime < this.timeOfDisplay;
	}
}

module.exports = Npc;
