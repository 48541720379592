import hash from 'object-hash';
import AlphaBlitzRound from '@@Components/Tests/AlphaBlitz/AlphaBlitzRound';
import { gameStates, playingStates } from '@@Constants';
import GameBoardBase from '@@Components/Tests/GameBoardBase';
import api, { ACTIONS, ENTITIES } from '@@Utils/api';


class AlphaBlitzGame extends GameBoardBase {

	constructor(props) {
		super(props);

		this.settings.numberOfRounds = 2;
		this.settings.roundSettings = {
			trailSettings: {
				circle: {
					radius: 30,
					color: '#808080',
					textColor: '#000000',
					selectedTextColor: '#FFFFFF',
					font: '30px Arial',
					boarderColor: '#FFFFFF',
					boarderSize: 5,
					hoverColor: '#D3D3D3',
				},
				trailLength: 26,
				circleSpacing: 15,
				font: '30px Arial',
				maxAttempts: 2,
			},
		};

		this.settings.scoreBoard = {
			position: {
				x: 700,
				y: 25,
			},
			size: {
				x: 250,
				y: 100,
			},
			padding: {
				x: 10,
				y: 10,
			},
			textLineSize: {
				x: 100,
				y: 15,
			},
			maxErrors: 10,
		};

		this.state = {
			scoreBoard: Object.assign(this.state.scoreBoard, {
				enemiesDestroyed: 0,
				alliesDestroyed: 0,
				numberOfClicks: 0,
				alliesSaved: 0,
				enemiesInvaded: 0,
				totalEnemies: 0,
				totalAllies: 0,
				totalErrors: () => {
					return this.state.scoreBoard.enemiesInvaded + this.state.scoreBoard.alliesDestroyed;
				},
			}),
		};
	}

	createNewRound() {
		return new AlphaBlitzRound({ gameBoard: this, settings: this.settings.roundSettings });
	}

	handleCanvasClick(event) {
		const click = this.getMousePositionFromEvent(event);
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (round) {
			round.checkClicked(click);
		}
	}

	handleMouseMove(event) {
		const mousePosition = this.getMousePositionFromEvent(event);
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		if (round) {
			round.trail.checkHover(mousePosition);
		}
	}

	drawGameBoard() {
		const round = this.state.scoreBoard.rounds[this.state.scoreBoard.rounds.length - 1];
		round.draw();
	}

	numberOfIncorrectClicks() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.trail.numberOfIncorrectClicks();
		});

		return output;
	}

	numberOfCorrectClicks() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.trail.correctClicks;
		});

		return output;
	}

	totalClicks() {
		let output = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			output += round.trail.totalClicks();
		});

		return output;
	}

	percentOfIncorrectClicks() {
		return parseFloat((this.numberOfIncorrectClicks() / this.totalClicks() * 100).toFixed(4));
	}

	totalElapsedTime() {
		let summedTime = 0;
		this.state.scoreBoard.rounds.forEach((round) => {
			summedTime += round.elapsedTime();
		});

		return summedTime;
	}

	totalElapsedSeconds() {
		return this.totalElapsedTime() / 1000;
	}

	setEndState() {
		super.setEndState();
		this.state.scoreBoard.rounds.forEach((round, index) => {
			api.action(ENTITIES.GAME_ALPHABLITZ_RESULTS, ACTIONS.CREATE, {
				round: index + 1,
				round_start: round.startTime,
				round_end: round.endTime,
				round_details: {
					hash: hash(this.settings),
				},
				total_time: round.elapsedTime(),
				incorrect_clicks_count: round.trail.numberOfIncorrectClicks(),
				incorrect_clicks_percent: round.trail.percentOfIncorrectClicks(),
			});
		});
	}
}

export default AlphaBlitzGame;
